import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  faFileInvoiceDollar,
  faFileArrowDown,
  faCheck,
  faBan
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';

const Invoice = props => {
  const breakpoints = useBreakpoints();
  var dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 8
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <FontAwesomeIcon
          icon={faFileInvoiceDollar}
          style={{ height: 30, paddingRight: 12 }}
        />
        <h5 style={{ marginBottom: 0 }}>
          {'Invoice - ' +
            new Date(
              props.data.status_transitions.finalized_at * 1000
            ).toLocaleDateString('en-gb', dateOptions)}
        </h5>
        <div
          style={{
            marginLeft: 12,
            marginRight: breakpoints.width >= 768 ? 0 : 16
          }}
        >
          <SoftBadge
            pill
            bg={props.data.status === 'paid' ? 'success' : 'danger'}
          >
            {props.data.status === 'paid' ? 'Paid' : 'Unpaid'}
            <FontAwesomeIcon
              icon={props.data.status === 'paid' ? faCheck : faBan}
              className="ms-2"
            />
          </SoftBadge>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <a
          href={props.data.invoice_pdf}
          target="_blank"
          rel="noreferrer"
          download
        >
          <IconButton
            className="me-2"
            variant="danger"
            icon={breakpoints.width >= 768 ? faFileArrowDown : null}
            transform="shrink-3"
          >
            Download
          </IconButton>
        </a>
      </div>
    </div>
  );
};

Invoice.propTypes = {
  data: PropTypes.object
};

export default Invoice;
