import React from 'react';
import NavbarDropdown from './NavbarDropdown';
import { hubspotRoutes, sharpspringRoutes } from 'routes/routes';
import NavbarDropdownApp from './NavbarDropdownApp';

const NavbarTopDropDownMenus = () => {
  return (
    <>
      {/* <NavbarDropdown title="dashboard">
        {dashboardRoutes.children[0].children.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown> */}

      <NavbarDropdown title="app">
        {window.localStorage.get('platform') === 'hubspot' && (
          <NavbarDropdownApp items={hubspotRoutes.children} />
        )}
        {window.localStorage.get('platform') === 'sharpspring' && (
          <NavbarDropdownApp items={sharpspringRoutes.children} />
        )}
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;
