import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';

const BadgelyM8Modal = props => {
  const { show, setShow } = props;

  const [apiUrl, setApiUrl] = useState('');
  const [apiEmail, setApiEmail] = useState('');
  const [apiPassword, setApiPassword] = useState('');
  const [apiAppName, setApiAppName] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const updateBadgelyM8Settings = async () => {
    try {
      await axios.post('/settings/badgelym8', {
        apiUrl,
        apiEmail,
        apiPassword,
        apiAppName
      });
      setShow(false);
    } catch (e) {
      switch (e.response.status) {
        case 400:
          setError('Invalid Credentials');
          break;
        case 500:
          setError('Invalid URL');
          break;
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Badgely Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert
          variant="danger"
          onClose={() => setError('')}
          dismissible
          hidden={error == ''}
        >
          {error}
        </Alert>
        <p>
          Please enter your Badgely API details so Badgely-M8 can interact with
          Badgely. These details can be updated anytime in settings.
        </p>
        <Form.Group className="mb-3">
          <Form.Label>Badgely API URL</Form.Label>
          <InputGroup>
            <InputGroup.Text>https://</InputGroup.Text>
            <Form.Control
              type={'text'}
              value={apiUrl}
              onChange={e => {
                let value = e.target.value;
                console.log(value, value.slice(0, 8));
                if (
                  value.slice(0, 8) == 'https://' &&
                  value.slice(value.length - 1, value.length) == '/'
                ) {
                  setApiUrl(value.slice(8, value.length - 1));
                } else if (value.slice(0, 8) == 'https://') {
                  setApiUrl(value.slice(8, value.length));
                } else if (value.slice(value.length - 1, value.length) == '/') {
                  setApiUrl(value.slice(0, value.length - 1));
                } else {
                  setApiUrl(e.target.value);
                }
              }}
            />
            <InputGroup.Text>/api</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Badgely API App Name</Form.Label>
          <Form.Control
            type="text"
            value={apiAppName}
            onChange={e => {
              setApiAppName(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Badgely API Email</Form.Label>
          <Form.Control
            type="text"
            value={apiEmail}
            onChange={e => {
              setApiEmail(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Badgely API Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              value={apiPassword}
              onChange={e => {
                setApiPassword(e.target.value);
              }}
            />
            <InputGroup.Text>
              <FontAwesomeIcon
                onClick={() => setShowPassword(!showPassword)}
                icon={showPassword ? faEyeSlash : faEye}
              />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Skip</Button>
        <Button
          variant="danger"
          onClick={() => {
            updateBadgelyM8Settings();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BadgelyM8Modal;
