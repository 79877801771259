import React, { useState, useEffect } from 'react';
import { Card, Table, Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TableRow = props => {
  var dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  };

  return (
    <tr>
      <td className="text-nowrap">
        {new Date(props.log.Timestamp).toLocaleString('en-gb', dateOptions)}
      </td>
      <td className="text-nowrap">{props.log.Domain}</td>
      <td className="text-end">{props.log.Activity}</td>
    </tr>
  );
};

const LogsTable = props => {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [paginations, setPaginations] = useState([]);
  const [maxPage, setMaxPage] = useState(1);

  const pageLength = 10;

  useEffect(() => {
    if (props.data === undefined) {
      return;
    }
    setRows(props.data.slice((page - 1) * pageLength, page * pageLength));
  }, [page, props.data]);

  useEffect(() => {
    if (props.data === undefined) {
      return;
    }

    let wholePages = props.data.length / pageLength;
    if (Math.floor(wholePages) < wholePages) {
      wholePages = Math.floor(wholePages) + 1;
    }
    setMaxPage(wholePages);
    if (page > wholePages) {
      setPage(1);
    }
    let temp_paginations = [];
    if (wholePages <= 1) {
      return;
    }
    if (page < 4) {
      for (let i = 1; i < 8 && i < wholePages + 1; i++) {
        temp_paginations.push(
          <Pagination.Item
            active={page === i}
            onClick={() => setPage(i)}
            key={i}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else if (page > wholePages - 3) {
      for (let i = wholePages - 6; i < wholePages + 1; i++) {
        if (i <= 0) {
          null;
        } else {
          temp_paginations.push(
            <Pagination.Item
              active={page === i}
              onClick={() => setPage(i)}
              key={i}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
    } else {
      for (let i = page - 3; i < wholePages + 1 && i < page + 4; i++) {
        if (i <= 0) {
          null;
        } else {
          temp_paginations.push(
            <Pagination.Item
              active={page === i}
              onClick={() => setPage(i)}
              key={i}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
    }
    setPaginations(temp_paginations);
  }, [props.data, page]);
  return (
    <Card style={{ height: '100%' }}>
      <Card.Body>
        <h2 style={{ fontWeight: 'normal', fontSize: 24, paddingLeft: 16 }}>
          Audit Trail
        </h2>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">Date/Time</th>
              <th scope="col">Email</th>
              <th scope="col" className="text-end">
                Total Activity
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.length != 0 &&
              rows.map((log, idx) => <TableRow log={log} key={idx} />)}
          </tbody>
        </Table>

        {maxPage > 1 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination>
              <Pagination.First onClick={() => setPage(1)} />
              {paginations}
              <Pagination.Last onClick={() => setPage(maxPage)} />
            </Pagination>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

LogsTable.propTypes = {
  data: PropTypes.array
};

TableRow.propTypes = {
  log: PropTypes.object
};

export default LogsTable;
