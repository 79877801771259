import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import { Container, Button, Card, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Portal = props => {
  const navigate = useNavigate();
  return (
    <tr
      className="hover-actions-trigger"
      style={{ cursor: 'pointer' }}
      onClick={async () => {
        let data = JSON.stringify({
          name: props.name
        });
        localStorage.setItem('portal', data);
        const [app] = await Promise.allSettled([
          axios
            .post('/auth/get-installed-apps', { accountID: props.id })
            .then(res => {
              localStorage.setItem('installedApps', '[]');
              localStorage.setItem('installedApps', JSON.stringify(res.data));
              return res.data[0];
            }),
          axios.post('/auth/get-betas', { accountID: props.id }).then(res => {
            localStorage.setItem('betas', '[]');
            localStorage.setItem('betas', JSON.stringify(res.data));
          }),
          axios.post('/set-portal', { portal: props.id }).then(res => {
            localStorage.setItem('currencyName', res.data.name);
            localStorage.setItem('currencySign', res.data.sign);
            localStorage.setItem('country', res.data.country);
            localStorage.setItem('platform', res.data.platform);
            localStorage.setItem('admin', res.data.admin);
          })
        ]);
        if (app.value == undefined) {
          navigate('/install');
        } else {
          navigate(`/${app.value}/dashboard`);
        }
      }}
    >
      <td>{props.name}</td>
      <td>{props.portal}</td>
    </tr>
  );
};

const Portals = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [portals, setPortals] = useState(null);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      axios.post('/auth/set-user-token', { token: token }).then(() => {
        searchParams.delete('token');
        setSearchParams(searchParams);
        axios
          .post('/get-portal-list', {}, { withCredentials: true })
          .then(res => {
            setPortals(res.data);
          })
          .catch(err => navigate('/login'));
      });
    } else {
      axios
        .post('/get-portal-list', {}, { withCredentials: true })
        .then(res => {
          setPortals(res.data);
        })
        .catch(err => navigate('/login'));
    }
  }, []);

  if (portals == null) {
    return <NavbarTop simple={true} />;
  }

  if (new URLSearchParams(window.location.search).get('token')) {
    return '';
  }

  return (
    <>
      <NavbarTop simple={true} />
      {portals.length != 0 && (
        <Container fluid>
          <div className="headerButtonDiv">
            <h1 className={'w-50 boldHeader'}>
              <span className="highlightedHeader">CONNECTED</span> PORTALS
            </h1>
            <Button
              onClick={() => {
                navigate('/newportal');
              }}
              variant="danger"
            >
              Connect New Portal
            </Button>
          </div>

          <Card style={{ marginLeft: 32, marginRight: 32 }}>
            <Card.Body>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Account ID</th>
                  </tr>
                </thead>
                <tbody>
                  {portals.map(portal => (
                    <Portal
                      key={portal.AccountID}
                      name={portal.AccountName}
                      id={portal.AccountID}
                      portal={portal.PortalID}
                      type={portal.Type}
                    />
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Container>
      )}
      {portals.length == 0 && (
        <Container fluid>
          <div className="centerDivHeader">
            <h1 style={{ marginTop: 32, padding: 16 }}>WELCOME</h1>
            <p style={{ paddingBottom: 32 }}>
              Welcome to Pacific Marketing Labs. To get started connect your
              first HubSpot portal by clicking the button below.
            </p>
            <Button
              onClick={() => {
                navigate('/newportal');
              }}
              variant="danger"
            >
              CONNECT NEW PORTAL
            </Button>
          </div>
        </Container>
      )}
    </>
  );
};

Portal.propTypes = {
  name: PropTypes.string,
  portal: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string
};

export default Portals;
