import React, { useState, useEffect, useContext } from 'react';
import AppContext from 'context/Context';
import logo from 'assets/img/logo.png';
import { Card, Form, Button, Alert, Modal, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import OTPCheck from 'components/OTP/OTPCheck';
import { Document, Page } from 'react-pdf';
import AnimateCC from 'react-adobe-animate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [userStep, setUserStep] = useState('login');
  const [showTCs, setShowTCs] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onLogin = async e => {
    e.preventDefault();
    setLoading(true);
    setInvalidLogin(false);
    if (email === '') {
      setEmailIsInvalid(true);
    }
    if (password === '') {
      setPasswordIsInvalid(true);
    }

    if (email === '' || password === '') {
      setLoading(false);
      return;
    }

    let data = {
      email: email,
      password: password
    };

    let result = await axios
      .post('/login', data)
      .then(res => {
        localStorage.setItem('email', email);
        return res.data;
      })
      .catch(err => console.log(err.data));

    if (result == 'invalid') {
      setInvalidLogin(true);
      setLoading(false);
      return;
    }
    localStorage.setItem('PMLAdmin', result.PMLAdmin);
    localStorage.setItem('fname', result.FirstName);
    localStorage.setItem('lname', result.LastName);
    if (result.TermsAccepted == null) {
      setShowTCs(true);
      return;
    }
    axios
      .post('/auth/set-user-token', { email: email, password: password })
      .then(() => navigate('/portals'));
    // setUserStep('otp');
  };

  const renderPages = () => {
    let pages = [];
    for (let i = 1; i < numPages + 1; i++) {
      pages.push(<Page pageNumber={i} scale={1.4} />);
    }
    return pages;
  };

  if (userStep === 'login') {
    return (
      <>
        <Modal
          show={showTCs}
          onHide={() => setShowTCs(false)}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-fitcontent"
        >
          <Modal.Header>
            <Modal.Title>Terms And Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Document
              file="https://www.pacificmarketinglabs.io/Terms-Conditions-Pacific-Marketing-Labs-Jan-23.pdf"
              onLoadSuccess={onDocumentLoadSuccess}
              className="signup-pdf-document"
            >
              {renderPages()}
            </Document>
            Accept the terms and conditions to continue.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setShowTCs(false);
                setLoading(false);
              }}
            >
              Reject
            </Button>
            <Button
              variant="danger"
              onClick={async () => {
                await axios.post('/acceptTerms', { email: email });
                setShowTCs(false);
                // setUserStep('otp');
                axios
                  .post('/auth/set-user-token', {
                    email: email,
                    password: password
                  })
                  .then(() => navigate('/portals'));
              }}
            >
              Accept
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="centerDiv">
          <div className="centerDivLogo" style={{ width: 150, height: 150 }}>
            {isDark ? (
              // <img className="me-2" src={logo} />
              <AnimateCC animationName="LabsLogoWhite" paused={false} />
            ) : (
              <AnimateCC animationName="LabsLogoBlue" paused={false} />
            )}
          </div>
          <Card className="p-32">
            <Card.Body>
              <h3 className="mb-32">Login to your account</h3>
              {invalidLogin ? (
                <Alert
                  variant="danger"
                  onClose={() => setInvalidLogin(false)}
                  dismissible
                >
                  <p className="m-0">Invalid Login Details.</p>
                </Alert>
              ) : (
                ''
              )}
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailIsInvalid(false);
                  }}
                  isInvalid={emailIsInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  Email cannot be empty.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value);
                      setPasswordIsInvalid(false);
                    }}
                    isInvalid={passwordIsInvalid}
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? faEyeSlash : faEye}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: passwordIsInvalid ? 'block' : 'none' }}
                >
                  Password cannot be empty.
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                onClick={e => {
                  onLogin(e);
                }}
                disabled={loading}
                variant="danger"
                className="btn-block"
              >
                {loading ? 'Loading' : 'Login'}
              </Button>
              <hr />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around'
                }}
              >
                {/* <p style={{ margin: 0, paddingRight: 8 }}>
                Don't have an account?
              </p> */}
                <a
                  className="nav-link"
                  style={{ cursor: 'pointer', padding: 0, marginRight: 16 }}
                  href={`${process.env.REACT_APP_WWW_URL}/signup`}
                >
                  Create Account
                </a>
                <a
                  className="nav-link"
                  style={{ cursor: 'pointer', padding: 0 }}
                  onClick={() => navigate('/reset-password')}
                >
                  Forgotten Password?
                </a>
              </div>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  } else if (userStep == 'otp') {
    return <OTPCheck email={email} resend={true} />;
  }
};

export default Login;
