import AdminUsageData from 'components/dashboard/common/AdminUsageData';
import UsageData from 'components/dashboard/common/UsageData';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AdminBetas from '../common/AdminBetas';
import LineChart from './LineChart';
import LogsTable from './LogsTable';

const ActivityM8Dashboard = props => {
  return (
    <>
      {/* <Row>
        <Col lg={3} style={{ paddingBottom: 32 }}></Col>
        <Col lg={6} style={{ paddingBottom: 32 }}></Col>
        <Col lg={3} style={{ paddingBottom: 32 }}>
          {props.admin ? (
            <AdminUsageData data={props.account} app={props.app} />
          ) : (
            <UsageData
              data={props.account}
              allocation={props.account.MaxRequests}
              text={'Extra credits used'}
            />
          )}
        </Col>
      </Row> */}
      {props.admin ? (
        <Row>
          <Col lg={9} style={{ paddingBottom: 32 }}>
            <LineChart data={props.data} range={props.range} />
          </Col>
          <Col lg={3} style={{ paddingBottom: 32 }}>
            <AdminBetas app={props.app} data={props.account} />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col lg={12} style={{ paddingBottom: 32 }}>
            <LineChart data={props.data} range={props.range} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <LogsTable data={props.data} />
        </Col>
      </Row>
    </>
  );
};

export default ActivityM8Dashboard;
