import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';

const Subscription = ({
  features,
  popular,
  info,
  chosen,
  setChosen,
  current,
  disabled,
  button,
  border,
  height,
  term
}) => {
  const [buttonText, setButtonText] = useState('Select Plan');

  term = term ?? 'month';

  useEffect(() => {
    if (current) {
      setButtonText('Current Plan');
    }
  }, []);

  // let features;
  try {
    if (info.Description == null || info.Type == 'additional-credit') {
      features = [];
    } else {
      features = JSON.parse(info.Description);
    }
  } catch {
    features = [info.Description];
  }

  const buttonOnClick = async () => {
    await setChosen(info);
    document.getElementById('checkout-button').scrollIntoView();
  };

  const formatPrice = price => {
    price = price.toString();
    if (price.slice(-2) == '00') {
      return price.slice(0, -2);
    } else if (price == '0') {
      return price;
    } else {
      return price.slice(0, -2) + '.' + price.slice(-2);
    }
  };

  return (
    <>
      <Col className="subscription-price">
        <div
          className="price-popular"
          style={{ display: 'none' }}
          // style={{ visibility: popular ? 'visible' : 'hidden' }}
        >
          Most popular
        </div>
        <div
          className="price"
          style={{
            border: border === false ? 'unset' : '1px solid #eaeced',
            minHeight: height !== undefined ? height : 610
          }}
        >
          <h3 className="price-header" style={{ fontSize: '1.728rem' }}>
            {info.Name}
          </h3>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
          >
            {info.Name != 'Enterprise' && (
              <>
                <span className="price-symbol">
                  {localStorage.getItem('currencySign')}
                </span>
                <span className="price-amount">{formatPrice(info.Price)}</span>
                {info.Price != 0 && info.Type !== 'additional-credit' && (
                  <span className="price-month">/{term}</span>
                )}
              </>
            )}
            {info.Name == 'Enterprise' && (
              <span className="price-amount">Call</span>
            )}
          </div>
          <ul className="price-features">
            {features.map((feature, idx) => {
              return (
                <li key={idx}>
                  {feature.replace(
                    '{currency} ',
                    localStorage.getItem('currencySign')
                  )}
                </li>
              );
            })}
          </ul>
          {info.Name != 'Enterprise' ? (
            <Button
              style={{
                marginBottom: 16,
                width: '95%',
                marginTop: 'auto',
                backgroundColor: chosen ? '#64a0c8' : '#f80303',
                borderColor: chosen ? '#64a0c8' : '#f80303'
              }}
              variant="danger"
              className="btn-block"
              disabled={current || disabled}
              onClick={() => buttonOnClick()}
              active={chosen}
              hidden={!button}
            >
              {chosen ? 'Selected' : ''}
              {current ? 'Current Plan' : ''}
              {!chosen && !current ? 'Select Plan' : ''}
            </Button>
          ) : (
            <Button
              style={{
                marginBottom: 16,
                width: '95%',
                marginTop: 'auto',
                backgroundColor: chosen ? '#64a0c8' : '#f80303',
                borderColor: chosen ? '#64a0c8' : '#f80303'
              }}
              variant="danger"
              className="btn-block"
              onClick={() => {
                window
                  .open(`${process.env.REACT_APP_WWW_URL}/contact`, '_blank')
                  .focus();
              }}
              hidden={!button}
            >
              Call
            </Button>
          )}
        </div>
      </Col>
    </>
  );
};

Subscription.propTypes = {
  // children: PropTypes.node,
  popular: PropTypes.bool,
  info: PropTypes.object,
  features: PropTypes.array,
  current: PropTypes.bool,
  setChosen: PropTypes.func,
  chosen: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Subscription;
