import axios from 'axios';
import classNames from 'classnames';
import * as CR8IFrame from 'components/currencyr8/IFrame';
import Footer from 'components/footer/Footer';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import React, { useContext, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import Addons from 'views/Addons';
import Billing from 'views/Billing';
import Dashboard from 'views/Dashboard';
import FXScheduler from 'views/FXScheduler';
import InstallApp from 'views/InstallApp';
import PaymentCompleteWrapper from 'views/PaymentComplete';
import ProfileSettings from 'views/ProfileSettings';
import Settings from 'views/Settings';
import Subscriptions from 'views/Subscriptions';
import UpgradeCompleteWrapper from 'views/UpgradeComplete';
import AdminFreeagent from 'views/admin/AdminFreeagent';
import Admins from 'views/admin/Admins';
import Agencys from 'views/admin/Agencys';
import AdminDashboard from 'views/admin/Dashboard';
import Logs from 'views/admin/Logs';
import Products from 'views/admin/Products';
import Valid8Report from 'views/admin/Valid8Report';
import InstallUrls from '../InstallUrls';

const MainLayout = () => {
  const { hash, pathname } = useLocation();

  const {
    config: { isFluid, navbarPosition, isDark, showNavbar },
    setConfig
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setConfig('showNavbar', true);
  }, [pathname]);

  return (
    <div className="container-fluid">
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') &&
        showNavbar && <NavbarVertical />}
      <div className={classNames('content')} style={{ paddingLeft: 16 }}>
        <NavbarTop />
        {/* prettier-ignore */}
        <Routes>
          <Route path="pmladmin/dashboard/*" element={<Auth><AdminDashboard /></Auth>}/>
          <Route path="pmladmin/admins" element={<Auth><Admins /></Auth>}/>
          <Route path="pmladmin/logs/:server" element={<Auth><Logs /></Auth>}/>
          <Route path="pmladmin/freeagent" element={<Auth><AdminFreeagent /></Auth>}/>
          <Route path="pmladmin/partners" element={<Auth><Agencys /></Auth>}/>
          <Route path="pmladmin/products" element={<Auth><Products /></Auth>}/>
          <Route path="pmladmin/reports/valid8" element={<Auth><Valid8Report /></Auth>}/>
          <Route path="portalsettings/*" element={<Auth><Settings /></Auth>}/>
          <Route path="profilesettings/*" element={<Auth><ProfileSettings /></Auth>}/>
          <Route path="/install" element={<Auth><InstallApp /></Auth>}/>
          <Route path="payment-complete" element={<Auth><PaymentCompleteWrapper /></Auth>}/>
          <Route path="upgrade-complete" element={ <Auth><UpgradeCompleteWrapper /></Auth>}/>
          <Route exact path="valid8">
            <Route path="dashboard" exact element={<Auth><Dashboard app="Valid8" /></Auth>}/>
            <Route path="billing" exact element={<Auth><Billing app="Valid8" /></Auth>}/>
            <Route path="plans" exact element={<Auth><Subscriptions app="Valid8" slider={true} annualPlans={true}/></Auth>}/>
            <Route path="install" exact element={<Auth><InstallUrls app="Valid8" /></Auth>}/>
            <Route path="add-ons" exact element={<Auth><Addons app="Valid8" /></Auth>} />
          </Route>
          <Route exact path="currencyr8">
            <Route path="scheduler" element={<Auth><FXScheduler app="CurrencyR8" /></Auth>}/>
            <Route path="dashboard" element={<Auth><Dashboard app="CurrencyR8" /></Auth>}/>
            <Route path="billing" element={<Auth><Billing app="CurrencyR8" /></Auth>}/>
            <Route path="plans" element={<Auth><Subscriptions app="CurrencyR8" /></Auth>}/>
            <Route path="install" element={<Auth> <InstallUrls app="CurrencyR8" /></Auth>}/>
          </Route>
          <Route exact path="activitym8">
            <Route path="dashboard" element={<Auth><Dashboard app="ActivityM8" /></Auth>}/>
            {/* <Route path="billing" element={<Auth><Billing app="ActivityM8" /></Auth>}/> */}
            {/* <Route path="plans" element={<Auth><Subscriptions app="ActivityM8" /></Auth>}/> */}
          </Route>
          <Route exact path="qrgener8">
            <Route path="dashboard" element={<Auth><Dashboard app="QRGener8" /></Auth>}/>
            <Route path="billing" element={<Auth><Billing app="QRGener8" /></Auth>}/>
            <Route path="plans" element={<Auth><Subscriptions app="QRGener8" /></Auth>}/>
          </Route>
          <Route exact path="badgelym8">
            <Route path="dashboard" element={<Auth><Dashboard app="BadgelyM8" /></Auth>}/>
            <Route path="billing" element={<Auth><Billing app="BadgelyM8" /></Auth>}/>
            <Route path="plans" element={<Auth><Subscriptions app="BadgelyM8" /></Auth>}/>
          </Route>
          <Route exact path="ping">
            <Route path="dashboard" exact element={<Auth><Dashboard app="Ping" /></Auth>}/>
            <Route path="billing" exact element={<Auth><Billing app="Ping" /></Auth>}/>
            <Route path="plans" exact element={<Auth><Subscriptions app="Ping" slider={true}/></Auth>}/>
            <Route path="install" exact element={<Auth><InstallUrls app="Ping" /></Auth>}/>
            {/* <Route path="add-ons" exact element={<Auth><Addons app="Ping" /></Auth>} /> */}
          </Route>
          <Route path="*" element={<Navigate to="/page-not-found" replace />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;

const Auth = props => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      axios.post('/auth/set-user-token', { token: token });
    } else {
      axios.post('/auth/verify-token').catch(err => navigate('/login'));
    }
  }, []);
  return <>{props.children}</>;
};
