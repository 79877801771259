import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { getColor, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';

const ValidationLineChart = props => {
  const [datasets, setDatasets] = useState([]);
  const [dateRange, setDateRange] = useState({});

  useEffect(() => {
    if (props.data !== undefined && props.data.length !== 0) {
      formatValues();
    }
  }, [props]);

  const formatValues = () => {
    let datasets = {
      Valid: [],
      Invalid: [],
      Unknown: [],
      Unverifiable: [],
      'Catch-All': []
    };

    let minDate;
    let maxDate;

    if (props.range == null) {
      minDate = props.data[props.data.length - 1].Timestamp.slice(0, 10);
      maxDate = new Date();
    } else {
      minDate = props.range.start.setDate(props.range.start.getDate() - 1);
      maxDate = props.range.end;
    }
    maxDate.setHours(0, 0, 0, 0);
    maxDate.setDate(maxDate.getDate() + 1);
    setDateRange({ min: minDate, max: maxDate.toISOString().split('T')[0] });

    let dataIndex = props.data.length - 1; //* Dates are backwards

    //* Iterate through the date range, creating 0 for each date
    //* If the date index matches the currenct date add 1 to the count and index
    //* Adding all results for that day, before continuing

    for (let i = new Date(minDate); i <= maxDate; i.setDate(i.getDate() + 1)) {
      datasets.Valid.push({ x: i.toISOString().slice(0, 10), y: 0 });
      datasets.Invalid.push({ x: i.toISOString().slice(0, 10), y: 0 });
      datasets.Unknown.push({ x: i.toISOString().slice(0, 10), y: 0 });
      datasets['Catch-All'].push({ x: i.toISOString().slice(0, 10), y: 0 });
      datasets.Unverifiable.push({
        x: i.toISOString().slice(0, 10),
        y: 0
      });
      try {
        while (
          props.data[dataIndex].Timestamp.slice(0, 10) ===
            i.toISOString().slice(0, 10) &&
          dataIndex >= 0
        ) {
          datasets[props.data[dataIndex].Status][
            datasets[props.data[dataIndex].Status].length - 1
          ].y++;
          dataIndex = dataIndex - 1;
        }
      } catch (err) {
        //* If final date past the final log, will crash wihtout adding the 0s
        null;
      }
    }
    setDatasets(datasets);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    type: 'line',
    elements: {
      point: {
        radius: 2
      }
    },
    animation: {
      duration: 2000
    },
    scales: {
      x: {
        distribution: 'linear',
        ticks: {
          source: 'auto',
          autoSkip: true
        },
        type: 'time',
        time: {
          tooltipFormat: 'MMM, DD YYYY',
          parser: 'YYYYMMDD',
          displayFormats: {
            day: 'MMM DD'
          },
          unit: 'day'
        },
        min: dateRange.min,
        max: dateRange.max
      },
      y: {
        min: 0
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Total Validations Over Time',
        font: {
          size: 24,
          family: "'Poppins'",
          weight: 'normal'
        }
      }
    }
  };
  const data = {
    labels: ['Valid'],
    datasets: [
      {
        label: 'Valid',
        data: datasets.Valid,
        backgroundColor: [rgbaColor(getColor('success'), 0.7)],
        borderColor: [rgbaColor(getColor('success'), 0.7)],
        tension: 0.2
      },
      {
        label: 'Invalid',
        data: datasets.Invalid,
        backgroundColor: [rgbaColor(getColor('danger'), 0.7)],
        borderColor: [rgbaColor(getColor('danger'), 0.7)],
        tension: 0.2
      },
      {
        label: 'Unknown',
        data: datasets.Unknown,
        backgroundColor: [rgbaColor(getColor('light'), 0.7)],
        borderColor: [rgbaColor(getColor('light'), 0.7)],
        tension: 0.2
      },
      {
        label: 'Catch-All',
        data: datasets['Catch-All'],
        backgroundColor: [rgbaColor(getColor('warning'), 0.7)],
        borderColor: [rgbaColor(getColor('warning'), 0.7)],
        tension: 0.2
      }
    ]
  };

  return (
    <Card style={{ height: '100%' }}>
      <Card.Body>
        <Line options={options} data={data} />
      </Card.Body>
    </Card>
  );
};

ValidationLineChart.propTypes = {
  data: PropTypes.array,
  range: PropTypes.object
};

export default ValidationLineChart;
