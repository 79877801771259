import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Subscription from './Subscription';

const Plans = props => {
  const {
    addons,
    account,
    selectedSubscription,
    setSelectedSubscription,
    selectedAddons,
    setSelectedAddons,
    setShowCheckout,
    setShowModal,
    slider,
    additionalCredits
  } = props;

  const [planSlider, setPlanSlider] = useState(0);
  const [addonSlider, setAddonSlider] = useState(-1);

  const hasEnterprisePlan = props.tiers.some(
    tier => tier.AccountID === account.AccountID
  );

  const tiers = props.tiers
    .map(tier => {
      if (account.CurrentPlan === tier.ProductID) {
        return tier;
      }
      if (account.TrialStatus == 'used' && tier.Type == 'trial') {
        return null;
      }
      if (account.IsAgency && tier.Type == 'trial') {
        return null;
      }
      if (!account.IsAgency && tier.Name == 'Agency') {
        return null;
      }
      if (!account.IsAgency && tier.Name == 'Partner') {
        return null;
      }
      if (hasEnterprisePlan && tier.AccountID !== account.AccountID) {
        return null;
      }
      return tier;
    })
    .filter(tier => tier != null);

  const subscriptionType = props.subscriptionType ?? 'monthly';

  useEffect(() => {
    if (
      // if account has a hidden legacy plan, just show the first plan
      tiers.find(tier => tier.ProductID == account.CurrentPlan) == null &&
      slider
    ) {
      setSelectedSubscription(tiers[0]);
      setPlanSlider(0);
    } else if (account.CurrentPlan != null && slider) {
      setSelectedSubscription(
        tiers.find(tier => tier.ProductID == account.CurrentPlan)
      );
      setPlanSlider(
        tiers.findIndex(tier => tier.ProductID == account.CurrentPlan)
      );
    } else if (account.CurrentPlan == null && slider) {
      setSelectedSubscription(tiers[0]);
      setPlanSlider(0);
      console.log(3);
    }
  }, [subscriptionType]);

  const hasCustomPlan = () => {
    return !tiers.some(p => p.Name == 'Enterprise' && p.Price == 0);
  };

  const tierDisabled = (tier, account, selectedAddons) => {
    if (tier.Price < account.Price && selectedAddons.length !== 0) return true;
    if (!['active', null, undefined].includes(account.SubscriptionStatus))
      return true;
    if (hasCustomPlan() && tier.AccountID == null) return true;
    return false;
  };

  if (slider && selectedSubscription) {
    return (
      <>
        <Card>
          <Row className="p-5">
            <Col lg={8}>
              <h3 className="pb-3">
                Choose {subscriptionType == 'monthly' && <>Monthly</>}{' '}
                {subscriptionType == 'annual' && <>Annual</>} Plan
              </h3>
              <p className="pb-3">
                How many email addresses would you like to validate each{' '}
                {subscriptionType == 'monthly' && <>month</>}
                {subscriptionType == 'annual' && <>year</>}?
              </p>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Form.Range
                  min={0}
                  max={tiers.length - 1}
                  step={1}
                  onChange={e => {
                    setPlanSlider(e.target.value);
                    setSelectedSubscription(tiers[e.target.value]);
                  }}
                  value={planSlider}
                  className="plans-range"
                  style={{ width: 'calc(100% - 37px)' }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {tiers.map((tier, idx) => {
                  let width = 74;
                  let requests = tier.Requests.toLocaleString();
                  switch (tier.Name) {
                    case 'Enterprise':
                      requests = '100,000+';
                      break;
                    case 'Free Trial':
                      requests = 'Free Trial';
                      break;
                    case 'Partner':
                      requests = 'Partner';
                      break;
                  }

                  if (tier.AccountID === account.AccountID) {
                    width = 'unset';
                    requests = tier.Name;
                  }

                  let currentPlan = tier.ProductID == account.CurrentPlan;

                  return (
                    <span
                      key={idx}
                      style={{ width: width, textAlign: 'center' }}
                    >
                      {requests}
                      {currentPlan && (
                        <span className="current-plan-indicator">
                          Current Plan
                        </span>
                      )}
                    </span>
                  );
                })}
              </div>
            </Col>
            <Col lg={4}>
              {Object.keys(selectedSubscription).length > 0 && (
                <Subscription
                  info={selectedSubscription}
                  features={[]}
                  popular={false}
                  chosen={false}
                  setChosen={setSelectedSubscription}
                  disabled={tierDisabled(
                    selectedSubscription,
                    account,
                    selectedAddons
                  )}
                  current={
                    account.CurrentPlan == selectedSubscription.ProductID
                  }
                  button={false}
                  height={'unset'}
                  term={
                    selectedSubscription.Type === 'subscription'
                      ? 'month'
                      : 'year'
                  }
                />
              )}
            </Col>
          </Row>
        </Card>
        {(account.CurrentPlan == null || account.TrialStatus == 'current') &&
          additionalCredits.length !== 0 && (
            <Card className="mt-5">
              <Row className="p-5">
                <Col lg={8}>
                  <h3 className="pb-3">Booster Pack</h3>
                  <p className="pb-3">
                    Would you like to purchase extra credits to run a one-time
                    bulk validation of your contacts?
                  </p>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Form.Range
                      min={-1}
                      max={additionalCredits.length - 1}
                      step={1}
                      onChange={e => {
                        setAddonSlider(e.target.value);
                        if (e.target.value == -1) {
                          setSelectedAddons([
                            ...selectedAddons.filter(
                              i => i.Type !== 'additional-credit'
                            )
                          ]);
                          return;
                        }
                        setSelectedAddons([
                          ...selectedAddons.filter(
                            i => i.Type !== 'additional-credit'
                          ),
                          additionalCredits[e.target.value]
                        ]);
                      }}
                      value={addonSlider}
                      className="plans-range"
                      style={{ width: 'calc(100% - 37px)' }}
                    />
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span style={{ width: 74, textAlign: 'center' }}>0</span>
                    {additionalCredits.map((tier, idx) => {
                      let requests = tier.Requests.toLocaleString();

                      return (
                        <span
                          key={idx}
                          style={{ width: 74, textAlign: 'center' }}
                        >
                          {requests}
                        </span>
                      );
                    })}
                  </div>
                </Col>
                <Col lg={4}>
                  {!selectedAddons[0] && (
                    <Subscription
                      info={{
                        Name: '-',
                        Price: 0,
                        Type: 'additional-credit',
                        Description: null
                      }}
                      features={[]}
                      popular={false}
                      chosen={false}
                      setChosen={false}
                      disabled={false}
                      current={false}
                      button={false}
                      border={true}
                      height={'unset'}
                    />
                  )}
                  {selectedAddons[0] &&
                    Object.keys(selectedAddons[0]).length > 0 && (
                      <Subscription
                        info={selectedAddons[0]}
                        features={[]}
                        popular={false}
                        chosen={false}
                        setChosen={false}
                        disabled={false}
                        current={false}
                        button={false}
                        border={true}
                        height={'unset'}
                      />
                    )}
                </Col>
              </Row>
            </Card>
          )}
        <Row className="pt-3">
          <Col>
            <Button
              id="checkout-button"
              onClick={() => {
                if (selectedSubscription.Name == 'Enterprise') {
                  window
                    .open(`${process.env.REACT_APP_WWW_URL}/contact`, '_blank')
                    .focus();
                } else {
                  setShowCheckout(true);
                }
              }}
              disabled={account.CurrentPlan == selectedSubscription.ProductID}
              variant="danger"
              className="btn-block"
              style={{ marginTop: 16, marginBottom: 16 }}
            >
              {account.CurrentPlan == selectedSubscription.ProductID && (
                <>Current Plan</>
              )}
              {/* {account.Price > selectedSubscription.Price && (
                <>Downgrade Plan</>
              )} */}
              {selectedSubscription.Name == 'Enterprise' && <>Contact Us</>}
              {/* {account.Price < selectedSubscription.Price && <>Upgrade Plan</>} */}
              {!(account.CurrentPlan == selectedSubscription.ProductID) &&
                // !(account.Price > selectedSubscription.Price) &&
                // !(account.Price < selectedSubscription.Price) &&
                !(selectedSubscription.Name === 'Enterprise') && (
                  <>Continue to Checkout</>
                )}
            </Button>
          </Col>
        </Row>
        <Row>
          {account.SubscriptionStatus === 'active' && (
            <a
              className="cancelButton"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Cancel Plan
            </a>
          )}
        </Row>
      </>
    );
  }
  if (!slider) {
    return (
      <>
        <Row className={addons.length == 0 ? '' : 'mb-32'}>
          {tiers.map((tier, idx) => {
            let features;
            try {
              features = JSON.parse(tier.Description);
            } catch (e) {
              features = [tier.Description];
            }

            return (
              <Col
                xl={3}
                md={6}
                style={{
                  padding: 16,
                  marginTop: account.currentPlan == null ? '' : '-32px'
                }}
                key={idx}
              >
                <Subscription
                  info={tier}
                  features={features}
                  popular={false}
                  chosen={
                    selectedSubscription.StripeID === tier.StripeID &&
                    selectedSubscription.Type == tier.Type
                  }
                  setChosen={setSelectedSubscription}
                  disabled={tierDisabled(tier, account, selectedAddons)}
                  current={account.CurrentPlan == tier.ProductID}
                  button={true}
                />
              </Col>
            );
          })}
        </Row>
        {addons.length !== 0 &&
          account.hadInstallation !== true &&
          account.IsAgency == false && (
            <Row>
              <Col>
                <Card className="p-32">
                  <Card.Body>
                    <h3 className="pb-2">Addon Packages</h3>
                    {addons.map((addon, idx) => {
                      let price = addon.Price.toString();
                      if (price.slice(-2) == '00') {
                        price = price.slice(0, 2);
                      } else {
                        price = price.slice(0, -2) + '.' + price.slice(-2);
                      }
                      return (
                        <span key={idx}>
                          {addon.Name} {localStorage.getItem('currencySign')}
                          {price}
                          <Form.Check
                            type="checkbox"
                            id={idx}
                            label={addon.Description}
                            disabled={
                              selectedSubscription.Price < account.Price ||
                              !['active', null].includes(
                                account.SubscriptionStatus
                              ) ||
                              selectedSubscription.Type == 'trial'
                            }
                            onChange={e => {
                              if (e.target.checked) {
                                setSelectedAddons([...selectedAddons, addon]);
                              } else {
                                setSelectedAddons(
                                  selectedAddons.filter(
                                    i => i.ProductID !== addon.ProductID
                                  )
                                );
                              }
                            }}
                          />
                        </span>
                      );
                    })}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        <Row style={{ paddingTop: 16 }}>
          <Col sm={12}>
            <Button
              id="checkout-button"
              hidden={
                Object.keys(selectedSubscription).length == 0 &&
                selectedAddons.length == 0
              }
              onClick={() => setShowCheckout(true)}
              variant="danger"
              className="btn-block"
              style={{ marginTop: 16, marginBottom: 16 }}
            >
              Continue to Checkout
            </Button>
          </Col>
        </Row>
        <Row>
          {account.SubscriptionStatus === 'active' && (
            <a
              className="cancelButton"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Cancel Plan
            </a>
          )}
        </Row>
      </>
    );
  }
  return <></>;
};

export default Plans;

// {/* Addons */}
// {addons.length !== 0 &&
//   account.hadInstallation !== true &&
//   account.IsAgency == false && (
//     <>
//       <h3 className="pb-2">Addon Packages</h3>
//       {addons.map((addon, idx) => {
//         let price = addon.Price.toString();
//         if (price.slice(-2) == '00') {
//           price = price.slice(0, 2);
//         } else {
//           price = price.slice(0, -2) + '.' + price.slice(-2);
//         }
//         return (
//           <span key={idx}>
//             {addon.Name} {localStorage.getItem('currencySign')}
//             {price}
//             <Form.Check
//               type="checkbox"
//               id={idx}
//               label={addon.Description}
//               disabled={
//                 selectedSubscription.Price < account.Price ||
//                 !['active', null].includes(
//                   account.SubscriptionStatus
//                 ) ||
//                 selectedSubscription.Type == 'trial'
//               }
//               onChange={e => {
//                 if (e.target.checked) {
//                   setSelectedAddons([...selectedAddons, addon]);
//                 } else {
//                   setSelectedAddons(
//                     selectedAddons.filter(
//                       i => i.ProductID !== addon.ProductID
//                     )
//                   );
//                 }
//               }}
//             />
//           </span>
//         );
//       })}
//     </>
//   )}
