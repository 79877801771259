import axios from 'axios';
import PageTitle from 'components/common/PageTitle';
import NavbarTop from 'components/navbar/top/NavbarTop';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

const Logs = () => {
  const server = useParams().server;
  const [files, setFiles] = useState([]);
  const [lines, setLines] = useState(100);
  const [apps, setApps] = useState([]);
  const [levels, setLevels] = useState([]);
  const [hideLegacyLogs, setHideLegacyLogs] = useState(false);
  const [hideTimestamps, setHideTimestamps] = useState(false);
  const [hideLineNumbers, setHideLineNumbers] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContents, setFileContents] = useState([]);

  useEffect(() => {
    getFilesList();
  }, []);

  useEffect(() => {
    setFiles([]);
    setSelectedFile(null);
    setFileContents([]);
    getFilesList();
  }, [server]);

  const getFilesList = async () => {
    const { data } = await axios.get(`/pmladmin/logs/files?server=${server}`);
    setFiles(data);
  };

  const getFile = async () => {
    let { data } = await axios.get(
      `/pmladmin/logs/${selectedFile}?server=${server}&lines=${lines}`
    );
    data = data.split('\n');
    data.shift();
    data.pop();
    setFileContents(data);
    const logBody = document.getElementById('log-body');
    logBody.scrollTop = logBody.scrollHeight;
  };

  return (
    <>
      {/* <NavbarTop simple /> */}
      <PageTitle app={server} page="Logs" />
      <div
        style={{
          position: 'absolute',
          top: 91.7,
          bottom: 64.8,
          width: 'calc(100% - 32px)'
        }}
      >
        <Card style={{ height: '100%' }}>
          <Card.Header style={{ display: 'flex', gap: 10 }}>
            <Form.Group>
              <Form.Label>File</Form.Label>
              <Form.Select onChange={e => setSelectedFile(e.target.value)}>
                <option>Select Log File</option>
                {files.map((file, index) => (
                  <option value={file.name} key={index}>
                    {file.timestampISO} - {file.type}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {/* <Form.Group style={{ width: 125 }}>
              <Form.Label>Lines</Form.Label>
              <Form.Control
                type="number"
                value={lines}
                onChange={e => setLines(e.target.value)}
              ></Form.Control>
            </Form.Group> */}
            <Form.Group>
              <Form.Label>App(s)</Form.Label>
              <Select
                closeMenuOnSelect={false}
                placeholder="Select App(s)"
                isMulti
                options={[
                  { value: 'SERVER', label: 'Server' },
                  { value: 'CURRENCY-R8', label: 'Currency-R8' },
                  { value: 'VALID8', label: 'Valid8' },
                  { value: 'FSQS-LIVE', label: 'FSQS Live' }
                ]}
                classNamePrefix="react-select"
                value={apps}
                onChange={setApps}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Level</Form.Label>
              <Select
                closeMenuOnSelect={false}
                placeholder="Select Level"
                isMulti
                options={[
                  { value: 'INFO', label: 'Info' },
                  { value: 'WARN', label: 'Warning' },
                  { value: 'DEBUG', label: 'Debug' },
                  { value: 'ERROR', label: 'Error' }
                ]}
                classNamePrefix="react-select"
                value={levels}
                onChange={setLevels}
              />
            </Form.Group>
            <Form.Group>
              {/* <Form.Label>Hide Legacy Logs</Form.Label> */}
              <Form.Check
                type="checkbox"
                value={hideLegacyLogs}
                onChange={e => setHideLegacyLogs(e.target.checked)}
                label="Hide Legacy Logs"
              ></Form.Check>
              <Form.Check
                style={{ marginTop: -13 }}
                type="checkbox"
                value={hideTimestamps}
                onChange={e => setHideTimestamps(e.target.checked)}
                label="Hide Timestamps"
              ></Form.Check>
              <Form.Check
                style={{ marginTop: -13 }}
                type="checkbox"
                value={hideLineNumbers}
                onChange={e => setHideLineNumbers(e.target.checked)}
                label="Hide Line Numbers"
              ></Form.Check>
            </Form.Group>
            <Button
              variant="danger"
              style={{
                whiteSpace: 'nowrap',
                height: 'fit-content',
                alignSelf: 'center',
                marginLeft: 'auto'
              }}
              disabled={
                selectedFile == null || selectedFile == 'Select Log File'
              }
              onClick={getFile}
            >
              View Logs
            </Button>
          </Card.Header>
          <Card.Body
            style={{ height: 'calc(100% - 120px)', overflow: 'auto' }}
            id="log-body"
          >
            <div>
              {fileContents.map((line, index) => {
                try {
                  line = JSON.parse(line);
                } catch {
                  null;
                }

                // Filter Legacy Logs
                if (hideLegacyLogs && typeof line == 'string') {
                  return null;
                }

                // Filter Apps
                if (apps.length > 0 && typeof line == 'object') {
                  if (apps.filter(app => app.value == line.app).length == 0) {
                    return null;
                  }
                }

                // Filter Levels
                if (levels.length > 0 && typeof line == 'object') {
                  if (
                    levels.filter(level => level.value == line.type).length == 0
                  ) {
                    return null;
                  }
                }

                return (
                  <div
                    key={index}
                    style={{
                      fontSize: 13,
                      fontFamily: 'monospace',
                      whiteSpace: 'pre',
                      marginBottom: -3
                    }}
                  >
                    <span
                      style={{
                        color: 'gray',
                        borderRight: '1px solid black',
                        paddingRight: 4,
                        display: hideLineNumbers ? 'none' : 'inline-block'
                      }}
                    >
                      {(index + 1).toString().padEnd(4, ' ')}
                    </span>{' '}
                    {typeof line == 'object'
                      ? `${hideTimestamps ? '' : '[' + line.timestamp + ']'}[${
                          line.type
                        }][${line.app}] ${line.msg}`
                      : line}
                  </div>
                );
              })}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Logs;
