import React from 'react';
import { Modal, Table, Button } from 'react-bootstrap';

const TrialConversionModal = ({ title, accounts, setAccounts, app }) => {
  const handleClose = () => setAccounts(null);

  return (
    <Modal show={accounts} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
        <Button
          variant="danger"
          size="sm"
          style={{ marginLeft: 20 }}
          onClick={() => console.log(accounts)}
        >
          Print Accounts
        </Button>
      </Modal.Header>
      <Modal.Body
        style={{
          height: '100%',
          maxHeight: '60vh',
          overflow: 'auto',
          padding: 0
        }}
      >
        <Table striped bordered hover>
          <thead style={{ position: 'sticky', top: 0, background: '#F1F2F6' }}>
            <tr>
              <th>Customer Name</th>
              <th>Used Trial</th>
              <th>Purchased</th>
            </tr>
          </thead>
          <tbody>
            {accounts &&
              Object.keys(accounts).map(a => {
                if (accounts[a].trial == null) return;
                return (
                  <tr key={a}>
                    <td>
                      {accounts[a].trial?.CustomerName ||
                        accounts[a].firstPurchase?.CustomerName}
                    </td>
                    <td>{accounts[a].trial != null ? '✅' : '❌'}</td>
                    <td>{accounts[a].firstPurchase != null ? '✅' : '❌'}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default TrialConversionModal;
