import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { defaults } from 'chart.js';
import Loading from 'components/common/Loading';
import { getColor, rgbaColor } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useSearchParams } from 'react-router-dom';

const ActivityM8IFrame = () => {
  const [searchParams] = useSearchParams();
  const portalID = searchParams.get('portalId');
  const contactID = searchParams.get('hs_object_id');
  const email = searchParams.get('email');
  const firstname = searchParams.get('firstname')
    ? searchParams.get('firstname')
    : '';
  const lastname = searchParams.get('lastname')
    ? searchParams.get('lastname')
    : '';

  const colors = {
    pageViews: getColor('warning'),
    emailOpens: getColor('danger'),
    emailClicks: '#FF5F1F',
    conversationsSent: getColor('success'),
    forms: '#96a1a7',
    calls: '#64a0c8',
    whatsapp: '#128C7E',
    linkedin: '#0A66C2',
    meetings: '#FFC0CB',
    sms: '#A020F0'
  };

  defaults.font.family = 'Poppins';
  defaults.color = '#2E4450';

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const getData = async () => {
    let res = await axios.post('/activitym8/crmcard/getdata', {
      portalID,
      contactID,
      email
    });

    return res.data;
  };

  const parseData = async data => {
    //Sort Chronologically
    data.communications = data.communications.sort((a, b) => {
      return (
        new Date(b.properties.hs_timestamp) -
        new Date(a.properties.hs_timestamp)
      );
    });

    data.meetings = data.meetings.sort((a, b) => {
      return (
        new Date(b.properties.hs_timestamp) -
        new Date(a.properties.hs_timestamp)
      );
    });

    //Communications Length
    // prettier-ignore
    let sms = data.communications.filter(e => e.properties.hs_communication_channel_type === 'SMS').length;
    // prettier-ignore
    let whatsapp = data.communications.filter(e => e.properties.hs_communication_channel_type === 'WHATS_APP').length;
    // prettier-ignore
    let linkedin = data.communications.filter(e => e.properties.hs_communication_channel_type === 'LINKEDIN_MESSAGE').length;

    // Pie Chart Data
    let pieChart = [];
    // Page Views
    pieChart.push(data.webAnalytics?.web.length);
    // Email Opens
    pieChart.push(data.marketingEmails?.totals.OPEN);
    // Email Clicks
    pieChart.push(data.marketingEmails?.totals.CLICK);
    // Conversations Sent
    pieChart.push(data.emails?.sent?.length);
    // Forms
    pieChart.push(data.webAnalytics?.forms?.length);
    // Calls
    pieChart.push(data.calls?.results?.length);
    // Meetings
    pieChart.push(data.meetings?.length);
    // SMS
    pieChart.push(sms);
    // LinkedIn
    pieChart.push(linkedin);
    // Whatsapp
    pieChart.push(whatsapp);

    // Table
    let tableData = [
      {
        name: 'Page Views',
        value: data.webAnalytics?.web.length ? data.webAnalytics.web.length : 0
      },
      {
        name: 'Email Opens',
        value: data.marketingEmails?.totals.OPEN
          ? data.marketingEmails.totals.OPEN
          : 0
      },
      {
        name: 'Email Clicks',
        value: data.marketingEmails?.totals.CLICK
          ? data.marketingEmails.totals.CLICK
          : 0
      },
      {
        name: 'Conversations Sent',
        value: data.emails?.sent?.length ? data.emails.sent.length : 0
      },
      {
        name: 'Forms',
        value: data.webAnalytics?.forms?.length
          ? data.webAnalytics.forms.length
          : 0
      },
      {
        name: 'Calls',
        value: data.calls?.results?.length ? data.calls.results.length : 0
      },
      {
        name: 'Meetings',
        value: data.meetings?.length ? data.meetings.length : 0
      },
      {
        name: 'SMS',
        value: sms
      },
      {
        name: 'LinkedIn',
        value: linkedin
      },
      {
        name: 'WhatsApp',
        value: whatsapp
      }
    ];

    tableData.sort((a, b) => b.value - a.value);

    // Bar/Line Chart Data
    let lineChartData = [];
    let barChartDatasets = {
      pageViews: {
        label: 'Page Views',
        data: [],
        backgroundColor: rgbaColor(colors.pageViews, 0.7)
      },
      emailOpens: {
        label: 'Email Opens',
        data: [],
        backgroundColor: rgbaColor(colors.emailOpens, 0.7)
      },
      emailClicks: {
        label: 'Email Clicks',
        data: [],
        backgroundColor: rgbaColor(colors.emailClicks, 0.7)
      },
      conversationsSent: {
        label: 'Conversations Sent',
        data: [],
        backgroundColor: rgbaColor(colors.conversationsSent, 0.7)
      },
      forms: {
        label: 'Forms',
        data: [],
        backgroundColor: rgbaColor(colors.forms, 0.7)
      },
      calls: {
        label: 'Calls',
        data: [],
        backgroundColor: rgbaColor(colors.calls, 0.7)
      },
      meetings: {
        label: 'Meetings',
        data: [],
        backgroundColor: rgbaColor(colors.meetings, 0.7)
      },
      sms: {
        label: 'SMS',
        data: [],
        backgroundColor: rgbaColor(colors.sms, 0.7)
      },
      linkedin: {
        label: 'LinkedIn',
        data: [],
        backgroundColor: rgbaColor(colors.linkedin, 0.7)
      },
      whatsapp: {
        label: 'WhatsApp',
        data: [],
        backgroundColor: rgbaColor(colors.whatsapp, 0.7)
      }
    };
    let pageViewsIndex = data.webAnalytics?.web?.length - 1;
    let marketingEmailsIndex = data.marketingEmails?.events?.length - 1;
    let conversationsSentIndex = 0;
    let formsIndex = data.webAnalytics?.forms?.length - 1;
    let callsIndex = data.calls?.results?.length - 1;
    let meetingsIndex = data.meetings?.length - 1;
    let communicationsIndex = data.communications?.length - 1;

    let lineChartActivityCount = 0;
    // prettier-ignore
    for (
      let i = new Date(new Date().setDate(new Date().getDate() - 30));
      i <= new Date();
      i.setDate(i.getDate() + 1)
    ) {
      
      barChartDatasets.pageViews.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
      barChartDatasets.emailOpens.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
      barChartDatasets.emailClicks.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
      barChartDatasets.conversationsSent.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
      barChartDatasets.forms.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
      barChartDatasets.calls.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
      barChartDatasets.meetings.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
      barChartDatasets.sms.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
      barChartDatasets.linkedin.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
      barChartDatasets.whatsapp.data.push({ x: i.toISOString().slice(0, 10), y: 0 });
  
      // Loop over page views
      while (
        pageViewsIndex >= 0 &&
        data.webAnalytics.web[pageViewsIndex].occurredAt.slice(0, 10) ===
          i.toISOString().slice(0, 10)
      ) {
        barChartDatasets.pageViews.data[barChartDatasets.pageViews.data.length - 1].y++;
        lineChartActivityCount++;
        pageViewsIndex = pageViewsIndex - 1;
      }
      // Loop over marketing email events
      while (
        marketingEmailsIndex >= 0 &&
        new Date(data.marketingEmails.events[marketingEmailsIndex].created)
          .toISOString()
          .slice(0, 10) === i.toISOString().slice(0, 10)
      ) {
        let type = data.marketingEmails.events[marketingEmailsIndex].type;

        if (type === 'OPEN') {
          barChartDatasets.emailOpens.data[barChartDatasets.emailOpens.data.length - 1].y++;
          lineChartActivityCount++;

        } else if (type === 'CLICK') {
          barChartDatasets.emailClicks.data[barChartDatasets.emailClicks.data.length - 1].y++;
          lineChartActivityCount++;
        }
        marketingEmailsIndex = marketingEmailsIndex - 1;
      }
      // Loop over conversations sent
      while (
        conversationsSentIndex < data.emails?.sent?.length &&
        data.emails.sent[conversationsSentIndex].createdAt.slice(0, 10) === i.toISOString().slice(0, 10)
      ) {
        barChartDatasets.conversationsSent.data[barChartDatasets.conversationsSent.data.length - 1].y++;
        lineChartActivityCount++;
        conversationsSentIndex++;
      }
      // Loop over form submissions
      while (
        formsIndex >= 0 &&
        data.webAnalytics.forms[formsIndex].occurredAt.slice(0, 10) ===
          i.toISOString().slice(0, 10)
      ) {
        barChartDatasets.forms.data[barChartDatasets.forms.data.length - 1].y++;
        lineChartActivityCount++;
        formsIndex = formsIndex - 1;
      }

      // Loop over calls
      while (
        callsIndex >= 0 &&
        data.calls.results[callsIndex].properties.hs_timestamp.slice(0, 10) ===
          i.toISOString().slice(0, 10)
      ) {
        barChartDatasets.calls.data[barChartDatasets.calls.data.length - 1].y++;
        lineChartActivityCount++;
        callsIndex = callsIndex - 1;
      }

      // Loop over meetings
      while (
        meetingsIndex >= 0 &&
        data.meetings[meetingsIndex].properties.hs_timestamp.slice(0, 10) ===
          i.toISOString().slice(0, 10)
      ) {
        barChartDatasets.meetings.data[barChartDatasets.meetings.data.length - 1].y++;
        lineChartActivityCount++;
        meetingsIndex = meetingsIndex - 1;
      }

      // Loop over communications
      while (
        communicationsIndex >= 0 &&
        data.communications[communicationsIndex].properties.hs_timestamp.slice(0, 10) ===
          i.toISOString().slice(0, 10)
      ) {
        let type = data.communications[communicationsIndex].properties.hs_communication_channel_type;

        if (type === 'SMS') {
          barChartDatasets.sms.data[barChartDatasets.sms.data.length - 1].y++;
          lineChartActivityCount++;
        } else if (type === 'WHATS_APP') {
          barChartDatasets.whatsapp.data[barChartDatasets.whatsapp.data.length - 1].y++;
          lineChartActivityCount++;
        } else if (type === 'LINKEDIN_MESSAGE') {
          barChartDatasets.linkedin.data[barChartDatasets.linkedin.data.length - 1].y++;
          lineChartActivityCount++;
        }
        communicationsIndex = communicationsIndex - 1;
      }

      lineChartData.push({ x: i.toISOString().slice(0, 10), y: lineChartActivityCount });
      lineChartActivityCount = 0;
    }

    setData({
      pieChart: pieChart,
      barChart: barChartDatasets,
      table: tableData,
      lineChart: lineChartData
    });
  };

  const onPageLoad = async () => {
    let data = await getData();
    setErrors(data.errors);
    await parseData(data);
  };

  useEffect(() => {
    /* HubSpot GETs page on closing iframe as well 
       Timeout stops page from fetching data on close and logging as request */
    setTimeout(onPageLoad, 500);
  }, []);

  const loadLineChart = dataset => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      type: 'line',
      elements: {
        point: {
          radius: 2
        }
      },
      animation: {
        duration: 2000
      },
      scales: {
        x: {
          distribution: 'linear',
          ticks: {
            source: 'auto',
            autoSkip: true
          },
          type: 'time',
          time: {
            tooltipFormat: 'MMM, DD YYYY',
            parser: 'YYYYMMDD',
            displayFormats: {
              day: 'MMM DD'
            },
            unit: 'day'
          },
          min: new Date(new Date().setDate(new Date().getDate() - 30)),
          max: new Date()
        },
        y: {
          min: 0
        }
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: true,
          text: 'Engagement',
          font: {
            size: 16,
            family: "'Poppins'",
            weight: 'normal'
          }
        }
      }
    };

    const chartData = {
      labels: ['Total Activity'],
      datasets: [
        {
          label: 'Total Activity',
          data: dataset,
          backgroundColor: [rgbaColor(getColor('primary'), 0.7)],
          borderColor: [rgbaColor(getColor('primary'), 0.7)],
          tension: 0.2
        }
      ]
    };

    return <Line data={chartData} options={options} />;
  };

  const loadPieChart = dataset => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      type: 'pie',
      elements: {
        point: {
          radius: 2
        }
      },
      animation: {
        duration: 2000
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: true,
          text: 'Overall Activity Summary',
          font: {
            size: 16,
            family: "'Poppins'",
            weight: 'normal'
          }
        }
      }
    };

    const chartData = {
      labels: [
        'Page Views',
        'Email Opens',
        'Email Clicks',
        'Conversations Sent',
        'Forms',
        'Calls',
        'Meetings',
        'SMS',
        'LinkedIn',
        'WhatsApp'
      ],
      datasets: [
        {
          label: 'Activity Breakdown',
          data: dataset,
          backgroundColor: [
            rgbaColor(colors.pageViews, 0.7),
            rgbaColor(colors.emailOpens, 0.7),
            rgbaColor(colors.emailClicks, 0.7),
            rgbaColor(colors.conversationsSent, 0.7),
            rgbaColor(colors.forms, 0.7),
            rgbaColor(colors.calls, 0.7),
            rgbaColor(colors.meetings, 0.7),
            rgbaColor(colors.sms, 0.7),
            rgbaColor(colors.linkedin, 0.7),
            rgbaColor(colors.whatsapp, 0.7)
          ]
        }
      ]
    };

    return <Pie data={chartData} options={options} />;
  };

  const loadBarChart = datasets => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      type: 'bar',
      elements: {
        point: {
          radius: 2
        }
      },
      animation: {
        duration: 2000
      },
      scales: {
        x: {
          stacked: true,
          distribution: 'linear',
          ticks: {
            source: 'auto',
            autoSkip: true
          },
          type: 'time',
          time: {
            tooltipFormat: 'MMM, DD YYYY',
            parser: 'YYYYMMDD',
            displayFormats: {
              day: 'MMM DD'
            },
            unit: 'day'
          },
          min: new Date(new Date().setDate(new Date().getDate() - 30)),
          max: new Date()
        },
        y: {
          stacked: true,
          min: 0
        }
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: true,
          text: 'Activity Breakdown Per Day',
          font: {
            size: 16,
            family: "'Poppins'",
            weight: 'normal'
          }
        }
      }
    };

    const chartData = {
      datasets: [
        datasets.emailClicks,
        datasets.emailOpens,
        datasets.pageViews,
        datasets.conversationsSent,
        datasets.forms,
        datasets.calls,
        datasets.meetings,
        datasets.sms,
        datasets.linkedin,
        datasets.whatsapp
      ]
    };

    return <Bar data={chartData} options={options} />;
  };

  if (Object.keys(data).length == 0) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div style={{ padding: 8, paddingTop: 0 }}>
        <h1 style={{ fontSize: 24, marginBottom: 14 }}>
          Activity Summary For {firstname} {lastname} Last 30 Days
        </h1>
        <div>
          {/* prettier-ignore */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignContent: 'center',
              marginBottom: 24
            }}
          >
            <LegendItem name="Email Clicks" color={colors.emailClicks} error={errors.marketingEmails} />
            <LegendItem name="Email Opens" color={colors.emailOpens} error={errors.marketingEmails} />
            <LegendItem name="Page Views" color={colors.pageViews} error={errors.web} />
            <LegendItem name="Conversations Sent" color={colors.conversationsSent} error={errors.emails} />
            <LegendItem name="Forms" color={colors.forms} error={errors.forms} />
            <LegendItem name="Calls" color={colors.calls} error={errors.calls} />
            <LegendItem name="Meetings" color={colors.meetings} error={errors.meetings} />
            <LegendItem name="SMS" color={colors.sms} error={errors.communications} />
            <LegendItem name="LinkedIn" color={colors.linkedin} error={errors.communications} />
            <LegendItem name="WhatsApp" color={colors.whatsapp} error={errors.communications} />
          </div>
        </div>
        {/* <Row>
          <Col md={6}>
            <Card style={{ padding: 8 }}>
              <div style={{ height: 300 }}>{loadPieChart(data.pieChart)}</div>
            </Card>
          </Col>
          <Col md={6}>
            <Card style={{ padding: 8 }}>
              <div style={{ height: 300 }}>{loadBarChart(data.barChart)}</div>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }}>
          <Col md={6}>
            <Card style={{ padding: 8 }}>
              <div style={{ height: 300, overflowY: 'auto' }}>
                <div style={{ textAlign: 'center', marginTop: 8 }}>
                  <h2 style={{ fontWeight: 'normal', fontSize: 24 }}>
                    Activity Breakdown:
                  </h2>
                </div>
                <Table striped hover style={{ margin: 0 }}>
                  <thead>
                    <tr>
                      <th>Activity</th>
                      <th style={{ textAlign: 'center' }}>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <strong>
                          {data.table.reduce((a, b) => a + b.value, 0)}
                        </strong>
                      </td>
                    </tr>
                    {data.table.map((item, index) => {
                      if (item.value === 0) {
                        return null;
                      }
                      return (
                        <tr key={index} style={{ height: 24 }}>
                          <td>{item.name}</td>
                          <td style={{ textAlign: 'center' }}>{item.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
          <Col md={6}>
            <Card style={{ padding: 8 }}>
              <div style={{ height: 300 }}>{loadLineChart(data.lineChart)}</div>
            </Card>
          </Col>
        </Row> */}
        <div
          style={{ width: '100%', height: '100%', display: 'flex', gap: 10 }}
        >
          <div
            style={{
              height: '100%',
              width: '60%',
              display: 'flex',
              flexDirection: 'column',
              gap: 10
            }}
          >
            <Card style={{ padding: 8 }}>
              <div style={{ height: 205 }}>{loadLineChart(data.lineChart)}</div>
            </Card>
            <Card style={{ padding: 8 }}>
              <div style={{ height: 205 }}>{loadBarChart(data.barChart)}</div>
            </Card>
          </div>
          <div style={{ height: '100%', width: '40%' }}>
            <Card style={{ padding: 8 }}>
              <div style={{ height: 205 }}>{loadPieChart(data.pieChart)}</div>
              <div style={{ height: 231, overflowY: 'auto', paddingTop: 6 }}>
                <Table striped hover style={{ margin: 0, fontSize: 12 }}>
                  <thead>
                    <tr>
                      <th style={{ padding: 8 }}>Activity</th>
                      <th style={{ textAlign: 'center', padding: 8 }}>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ padding: 8 }}>
                        <strong>Total</strong>
                      </td>
                      <td style={{ textAlign: 'center', padding: 8 }}>
                        <strong>
                          {data.table.reduce((a, b) => a + b.value, 0)}
                        </strong>
                      </td>
                    </tr>
                    {data.table.map((item, index) => {
                      if (item.value === 0) {
                        return null;
                      }
                      return (
                        <tr key={index} style={{ height: 24 }}>
                          <td style={{ padding: 8 }}>{item.name}</td>
                          <td style={{ textAlign: 'center', padding: 8 }}>
                            {item.value}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityM8IFrame;

const LegendItem = ({ name, color, error = false }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        error ? (
          <Tooltip id="overlay-trigger">
            Activity-M8 was unable to retrieve this data from HubSpot.
          </Tooltip>
        ) : (
          <></>
        )
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginRight: 8
        }}
        data-test-id={name.toLowerCase() + '-legend-item'}
      >
        {error ? (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            style={{ height: 16, paddingRight: 4, color: getColor('danger') }}
          />
        ) : (
          <div
            style={{
              width: 16,
              height: 16,
              backgroundColor: rgbaColor(color, 0.7),
              marginRight: 4
            }}
          />
        )}
        <p style={{ margin: 0, fontSize: 13 }}>{name}</p>
      </div>
    </OverlayTrigger>
  );
};
