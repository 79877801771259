import React, { useState, useEffect } from 'react';
import { Alert, Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { textAppNames } from 'helpers/utils';

const DowngradeAlert = props => {
  const [hidden, setHidden] = useState(true);
  const [showModal, setShowModal] = useState();

  const remainingMS = new Date(new Date(props.account.PaidUntil) - new Date());

  const remainingDays = Math.floor(remainingMS / 8.64e7);

  useEffect(() => {
    if (props.account.SubscriptionStatus == 'downgraded') {
      setHidden(false);
    }
  }, [props.account]);

  const undowngradeSubscription = () => {
    axios
      .post('/subscriptions/undowngrade-subscription', {
        app: props.app,
        subscriptionID: props.account.SubscriptionID
      })
      .then(res => {
        window.location.reload();
      });
  };

  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  const formatPrice = price => {
    price = price.toString();
    if (price.slice(-2) == '00') {
      return price.slice(0, -2);
    } else if (price == '0') {
      return price;
    } else {
      return price.slice(0, -2) + '.' + price.slice(-2);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Restart Plan Payments
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure you want to restart your plan?</h4>
          <p>
            Your {textAppNames[props.app]} plan will continue and you will be
            charged as normal on the{' '}
            {new Date(props.account.PaidUntil).toLocaleDateString(
              'en-gb',
              dateOptions
            )}
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Close</Button>
          <Button
            variant="danger"
            onClick={() => {
              undowngradeSubscription();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Alert
        variant="warning"
        onClose={() => setHidden(true)}
        dismissible
        hidden={hidden}
      >
        <div className="horizontalDiv">
          <>
            Your {textAppNames[props.app]} plan is downgrading in{' '}
            {remainingDays} days.
          </>
          <a
            className="reactivateSubButton text-danger"
            onClick={() => setShowModal(true)}
          >
            Undo
          </a>
        </div>
      </Alert>
    </>
  );
};

DowngradeAlert.propTypes = {
  app: PropTypes.string,
  account: PropTypes.object
};

export default DowngradeAlert;
