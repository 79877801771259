import React, { useState, useEffect } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import axios from 'axios';

const AdminValid8Settings = props => {
  const [settings, setSettings] = useState(null);
  const [saveLocked, setSaveLocked] = useState(true);

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const { data: settings } = await axios.get(
      `/pmladmin/valid8settings?accountID=${props.data.AccountID}`
    );
    setSettings(settings);
  };

  const saveSettings = async () => {
    await axios.post('/pmladmin/valid8settings', {
      accountID: props.data.AccountID,
      settings
    });
    setSaveLocked(true);
    getSettings();
  };

  return (
    <>
      <Card style={{ height: 300 }}>
        <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: 12
            }}
          >
            <h2 style={{ fontWeight: 'normal', fontSize: 24 }}>
              Valid8 Settings
            </h2>
          </div>
          {settings && (
            <div
              style={{
                flexGrow: 1,
                flexBasis: 1,
                flex: 'flex-grow'
              }}
            >
              <div
                style={{
                  paddingBottom: 8,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <p style={{ margin: 0 }}>Automatic Email Validation</p>
                <Form.Check
                  type="switch"
                  id="webhooks"
                  defaultChecked={settings.webhooks}
                  onChange={() => {
                    setSaveLocked(false);
                    setSettings({ ...settings, webhooks: !settings.webhooks });
                  }}
                  checked={settings.webhooks}
                />
              </div>
              <div
                style={{
                  paddingBottom: 8,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <p style={{ margin: 0 }}>Enable CRM Card</p>
                <Form.Check
                  type="switch"
                  id="crmcard"
                  defaultChecked={settings.crmcard}
                  onChange={() => {
                    setSaveLocked(false);
                    setSettings({ ...settings, crmcard: !settings.crmcard });
                  }}
                  checked={settings.crmcard}
                />
              </div>
              <div
                style={{
                  paddingBottom: 8,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <p style={{ margin: 0 }}>CRM Card HS Properties</p>
                <Form.Check
                  type="switch"
                  id="crmcardUseHSData"
                  defaultChecked={settings.crmcardUseHSData}
                  onChange={() => {
                    setSaveLocked(false);
                    setSettings({
                      ...settings,
                      crmcardUseHSData: !settings.crmcardUseHSData
                    });
                  }}
                  checked={settings.crmcardUseHSData}
                />
              </div>
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <Button
              variant="danger"
              style={{ width: '100%' }}
              onClick={() => {
                saveSettings();
              }}
              disabled={saveLocked}
            >
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AdminValid8Settings;
