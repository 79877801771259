import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form, Button, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import { currencies } from 'data/currencies';
import axios from 'axios';
import Lottie from 'lottie-react';
import loadingJSON from 'assets/animations/loading.json';
import IconButton from 'components/common/IconButton';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useScrollbarWidth } from 'hooks/useScrollbarWidth';
import { useIsOverflow } from 'hooks/useIsOverflow';

const IFrame = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const scrollbarWidth = useScrollbarWidth();

  const amount = searchParams.get('amount');
  const currency = searchParams.get('currency');
  const dealID = searchParams.get('hs_object_id');
  const portalID = searchParams.get('portalId');

  const [outputCurrency, setOutputCurrency] = useState('');
  const [accountType, setAccountType] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const [conversion, setConversion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [appState, setAppState] = useState(1);
  const [error, setError] = useState(null);
  const [updatedLineItems, setUpdatedLineItems] = useState(false);
  const [adminEmail, setAdminEmail] = useState('');

  const [inputHidden, setInputHidden] = useState('none');
  const [tableOverflowing, setTableOverflowing] = useState(false);

  let isDarkMode = document.querySelector('html').classList.contains('dark');
  if (isDarkMode) {
    document.querySelector('html').classList.remove('dark');
  }

  useEffect(() => {
    onPageLoad();
  }, []);
  useEffect(() => {
    const element = document.getElementById('lineitemtablebody');
    if (element == null) {
      return;
    }
    const hasOverflowingChildren =
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth;

    setTableOverflowing(hasOverflowingChildren);
  });

  const onPageLoad = async () => {
    setLoading(true);

    let { data: settings } = await axios.get(
      '/settings/currencyr8?portalID=' + portalID
    );
    if (!settings.crmcard) {
      setAdminEmail(settings.adminEmail);
      setError('CARD_DISABLED');
      setLoading(false);
      return;
    }

    Promise.all(
      [
        axios
          .post(
            `${process.env.REACT_APP_WEBHOOKS_URL}/currencyr8/getaccounttype`,
            {
              portalID: portalID
            }
          )
          .then(res => {
            setAccountType(res.data);
          })
          .catch(err => {
            setError('ACCOUNT_NOT_FOUND');
          })
      ],
      [
        axios
          .post(
            `${process.env.REACT_APP_WEBHOOKS_URL}/currencyr8/getlineitems`,
            {
              dealID: dealID,
              portalID: portalID
            }
          )
          .then(res => {
            setLineItems(res.data.results);
          })
          .catch(err => {
            setError('MISSING_SCOPES');
          })
      ]
    ).then(() => {
      // setAppState(4);
      setLoading(false);
    });
  };

  const convertCurrency = () => {
    if (outputCurrency == '') {
      return;
    }
    if (outputCurrency == currency) {
      return;
    }
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_WEBHOOKS_URL}/currencyr8/convert`, {
        input: currency,
        output: outputCurrency,
        amount: amount,
        portalID: portalID,
        dealID: dealID
      })
      .then(res => {
        if (res.data.error) {
          setError(res.data.error);
          setLoading(false);
        } else {
          setConversion(res.data);
          setAppState(2);
          setLoading(false);
        }
      });
  };

  const updateDeal = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_WEBHOOKS_URL}/currencyr8/crmcard/update`, {
        ...conversion,
        dealID: dealID,
        portalID: portalID
      })
      .then(res => {
        setTimeout(() => {
          if (lineItems.length > 0) {
            setAppState(4);
          } else {
            setAppState(3);
          }
          setLoading(false);
        }, 1000);
      })
      .catch(err => {
        setAppState(3);
        setLoading(false);
      });
  };

  const updateLineItems = () => {
    setLoading(true);
    let lineItemProperties = [];
    let total = 0;
    for (let i = 0; i < lineItems.length; i++) {
      total =
        total +
        parseFloat(
          formatPrice(
            lineItems[i].properties.price *
              conversion.rate *
              lineItems[i].properties.quantity
          )
        );
      lineItemProperties.push({
        id: lineItems[i].id,
        properties: {
          price: formatPrice(lineItems[i].properties.price * conversion.rate),
          hs_line_item_currency_code: outputCurrency
        }
      });
    }
    axios
      .post(
        `${process.env.REACT_APP_WEBHOOKS_URL}/currencyr8/updatelineitems`,
        {
          lineItemProperties: lineItemProperties,
          portalID: portalID,
          dealID: dealID,
          total: total,
          currency: outputCurrency
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      .then(res => {
        setTimeout(() => {
          setUpdatedLineItems(true);
          setAppState(3);
          setLoading(false);
        }, 1000);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const formatPrice = price => {
    return parseFloat(price).toFixed(2);
  };

  const renderLineItemsTable = () => {
    return (
      <tbody
        id="lineitemtablebody"
        style={{
          display: 'block',
          overflowX: 'hidden',
          overflowY: 'auto',
          width: 578,
          height: 145
        }}
      >
        {lineItems.map((lineItem, idx) => {
          const originalSign = currencies.filter(c => {
            return c.symbol === currency;
          })[0].sign;

          const newSign = currencies.filter(c => {
            return c.symbol === conversion.outputCurrency;
          })[0].sign;

          return (
            <tr
              key={idx}
              style={{
                width: tableOverflowing ? 2578 - scrollbarWidth : 578,
                display: 'inline-block'
              }}
            >
              <td
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: tableOverflowing ? 278 - scrollbarWidth : 278,
                  display: 'inline-block',
                  fontSize: 13,
                  textAlign: 'left'
                }}
              >
                {lineItems[idx].properties.name}
              </td>
              <td
                className="text-center"
                style={{
                  width: 150,
                  display: 'inline-block',
                  fontSize: 13
                }}
              >
                {originalSign}
                {Number(
                  formatPrice(
                    lineItems[idx].properties.price *
                      lineItems[idx].properties.quantity
                  )
                ).toLocaleString()}
              </td>
              <td
                className="text-center"
                style={{
                  width: 150,
                  display: 'inline-block',
                  fontSize: 13
                }}
              >
                {newSign}
                {Number(
                  formatPrice(
                    lineItems[idx].properties.price *
                      conversion.rate *
                      lineItems[idx].properties.quantity
                  )
                ).toLocaleString()}
              </td>
            </tr>
          );
        })}
        {/* {renderTotalRow()} */}
      </tbody>
    );
  };

  const renderTotalRow = () => {
    let total = 0;
    for (let i = 0; i < lineItems.length; i++) {
      total = total + parseFloat(formatPrice(lineItems[i].properties.price));
    }
    return (
      <tr>
        <th
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 280,
            display: 'inline-block',
            paddingLeft: 0
          }}
        >
          Total:
        </th>
        <th
          className="text-end"
          style={{
            width: 150,
            display: 'inline-block'
          }}
        >
          {formatPrice(total)}
        </th>
        <th
          className="text-end"
          style={{
            width: 150,
            display: 'inline-block'
          }}
        >
          {formatPrice(total * conversion.rate)}
        </th>
      </tr>
    );
  };

  if (loading) {
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <Lottie
          animationData={loadingJSON}
          loop
          autoplay
          style={{
            width: '200px',
            height: '200px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </div>
    );
  }

  if (error != null) {
    if (error === 'ACCOUNT_NOT_PAID') {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            width: '100vw',
            height: '100vh',
            padding: 10
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <span style={{ fontSize: 24 }}>
              You do not have an active Currency-R8 plan or have run out of
              credits. Please login to your Pacific Marketing Labs portal to
              select a plan or upgrade your plan.
            </span>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 10,
              width: 'calc(100vw - 30px)'
            }}
          >
            <Button
              variant="danger"
              className="btn-block"
              onClick={() => {
                window.open('/login', '_blank').focus();
                window.parent.postMessage(
                  JSON.stringify({ action: 'DONE' }),
                  '*'
                );
              }}
              style={{ width: 'calc(50% - 5px)', marginRight: 10 }}
            >
              Login
            </Button>
            <Button
              variant="danger"
              className="btn-block"
              onClick={() =>
                window.parent.postMessage(
                  JSON.stringify({ action: 'DONE' }),
                  '*'
                )
              }
              style={{ width: 'calc(50% - 5px)' }}
            >
              Close
            </Button>
          </div>
        </div>
      );
    } else if (error === 'ACCOUNT_NOT_FOUND') {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            width: '100vw',
            height: '100vh',
            padding: 10
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <span style={{ fontSize: 24 }}>
              Pacific Marketing Labs account not found. Please signup to use
              CurrencyR8
            </span>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 10,
              width: 'calc(100vw - 30px)'
            }}
          >
            <Button
              variant="danger"
              className="btn-block"
              onClick={() => {
                window.open('/signup', '_blank').focus();
                window.parent.postMessage(
                  JSON.stringify({ action: 'DONE' }),
                  '*'
                );
              }}
              style={{ width: 'calc(50% - 5px)', marginRight: 10 }}
            >
              Signup
            </Button>
            <Button
              variant="danger"
              className="btn-block"
              onClick={() =>
                window.parent.postMessage(
                  JSON.stringify({ action: 'DONE' }),
                  '*'
                )
              }
              style={{ width: 'calc(50% - 5px)' }}
            >
              Close
            </Button>
          </div>
        </div>
      );
    } else if (error === 'MISSING_SCOPES') {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            width: '100vw',
            height: '100vh',
            padding: 10
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <span style={{ fontSize: 24 }}>
              Please login to your Pacific Marketing Labs portal and update
              Currency-R8 to the latest version.
            </span>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 10,
              width: 'calc(100vw - 30px)'
            }}
          >
            <Button
              variant="danger"
              className="btn-block"
              onClick={() => {
                window.open('/login', '_blank').focus();
                window.parent.postMessage(
                  JSON.stringify({ action: 'DONE' }),
                  '*'
                );
              }}
              style={{ width: 'calc(50% - 5px)', marginRight: 10 }}
            >
              Login
            </Button>
            <Button
              variant="danger"
              className="btn-block"
              onClick={() =>
                window.parent.postMessage(
                  JSON.stringify({ action: 'DONE' }),
                  '*'
                )
              }
              style={{ width: 'calc(50% - 5px)' }}
            >
              Close
            </Button>
          </div>
        </div>
      );
    } else if (error === 'API_ERROR') {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            width: '100vw',
            height: '100vh',
            padding: 10
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <span style={{ fontSize: 24 }}>
              Something went wrong. Please try again later.
            </span>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 10,
              width: 'calc(100vw - 30px)'
            }}
          >
            <Button
              variant="danger"
              className="btn-block"
              onClick={() =>
                window.parent.postMessage(
                  JSON.stringify({ action: 'DONE' }),
                  '*'
                )
              }
            >
              Close
            </Button>
          </div>
        </div>
      );
    } else if (error === 'CARD_DISABLED') {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            width: '100vw',
            height: '100vh',
            padding: 10
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <span style={{ fontSize: 24 }}>
              Sorry, this functionality is currently disabled on your account.
              Please contact your Pacific Marketing Labs account holder (
              <a href={`mailto:${adminEmail}`}>{adminEmail}</a>) if you would
              like to have this feature enabled.
            </span>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 10,
              width: 'calc(100vw - 30px)'
            }}
          >
            <Button
              variant="danger"
              className="btn-block"
              onClick={() =>
                window.parent.postMessage(
                  JSON.stringify({ action: 'DONE' }),
                  '*'
                )
              }
            >
              Close
            </Button>
          </div>
        </div>
      );
    }
  }

  if (appState == 1) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          width: '100vw',
          height: '100vh',
          padding: 10
        }}
      >
        <h1 style={{ paddingBottom: 12 }}>Existing Deal Amount</h1>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            paddingBottom: 16
          }}
        >
          <span style={{ fontSize: 64 }}>
            {
              currencies.filter(c => {
                return c.symbol === currency;
              })[0].sign
            }
            {Number(amount).toLocaleString()}
          </span>
          <span style={{ fontSize: 24 }}>{currency}</span>
        </div>
        <h1 style={{ paddingBottom: 12 }}>Convert To:</h1>
        <Form.Group className="mb-2">
          <Form.Select
            value={outputCurrency}
            onChange={e => setOutputCurrency(e.target.value)}
          >
            <option value={''}>-- SELECT A CURRENCY --</option>
            <option value="USD">United States Dollar (USD) $</option>
            <option value="GBP">British Pound Sterling (GBP) £</option>
            <option value="EUR">Euro (EUR) €</option>
            <option value="JPY">Japanese Yen (JPY) ¥</option>
            <option value="AUD">Australian Dollar (AUD) $</option>
            <option value="">-----------------------------</option>
            {currencies.map((currency, idx) => {
              return (
                <option value={currency.symbol} key={idx}>
                  {currency.name} ({currency.symbol}) {currency.sign}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>

        <div
          style={{
            position: 'absolute',
            bottom: 10,
            width: 'calc(100vw - 20px)'
          }}
        >
          <Button
            variant="danger"
            className="btn-block"
            onClick={() => {
              convertCurrency();
            }}
          >
            Request Conversion
          </Button>
        </div>
      </div>
    );
  } else if (appState == 2) {
    let total;
    if (lineItems.length > 0) {
      total = 0;
      for (let i = 0; i < lineItems.length; i++) {
        total =
          total +
          parseFloat(
            formatPrice(
              lineItems[i].properties.price *
                conversion.rate *
                lineItems[i].properties.quantity
            )
          );
      }
    }
    return (
      <div
        style={{
          backgroundColor: '#fff',
          width: '100vw',
          height: '100vh',
          padding: 10
        }}
      >
        {/* 3 Boxes Div */}
        <div
          style={{
            width: '100%',
            height: 100,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              width: 'calc((100% - 32px) / 3)',
              height: 100,
              border: '1px solid #cbd0d3',
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <h5 style={{ fontSize: 13 }}>Existing Deal Amount</h5>
            <p
              style={{
                textAlign: 'center',
                width: '100%',
                fontSize: 24,
                paddingTop: 12,
                overflowX: 'auto',
                overflowY: 'hidden',
                height: '70px',
                margin: 0
              }}
            >
              {
                currencies.filter(c => {
                  return c.symbol === currency;
                })[0].sign
              }
              {Number(amount).toLocaleString()}
            </p>
          </div>
          <div
            style={{
              width: 'calc((100% - 32px) / 3)',
              height: 100,
              border: '1px solid #cbd0d3',
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <h5 style={{ fontSize: 13 }}>Exchange Rate</h5>
            <p
              style={{
                textAlign: 'center',
                width: '100%',
                fontSize: 14,
                paddingTop: 18,
                overflowX: 'auto',
                overflowY: 'hidden',
                height: '70px',
                margin: 0
              }}
            >
              {conversion.rate} {conversion.outputCurrency}
            </p>
          </div>
          <div
            style={{
              width: 'calc((100% - 32px) / 3)',
              height: 100,
              border: '1px solid #cbd0d3',
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <h5 style={{ fontSize: 13 }}>Converted Deal Amount</h5>
            <p
              style={{
                textAlign: 'center',
                width: '100%',
                fontSize: 24,
                paddingTop: 12,
                overflowX: 'auto',
                overflowY: 'hidden',
                height: '70px',
                margin: 0
              }}
            >
              {
                currencies.filter(c => {
                  return c.symbol === conversion.outputCurrency;
                })[0].sign
              }
              {lineItems.length > 0
                ? Number(total).toLocaleString()
                : Number(conversion.outputAmountString).toLocaleString()}
            </p>
            {lineItems.length > 0 && (
              <p
                style={{
                  position: 'relative',
                  bottom: '0px',
                  margin: 0,
                  fontSize: 8,
                  color: '#cbd0d3'
                }}
              >
                (calculated using line item prices)
              </p>
            )}
          </div>
        </div>
        {/* Table Div */}
        {lineItems.length > 0 && (
          <div style={{ border: '1px solid #cbd0d3', marginTop: 16 }}>
            <Table hover responsive style={{ tableLayout: 'fixed', margin: 0 }}>
              <thead>
                <tr
                  style={{
                    backgroundColor: '#cbd0d3',
                    width: tableOverflowing ? 578 - scrollbarWidth : 578
                  }}
                >
                  <th
                    scope="col"
                    style={{
                      width: tableOverflowing ? 278 - scrollbarWidth : 278,
                      fontSize: 13
                    }}
                  >
                    Line Item
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{
                      width: 150,
                      fontSize: 13
                    }}
                  >
                    {conversion.inputCurrency} Price
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{
                      width: 150,
                      fontSize: 13
                    }}
                  >
                    {conversion.outputCurrency} Price
                  </th>
                </tr>
              </thead>
              {renderLineItemsTable()}
            </Table>
          </div>
        )}
        {/* 2 Buttons Div */}
        <div
          style={{
            position: 'absolute',
            bottom: 10,
            width: 'calc(100vw - 20px)',
            display: 'flex'
          }}
        >
          <input
            id="output"
            value={conversion.outputAmountString}
            style={{ display: inputHidden }}
          />
          <Button
            variant="danger"
            className="btn-block"
            onClick={async () => {
              try {
                // navigator.clipboard.writeText(conversion.outputAmountString);
                await setInputHidden('block');
                document.querySelector('#output').select();
                document.execCommand('copy');
                setInputHidden('none');
              } catch (error) {
                console.log(error);
              }
            }}
            style={{ width: 'calc(50% - 5px)', marginRight: 10 }}
          >
            Copy Total to Clipboard
          </Button>
          {accountType == 'free' ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="overlay-trigger-example">
                  Upgrade to Currency-R8 Starter or Pro to unlock this feature.
                </Tooltip>
              }
            >
              <div style={{ width: 'calc(50% - 5px)' }}>
                <IconButton
                  variant="danger"
                  className="btn-block"
                  // style={{ width: 'calc(50% - 5px)' }}
                  disabled={true}
                  icon={faLock}
                >
                  Update Deal
                </IconButton>
              </div>
            </OverlayTrigger>
          ) : (
            <Button
              variant="danger"
              className="btn-block"
              onClick={() => {
                if (lineItems.length > 0) {
                  updateLineItems();
                } else {
                  updateDeal();
                }
              }}
              style={{ width: 'calc(50% - 5px)' }}
            >
              Update Deal {lineItems.length > 0 && 'and Line Items'}
            </Button>
          )}
        </div>
      </div>
    );
  } else if (appState == 3) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          width: '100vw',
          height: '100vh',
          padding: 10
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex'
          }}
        >
          <span style={{ fontSize: 24 }}>
            Currency-R8 has now updated your deals
            {updatedLineItems ? '/line items' : ''} amount and currency but this
            will only be available once you close this window and refresh the
            deal page.
          </span>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 10,
            width: 'calc(100vw - 30px)'
          }}
        >
          <Button
            variant="danger"
            className="btn-block"
            onClick={() =>
              window.parent.postMessage(JSON.stringify({ action: 'DONE' }), '*')
            }
          >
            Close
          </Button>
        </div>
      </div>
    );
  } else if (appState == 4) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          width: '100vw',
          height: '100vh',
          padding: 10
        }}
      >
        <h1 style={{ paddingBottom: 12 }}>Also Update Line Items?</h1>
        <div>
          <Table
            hover
            responsive
            className="cr8-lineitems-table"
            style={{ tableLayout: 'fixed', margin: '0px !important' }}
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{
                    width: 280,
                    paddingLeft: 0
                  }}
                >
                  Line Item
                </th>
                <th scope="col" className="text-end" style={{ width: 150 }}>
                  {conversion.inputCurrency} Price
                </th>
                <th scope="col" className="text-end" style={{ width: 150 }}>
                  {conversion.outputCurrency} Price
                </th>
              </tr>
            </thead>
            {renderLineItemsTable()}
          </Table>
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: 10,
            width: 'calc(100vw - 30px)',
            display: 'flex'
          }}
        >
          <Button
            variant="danger"
            className="btn-block"
            onClick={() => setAppState(3)}
            style={{ width: 'calc(50% - 5px)', marginRight: 10 }}
          >
            Skip
          </Button>
          <Button
            variant="danger"
            className="btn-block"
            onClick={() => updateLineItems()}
            style={{ width: 'calc(50% - 5px)' }}
          >
            Update Line Items
          </Button>
        </div>
      </div>
    );
  }
};

export default IFrame;
