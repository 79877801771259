import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Lottie from 'lottie-react';
import loading from 'assets/animations/loading.json';
import { textAppNames } from 'helpers/utils';

const CancellationModal = props => {
  const [modalJSX, setModalJSX] = useState();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setModalJSX(cancellationText);
  }, [props]);

  const loadingAnimation = (
    <Lottie
      animationData={loading}
      loop
      autoplay
      style={{ width: '200px', height: '200px', margin: 'auto' }}
    />
  );

  var dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  const cancellationText = (
    <>
      <h4>Are you sure you want to cancel your plan?</h4>
      <p>
        You will lose access to all {textAppNames[props.app]} features after{' '}
        {new Date(props.account.PaidUntil).toLocaleDateString(
          'en-gb',
          dateOptions
        )}
        . The plan can be reactivated before then, or a new plan can be
        purchased after.
      </p>
    </>
  );

  const cancelSubscription = () => {
    setModalJSX(loadingAnimation);
    axios
      .post('/subscriptions/cancel-subscription', {
        app: props.app
      })
      .then(res => {
        setModalJSX(<p>{res.data}</p>);
        setCompleted(true);
      })
      .catch(err =>
        setModalJSX(<p>Something went wrong. Please try again later.</p>)
      );
  };

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={() => props.setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cancel Subscription
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalJSX}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.setShowModal(false)} hidden={completed}>
            Go Back
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              cancelSubscription();
            }}
            hidden={completed}
          >
            Confirm Cancellation
          </Button>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            hidden={!completed}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CancellationModal.propTypes = {
  app: PropTypes.string,
  account: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func
};

export default CancellationModal;
