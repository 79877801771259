import React, { useState, useEffect } from 'react';
import PageTitle from 'components/common/PageTitle';
import NavbarTop from 'components/navbar/top/NavbarTop';
import Lottie from 'lottie-react';
import loadingJSON from 'assets/animations/loading.json';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Modal, Alert, Form } from 'react-bootstrap';

const Admins = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [showNewAdminModal, setShowNewAdminModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    onPageLoad();
  }, []);

  const onPageLoad = async () => {
    const isAdmin = await axios
      .post('/pmladmin/isadmin')
      .then(res => res.data)
      .catch(err => console.log(err));

    if (isAdmin != true) {
      return navigate('/page-not-found');
    }

    await fetchAdmins();
    setLoading(false);
  };

  const fetchAdmins = async () => {
    await axios
      .post('/pmladmin/listadmins')
      .then(res => setAdmins(res.data))
      .catch(err => console.log(err));
  };

  const removeAdmin = async id => {
    let res = await axios.post('/pmladmin/removeadmin', { id });
    fetchAdmins();
  };

  const addAdmin = async () => {
    setModalLoading(true);
    setShowAlert(false);
    if (admins.filter(admin => admin.Email == email).length > 0) {
      setAlertText('User is already an admin');
      setAlertVariant('danger');
      setShowAlert(true);
      setModalLoading(false);
      return;
    }
    const res = await axios.post('/pmladmin/addadmin', { email });
    setAlertText(res.data);
    if (res.data == 'User added successfully') {
      setAlertVariant('success');
    } else {
      setAlertVariant('danger');
    }
    fetchAdmins();
    setShowAlert(true);
    setModalLoading(false);
  };

  if (loading) {
    return (
      <div style={{ width: '100vw', height: '100%' }}>
        <Lottie
          animationData={loadingJSON}
          loop
          autoplay
          style={{
            width: '200px',
            height: '200px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </div>
    );
  }

  return (
    <>
      {/* Add New Admin Modal */}
      <Modal
        show={showNewAdminModal}
        onHide={() => {
          setShowNewAdminModal(false);
          setShowAlert(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Admin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={alertVariant} hidden={!showAlert}>
            {alertText}
          </Alert>
          <div hidden={modalLoading}>
            <p>
              If the user exists, they will be able to view/edit all accounts
              and also add new users.
            </p>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>
          </div>
          <Lottie
            animationData={loadingJSON}
            loop
            autoplay
            style={{ width: '100px', height: '100px', margin: 'auto' }}
            hidden={!modalLoading}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowNewAdminModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => addAdmin()}>
            Add Admin
          </Button>
        </Modal.Footer>
      </Modal>

      <NavbarTop simple />
      <PageTitle app="" page="Admins" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: 16,
          paddingRight: 20
        }}
      >
        <p>
          All admins will have access to edit/view all accounts and also add new
          users.
        </p>
        <Button variant="danger" onClick={() => setShowNewAdminModal(true)}>
          Add Admin
        </Button>
      </div>

      <Table responsive hover>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col" className="text-end"></th>
          </tr>
        </thead>
        <tbody>
          {admins.map(admin => (
            <tr key={admin.UserID}>
              <td>
                {admin.FirstName} {admin.LastName}
              </td>
              <td>{admin.Email}</td>
              <td className="text-end">
                <Button
                  variant="danger"
                  disabled={localStorage.getItem('email') == admin.Email}
                  onClick={() => removeAdmin(admin.UserID)}
                >
                  Remove Admin
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Admins;
