import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { getColor, rgbaColor } from 'helpers/utils';

const BarChart = props => {
  const [values, setValues] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    setValues([]);
    setLabels([]);
    if (props.data !== undefined) {
      formatValues();
    }
  }, [props]);

  const formatValues = () => {
    let data = [];
    for (let i = 0; i < props.data.length; i++) {
      let obj = { label: props.data[i].To, y: 0 };
      if (!data.some(e => e.label === props.data[i].To)) {
        data.push(obj);
      }
    }
    for (let j = 0; j < props.data.length; j++) {
      let index = data.findIndex(e => e.label === props.data[j].To);
      data[index].y++;
    }
    data.sort((a, b) => b.y - a.y);
    data.slice(0, 8).forEach(e => {
      setLabels(prev => [...prev, e.label]);
    });
    data.slice(0, 8).forEach(e => {
      setValues(prev => [...prev, e.y]);
    });
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 2000
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Most Converted To',
        font: {
          size: 24,
          family: "'Poppins'",
          weight: 'normal'
        }
      }
    }
  };
  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Valiations',
        data: values,
        backgroundColor: [
          rgbaColor(getColor('info'), 0.5),
          rgbaColor(getColor('danger'), 0.5)
        ]
      }
    ]
  };

  return (
    <Card style={{ height: '100%' }}>
      <Card.Body>
        <Bar options={options} data={data} />
      </Card.Body>
    </Card>
  );
};

BarChart.propTypes = {
  data: PropTypes.array
};

export default BarChart;
