import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';

const AdminUsageData = props => {
  const navigate = useNavigate();

  const [currentRequests, setCurrentRequests] = useState(
    props.data.CurrentRequests
  );
  const [maxRequests, setMaxRequests] = useState(props.data.MaxRequests);
  const [currentPlan, setCurrentPlan] = useState(props.data.CurrentPlan);
  const [products, setProducts] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [trialDate, setTrialDate] = useState(new Date(props.data.PaidUntil));

  useEffect(() => {
    axios
      .post('subscriptions/fetch-products', {
        currency: 'GBP',
        app: props.app,
        accountID: props.data.AccountID
      })
      .then(res => {
        let subscriptions = res.data.filter(
          e =>
            e.Type === 'subscription' ||
            e.Type === 'trial' ||
            e.Type === 'annual-subscription' ||
            e.AccountID == props.data.AccountID
        );
        setProducts(subscriptions);
      });
  }, []);

  const updateUsageData = () => {
    let account = document.getElementById('accountName').value;
    let range = document.getElementsByClassName(
      'react-datepicker__input-container'
    )[0].children[0].value;
    axios
      .post('/pmladmin/updateusagedata', {
        currentPlan: currentPlan,
        currentRequests: currentRequests,
        maxRequests: maxRequests,
        accountID: props.data.AccountID,
        app: props.app,
        isTrial: isTrial(),
        paidUntil: isTrial() ? trialDate : props.data.PaidUntil
      })
      .then(_ => {
        // navigate(`?account=${account.split(' (')[0]}&range=${range}`);
        navigate(`?account=${account}&range=${range}`);
      });
  };

  const isTrial = () => {
    let plan = products.filter(e => e.ProductID === parseInt(currentPlan))[0];
    if (plan === undefined) return false;
    return plan.Type === 'trial';
  };

  const isAgency = () => {
    let plan = products.filter(e => e.ProductID === parseInt(currentPlan))[0];
    if (plan === undefined) return false;
    return plan.Name === 'Partner';
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to update this accounts usage data? Changes
            made here will only last until{' '}
            {new Date(props.data.PaidUntil).toLocaleString('en-gb', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })}
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
          <Button variant="danger" onClick={() => updateUsageData()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Card style={{ height: 'fit-content' }}>
        <Card.Body>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: 12
            }}
          >
            <h2 style={{ fontWeight: 'normal', fontSize: 24 }}>Account</h2>
          </div>
          <div>
            <div style={{ display: 'flex' }}>
              <Form.Group className="mb-3" style={{ marginRight: 8 }}>
                <Form.Label>Current Requests</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={currentRequests}
                  onChange={e => setCurrentRequests(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Max Requests</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={maxRequests}
                  onChange={e => setMaxRequests(e.target.value)}
                />
              </Form.Group>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Current Plan</Form.Label>
              <Form.Select
                value={currentPlan}
                onChange={e => setCurrentPlan(e.currentTarget.value)}
              >
                <option value={null}>Null</option>
                {products.map(product => {
                  return (
                    <option value={product.ProductID} key={product.ProductID}>
                      {product.Name}
                      {product.Type === 'annual-subscription' && ' (Annual)'}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            {isTrial() && (
              <Form.Group className="mb-3">
                <Form.Label>Trial End Date</Form.Label>
                <ReactDatePicker
                  selected={trialDate}
                  onChange={setTrialDate}
                  formatWeekDay={day => day.slice(0, 3)}
                  dateFormat="dd MMM yyyy"
                  className="form-control"
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              {console.log(props.data)}
              <Form.Label>Paid Until</Form.Label>
              <Form.Control disabled type="text" value={props.data.PaidUntil} />
            </Form.Group>
            <Button
              variant="danger"
              style={{ width: '100%' }}
              onClick={() => setModalShow(true)}
            >
              Update
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

AdminUsageData.propTypes = {
  data: PropTypes.object
};

export default AdminUsageData;
