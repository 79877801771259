import 'chart.js/auto';
import axios from 'axios';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getColor, rgbaColor } from 'helpers/utils';
import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { Line, getElementAtEvent } from 'react-chartjs-2';
import Loading from 'components/common/Loading';
import { monthNames, textAppNames } from 'helpers/utils';

const formatData = async (transactions, exchangeRates) => {
  if (!transactions || !exchangeRates) {
    return;
  }

  const groupedByMonth = transactions.reduce((acc, obj) => {
    const month = obj.Timestamp.substring(0, 7); // Extract the YYYY-MM part
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(obj);
    return acc;
  }, {});

  let now = new Date();

  let date12MonthsAgo = new Date();
  date12MonthsAgo.setMonth(now.getMonth() - 12);
  if (now.getMonth() < date12MonthsAgo.getMonth()) {
    date12MonthsAgo.setFullYear(now.getFullYear() - 1);
  }

  const labels = [];
  const values = [];
  const monthlyTransactions = [];
  const tooltipLabels = [];

  while (date12MonthsAgo <= now) {
    let month = date12MonthsAgo.toISOString().substring(0, 7);

    let usdTooltip = 0;
    let eurTooltip = 0;
    let gbpTooltip = 0;
    let totalGBP = 0;
    let monthTransactions = [];

    if (groupedByMonth[month]) {
      totalGBP = groupedByMonth[month].reduce((acc, obj) => {
        if (obj.NetAmount <= 0) {
          return acc;
        }

        monthTransactions.push(obj);

        switch (obj.Currency) {
          case 'USD':
            usdTooltip += obj.NetAmount;
            return acc + obj.NetAmount / exchangeRates.GBPUSD;
          case 'EUR':
            eurTooltip += obj.NetAmount;
            return acc + obj.NetAmount / exchangeRates.GBPEUR;
          default:
            gbpTooltip += obj.NetAmount;
            return acc + obj.NetAmount;
        }
      }, 0);
      values.push(Math.round((totalGBP / 100) * 100) / 100);
      monthlyTransactions.push(monthTransactions);
    } else {
      values.push(0);
      monthlyTransactions.push([]);
    }

    const tooltip = [
      'Total GBP: £' + Math.round((totalGBP / 100) * 100) / 100,
      'GBP: £' + (gbpTooltip / 100).toFixed(2),
      'USD: $' + (usdTooltip / 100).toFixed(2),
      'EUR: €' + (eurTooltip / 100).toFixed(2)
    ];

    labels.push(monthNames[date12MonthsAgo.getMonth()]);
    tooltipLabels.push(tooltip);

    date12MonthsAgo.setMonth(date12MonthsAgo.getMonth() + 1);
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of New Trials',
        data: values,
        transactions: monthlyTransactions,
        tooltipLabels: tooltipLabels,
        backgroundColor: [rgbaColor(getColor('danger'), 0.7)]
      }
    ]
  };

  return data;
};

const MonthlyRevenueLineChart = props => {
  let { app, height } = props;

  if (!height) {
    height = '300px';
  }

  const [transactions, setTransactions] = useState();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    setTransactions(props.transactions);
  }, [props.transactions]);

  useEffect(() => {
    // if (!props.exchangeRates) {
    //   return;
    // }

    formatData(transactions, props.exchangeRates).then(d => setChartData(d));
  }, [transactions, props.exchangeRates]);

  const chartRef = useRef();
  const chartOnClick = event => {
    const col = getElementAtEvent(chartRef.current, event)[0];

    if (!col) {
      return;
    }

    const transactions = chartData.datasets[0].transactions[col.index];

    if (props.setModalTitle && props.setModalTransactions) {
      props.setModalTitle(`Transactions ${chartData.labels[col.index]}`);
      props.setModalTransactions(transactions);
    }
  };

  const options = {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 2000
    },
    scales: {
      y: {
        ticks: {
          display: true,
          font: {
            size: 12
          }
        }
      },
      x: {
        ticks: {
          display: true
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            return tooltipItem.dataset.tooltipLabels[tooltipItem.dataIndex];
          }
        }
      },
      legend: {
        display: false
      },
      title: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: 'bottom',
        color: '#000',
        backgroundColor: '#ffffff80',
        font: {
          size: 13,
          family: 'Poppins'
        },
        formatter: function (value, _) {
          if (value == 0) {
            return null;
          }
          return '£' + value;
        }
      }
    }
  };

  if (
    chartData == null ||
    chartData.length == 0 ||
    props.exchangeRates == null
  ) {
    return (
      <Card>
        <Card.Body style={{ paddingTop: 0, height: height }}>
          <div className="chart-header">
            <h2>{textAppNames[app]} Monthly Revenue</h2>
          </div>
          <Loading />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Body style={{ paddingTop: 0, height: height }}>
        <div className="chart-header">
          <h2>{textAppNames[app]} Monthly Revenue</h2>
        </div>
        <div style={{ height: 'calc(100% - 64px)' }}>
          <Line
            ref={chartRef}
            onClick={chartOnClick}
            options={options}
            data={chartData}
            plugins={[ChartDataLabels]}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default MonthlyRevenueLineChart;
