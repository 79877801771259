import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import PageTitle from 'components/common/PageTitle';
import NavbarTop from 'components/navbar/top/NavbarTop';
import loadingJSON from 'assets/animations/loading.json';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const redirect_uri = process.env.REACT_APP_BASE_URL + '/pmladmin/freeagent';
const client_id = 'i-ZcMVfu5AqfQAu6QTintA';

const AdminFreeagent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    onPageLoad();
  }, []);

  const onPageLoad = async () => {
    const isAdmin = await axios
      .post('/pmladmin/isadmin')
      .then(res => res.data)
      .catch(err => console.log(err));

    if (isAdmin != true) {
      return navigate('/page-not-found');
    }

    const code = searchParams.get('code');
    if (code) {
      await getAccessToken(code);
      setAuthorized(true);
      setLoading(false);
    } else {
      let { data: tokens } = await axios.post('/pmladmin/getfreeagenttokens');
      if (tokens == '') {
        setLoading(false);
      } else {
        setAuthorized(true);
        setLoading(false);
      }
    }
  };

  const authorizeFreeagent = async () => {
    window.location.href = `https://api.freeagent.com/v2/approve_app?scope=all&redirect_uri=${redirect_uri}&response_type=code&client_id=${client_id}&access_type=offline`;
  };

  const getAccessToken = async code => {
    await axios.post('/pmladmin/storefreeagenttokens', { code });
  };

  if (loading) {
    return (
      <div style={{ width: '100vw', height: '100%' }}>
        <Lottie
          animationData={loadingJSON}
          loop
          autoplay
          style={{
            width: '200px',
            height: '200px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </div>
    );
  }

  return (
    <>
      <NavbarTop simple />
      <PageTitle app="" page="Freeagent" />
      <Button
        variant="danger"
        onClick={authorizeFreeagent}
        disabled={authorized}
      >
        {authorized ? 'Authorized' : 'Authorize Freeagent'}
      </Button>
    </>
  );
};

export default AdminFreeagent;
