import PropTypes from 'prop-types';
const InstallUrls = props => {
  let urls = {};

  if (process.env.NODE_ENV == 'development') {
    urls = {
      Valid8:
        'https://app-eu1.hubspot.com/oauth/authorize?client_id=15a46ce8-2426-4035-bdf8-76e6b47955f1&redirect_uri=https://ev.dev.pacificmarketing.io/oauth-callback/valid8&scope=automation%20oauth%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.contacts.read%20crm.schemas.contacts.write',
      CurrencyR8:
        'https://app-eu1.hubspot.com/oauth/authorize?client_id=2b2d23c7-56f0-44d5-9024-ca234f4db669&redirect_uri=https://app.dev.pacificmarketinglabs.io/oauth-callback/currencyr8&scope=settings.currencies.read%20settings.currencies.write%20automation%20oauth%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.line_items.read%20crm.objects.line_items.write'
    };
  } else if (process.env.NODE_ENV == 'production') {
    urls = {
      Valid8:
        'https://app-eu1.hubspot.com/oauth/authorize?client_id=15a46ce8-2426-4035-bdf8-76e6b47955f1&redirect_uri=https://app.pacificmarketinglabs.io/oauth-callback/valid8&scope=automation%20oauth%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.contacts.read%20crm.schemas.contacts.write',
      CurrencyR8:
        'https://app-eu1.hubspot.com/oauth/authorize?client_id=9199c45e-8028-4e61-832e-48f52b80ee7f&redirect_uri=https://app.pacificmarketinglabs.io/oauth-callback/currencyr8&scope=settings.currencies.read%20settings.currencies.write%20oauth%20crm.objects.deals.read%20crm.objects.deals.write%20crm.objects.line_items.read%20crm.objects.line_items.write'
    };
  }

  location.href = urls[props.app];
  return null;
};

InstallUrls.propTypes = {
  app: PropTypes.string
};
export default InstallUrls;
