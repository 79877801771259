import loading from 'assets/animations/loading.json';
import axios from 'axios';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

let originalSettings = {};

const PingSettings = () => {
  const [settings, setSettings] = useState({});
  const [showWebhookModal, setShowWebhookModal] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    let res = await axios.get('/settings/ping');
    originalSettings = res.data;
    setSettings(res.data);
  };

  const saveSettings = async (instantSetting = null) => {
    if (instantSetting) {
      await axios.post('/settings/ping', instantSetting);
      fetchSettings();
    } else {
      await axios.post('/settings/ping', settings);
      fetchSettings();
    }
  };

  if (JSON.stringify(settings) === '{}')
    return (
      <Lottie
        animationData={loading}
        loop
        autoplay
        style={{ width: '200px', height: '200px', margin: 'auto' }}
      />
    );

  return (
    <>
      <Modal
        show={showWebhookModal}
        onHide={() => setShowWebhookModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to disable this feature? This will stop
          automatic email address validation of new contacts. Only disable this
          feature if you purely want to use Ping within workflows or manually
          from the contact record.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowWebhookModal(false)}>Cancel</Button>
          <Button
            variant="danger"
            onClick={() => {
              saveSettings({ ...settings, webhooks: false });
              setShowWebhookModal(false);
            }}
          >
            Yes I'm Sure
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="horizontalDiv">
        <h3>Ping Settings</h3>
        <Button
          onClick={() => saveSettings()}
          variant="danger"
          disabled={
            JSON.stringify(settings) === JSON.stringify(originalSettings)
          }
        >
          Save Changes
        </Button>
      </div>
      {/* <div>
        <Form.Check
          id="webhooks"
          label="Automatically validate a contact's email address when they are first created."
          onChange={e => {
            if (!e.target.checked) {
              setShowWebhookModal(true);
            } else {
              setSettings({ ...settings, webhooks: e.target.checked });
            }
          }}
          defaultChecked={settings.webhooks}
          checked={settings.webhooks}
        />
      </div> */}
      <Form.Check
        id="crmcard"
        label="Enable on-demand email address validation from Contact record card."
        onChange={e => setSettings({ ...settings, crmcard: e.target.checked })}
        defaultChecked={settings.crmcard}
        checked={settings.crmcard}
      />
    </>
  );
};

export default PingSettings;
