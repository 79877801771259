import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  FormCheck,
  OverlayTrigger,
  Tooltip,
  Card
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import IconButton from 'components/common/IconButton';
import { faLock } from '@fortawesome/free-solid-svg-icons';

let originalUpdateFrequency = null;
let originalEnabledStatus = null;
let originalUpdateTime = null;
let originalDisabledCurrencies = [];
let originalUpdateDay = null;
let originalRate = null;

const days = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednsday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
};

const dates = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
  5: '5th',
  6: '6th',
  7: '7th',
  8: '8th',
  9: '9th',
  10: '10th',
  11: '11th',
  12: '12th',
  13: '13th',
  14: '14th',
  15: '15th',
  16: '16th',
  17: '17th',
  18: '18th',
  19: '19th',
  20: '20th',
  21: '21st',
  22: '22nd',
  23: '23rd',
  24: '24th',
  25: '25th',
  26: '26th',
  27: '27th',
  28: '28th',
  29: '29th',
  30: '30th',
  31: '31st'
};

const Scheduler = props => {
  const currencies = props.currencies;
  const accountType = props.accountType;
  const schedulerData = props.schedulerData;

  const [updateFrequency, setUpdateFrequency] = useState(0); // 1 daily, 2 weekly, 3 monthly
  const [updateDay, setUpdateDay] = useState(0); // number of day of week or day of month
  const [rate, setRate] = useState(0); // 1 daily, 2, average of last 30 days, 3 average of month to date
  const [selectedTime, setSelectedTime] = useState();
  const [schedulerEnabled, setSchedulerEnabled] = useState(false);
  const [saveChangesDisabled, setSaveChangesDisabled] = useState(true);

  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    if (
      originalEnabledStatus !== schedulerEnabled ||
      originalUpdateTime !== selectedTime ||
      originalDisabledCurrencies !== props.disabledCurrencies ||
      originalUpdateFrequency !== updateFrequency ||
      originalUpdateDay !== updateDay ||
      originalRate !== rate
    ) {
      setSaveChangesDisabled(false);
    } else {
      setSaveChangesDisabled(true);
    }
  }, [
    selectedTime,
    schedulerEnabled,
    props.disabledCurrencies,
    updateFrequency,
    updateDay,
    rate
  ]);

  useEffect(() => {
    if (
      currencies.length - 1 - props.disabledCurrencies.length == 1 &&
      !schedulerEnabled
    ) {
      setSchedulerEnabled(true);
      props.setSchedulerEnabled(true);
    }
  }, [props.disabledCurrencies]);

  const onPageLoad = async () => {
    let schedule = await axios.post('/currencyr8/scheduler/getcurrentschedule');

    if (schedule.data.Hour === undefined) {
      originalUpdateTime = new Date().setHours(0, 0, 0, 0);
      setSelectedTime(new Date().setHours(0, 0, 0, 0));
      props.setSchedulerTime(new Date().setHours(0, 0, 0, 0));
    } else {
      originalUpdateTime = new Date().setHours(
        schedule.data.Hour,
        schedule.data.Minute,
        0,
        0
      );
      setSelectedTime(
        new Date().setHours(schedule.data.Hour, schedule.data.Minute, 0, 0)
      );
      props.setSchedulerTime(
        new Date().setHours(schedule.data.Hour, schedule.data.Minute, 0, 0)
      );

      props.setSchedulerData(schedule.data);

      originalDisabledCurrencies = JSON.parse(schedule.data.DisabledCurrencies);
      props.setDisabledCurrencies(originalDisabledCurrencies);
      originalEnabledStatus = schedule.data.Enabled;
      setSchedulerEnabled(schedule.data.Enabled);
      props.setSchedulerEnabled(schedule.data.Enabled);
      originalUpdateFrequency = schedule.data.UpdateFrequency;
      setUpdateFrequency(schedule.data.UpdateFrequency);
      originalUpdateDay = schedule.data.UpdateDay;
      setUpdateDay(schedule.data.UpdateDay);
      originalRate = schedule.data.RateCalculation;
      setRate(schedule.data.RateCalculation);
    }
  };

  const saveChanges = async () => {
    if (selectedTime === undefined) {
      return;
    }
    let time = new Date(selectedTime);
    await axios.post('/currencyr8/scheduler/updatescheduler', {
      enabled: schedulerEnabled,
      updateFrequency: updateFrequency,
      updateDay: updateDay,
      rateCalculation: rate,
      time: {
        hour: time.getHours(),
        minute: time.getMinutes()
      },
      disabledCurrencies: JSON.stringify(props.disabledCurrencies),
      companyHubspotID: props.companyHubspotID
    });
    window.location.reload();
  };

  if (accountType === null) {
    return <></>;
  }

  if (schedulerData === null) {
    return <></>;
  }

  return (
    <>
      <Card className="p-16 scheduler">
        {/* Your currencies will renew once a{' '}
        {schedulerData.UpdateFrequency == 1 && 'day'}
        {schedulerData.UpdateFrequency == 2 && 'week'}
        {schedulerData.UpdateFrequency == 3 && 'month'} at the selected UTC
        time. This will cost you{' '}
        {currencies.length - 1 - props.disabledCurrencies.length} credits per
        day. This will increase if you add more currencies to your HubSpot
        portal. */}

        {!schedulerData.Enabled &&
          'Scheduler is not currently enabled. please enable it using the settings below.'}
        {schedulerData.Enabled &&
          schedulerData.UpdateFrequency == 1 &&
          `Your currencies will renew once a day at ${schedulerData.Hour.toString().padStart(
            2,
            0
          )}:${schedulerData.Minute.toString().padStart(
            2,
            0
          )} UTC which will cost you ${
            currencies.length - 1 - props.disabledCurrencies.length
          } credits per day. This will increase if you add more currencies to your HubSpot portal.`}

        {schedulerData.Enabled &&
          schedulerData.UpdateFrequency == 2 &&
          `Your currencies will renew once a week every ${
            days[schedulerData.UpdateDay]
          } at ${schedulerData.Hour.toString().padStart(
            2,
            0
          )}:${schedulerData.Minute.toString().padStart(
            2,
            0
          )} UTC which will cost you ${
            currencies.length - 1 - props.disabledCurrencies.length
          } credits per week. This will increase if you add more currencies to your HubSpot portal.`}

        {schedulerData.Enabled &&
          schedulerData.UpdateFrequency == 3 &&
          `Your currencies will renew once a month on the ${
            dates[schedulerData.UpdateDay]
          } at ${schedulerData.Hour.toString().padStart(
            2,
            0
          )}:${schedulerData.Minute.toString().padStart(
            2,
            0
          )} UTC which will cost you ${
            currencies.length - 1 - props.disabledCurrencies.length
          } credits per month. This will increase if you add more currencies to your HubSpot portal.`}
        {/* Scheduler Toggle */}
        <Form.Group style={{ display: 'flex', paddingTop: 16 }}>
          <Form.Label>Scheduler Enabled: </Form.Label>
          {accountType === 'free' || accountType == 'starter' ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="overlay-trigger-example">
                  Upgrade to Currency-R8 Pro or Enterprise to use this feature.
                </Tooltip>
              }
            >
              <div style={{ width: 'fit-content' }}>
                <FormCheck
                  style={{ marginLeft: 16 }}
                  checked={
                    accountType === 'free' || accountType === 'starter'
                      ? false
                      : schedulerEnabled
                  }
                  onChange={e => {
                    setSchedulerEnabled(e.target.checked);
                  }}
                  disabled={accountType === 'free' || accountType == 'starter'}
                />
              </div>
            </OverlayTrigger>
          ) : (
            <FormCheck
              style={{ marginLeft: 16 }}
              checked={
                accountType === 'free' || accountType === 'starter'
                  ? false
                  : schedulerEnabled
              }
              onChange={e => {
                setSchedulerEnabled(e.target.checked);
                props.setSchedulerEnabled(e.target.checked);
              }}
              disabled={accountType === 'free' || accountType == 'starter'}
            />
          )}
        </Form.Group>
        {/* Time Select */}
        <Form.Group
          style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}
        >
          <Form.Label
            style={{
              margin: 'auto',
              marginLeft: 0,
              marginRight: 16,
              whiteSpace: 'nowrap'
            }}
          >
            Daily Update Time:{' '}
          </Form.Label>
          {accountType === 'free' || accountType == 'starter' ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="overlay-trigger-example">
                  Upgrade to Currency-R8 Pro or Enterprise to use this feature.
                </Tooltip>
              }
            >
              <div style={{ width: 'fit-content' }}>
                <DatePicker
                  selected={selectedTime}
                  onChange={date => setSelectedTime(date)}
                  className="form-control"
                  placeholderText="Select Time"
                  timeIntervals={15}
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  showTimeSelect
                  showTimeSelectOnly
                  disabled={accountType === 'free' || accountType == 'starter'}
                  style={{ width: 'fitContent' }}
                />
              </div>
            </OverlayTrigger>
          ) : (
            <DatePicker
              selected={selectedTime}
              onChange={date => setSelectedTime(date)}
              className="form-control"
              placeholderText="Select Time"
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              showTimeSelect
              showTimeSelectOnly
              disabled={accountType === 'free' || accountType == 'starter'}
              style={{ width: 'fitContent' }}
            />
          )}
          <Form.Label
            style={{
              margin: 'auto',
              marginLeft: 0,
              marginRight: 16,
              whiteSpace: 'nowrap'
            }}
          >
            (UTC Time)
          </Form.Label>
        </Form.Group>
        {/* Frequency Select */}
        <Form.Group
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 8,
            marginTop: 4
          }}
        >
          <Form.Label
            style={{
              margin: 'auto',
              marginLeft: 0,
              marginRight: 16,
              whiteSpace: 'nowrap'
            }}
          >
            Update Frequency:
          </Form.Label>
          <Form.Select
            style={{ maxWidth: 300 }}
            onChange={e => {
              setUpdateDay(1);
              setUpdateFrequency(parseInt(e.target.value));
            }}
            value={updateFrequency}
          >
            <option value="1">Daily</option>
            <option value="2">Weekly</option>
            <option value="3">Monthly</option>
          </Form.Select>
        </Form.Group>
        {/* Day Select */}
        {updateFrequency !== 1 && (
          <Form.Group
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 8,
              marginTop: 4
            }}
          >
            <Form.Label
              style={{
                margin: 'auto',
                marginLeft: 0,
                marginRight: 122,
                whiteSpace: 'nowrap'
              }}
            >
              Day:
            </Form.Label>
            <Form.Select
              style={{ maxWidth: 300 }}
              onChange={e => {
                setUpdateDay(parseInt(e.target.value));
              }}
              value={updateDay}
            >
              {updateFrequency === 2 && (
                <>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                  <option value="0">Sunday</option>
                </>
              )}
              {updateFrequency === 3 && (
                <>
                  <option value="1">1st</option>
                  <option value="2">2nd</option>
                  <option value="3">3rd</option>
                  <option value="4">4th</option>
                  <option value="5">5th</option>
                  <option value="6">6th</option>
                  <option value="7">7th</option>
                  <option value="8">8th</option>
                  <option value="9">9th</option>
                  <option value="10">10th</option>
                  <option value="11">11th</option>
                  <option value="12">12th</option>
                  <option value="13">13th</option>
                  <option value="14">14th</option>
                  <option value="15">15th</option>
                  <option value="16">16th</option>
                  <option value="17">17th</option>
                  <option value="18">18th</option>
                  <option value="19">19th</option>
                  <option value="20">20th</option>
                  <option value="21">21st</option>
                  <option value="22">22nd</option>
                  <option value="23">23rd</option>
                  <option value="24">24th</option>
                  <option value="25">25th</option>
                  <option value="26">26th</option>
                  <option value="27">27th</option>
                  <option value="28">28th</option>
                  <option value="29">29th</option>
                  <option value="30">30th</option>
                  <option value="31">31st</option>
                </>
              )}
            </Form.Select>
          </Form.Group>
        )}
        {/* Rate Select */}
        <Form.Group
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 12,
            marginTop: 4
          }}
        >
          <Form.Label
            style={{
              margin: 'auto',
              marginLeft: 0,
              marginRight: 68,
              whiteSpace: 'nowrap'
            }}
          >
            Rate to Use:
          </Form.Label>
          <Form.Select
            style={{ maxWidth: 300 }}
            onChange={e => {
              setRate(parseInt(e.target.value));
            }}
            value={rate}
          >
            <option value="1">Latest Rate</option>
            <option value="2">Average of Last 30 Days</option>
            <option value="3">Average of Month to Date</option>
          </Form.Select>
        </Form.Group>
        {/* //Disable save button if account is free or starter */}
        {accountType === 'free' || accountType == 'starter' ? (
          <OverlayTrigger
            overlay={
              <Tooltip id="overlay-trigger-example">
                Upgrade to Currency-R8 Pro or Enterprise to use this feature.
              </Tooltip>
            }
          >
            <div style={{ width: 'fit-content' }}>
              <IconButton
                variant="danger"
                style={{ marginRight: 16 }}
                disabled={true}
                icon={faLock}
              >
                Save Changes
              </IconButton>
            </div>
          </OverlayTrigger>
        ) : (
          <Button
            variant="danger"
            onClick={saveChanges}
            style={{ maxWidth: 454 }}
            disabled={saveChangesDisabled}
          >
            Save Changes
          </Button>
        )}
      </Card>

      {/* <div style={{ paddingTop: 32, display: 'flex' }}>

        {accountType === 'free' ? (
          <OverlayTrigger
            overlay={
              <Tooltip id="overlay-trigger-example">
                Upgrade to a paid Currency-R8 plan to use this feature.
              </Tooltip>
            }
          >
            <div style={{ width: 'fit-content' }}>
              <IconButton
                variant="danger"
                style={{ marginRight: 16 }}
                disabled={true}
                icon={faLock}
              >
                Update All Currencies Now
              </IconButton>
            </div>
          </OverlayTrigger>
        ) : (
          <Button
            variant="danger"
            onClick={props.showModal}
            style={{ marginRight: 24 }}
          >
            Update All Currencies Now
          </Button>
        )}
      </div> */}
    </>
  );
};

export default Scheduler;
