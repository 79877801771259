import React from 'react';
import Lottie from 'lottie-react';
import loading from 'assets/animations/loading.json';

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'auto',
        justifyContent: 'center'
      }}
    >
      <Lottie
        animationData={loading}
        loop
        autoplay
        style={{ width: '200px', height: '200px', margin: 'auto' }}
      />
    </div>
  );
};

export default Loading;
