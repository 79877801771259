import React, { useState, useEffect } from 'react';
import { Alert, Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import loading from 'assets/animations/loading.json';
import axios from 'axios';
import { textAppNames } from 'helpers/utils';

const ReactivateSubAlert = props => {
  const [hidden, setHidden] = useState(true);
  const [showModal, setShowModal] = useState();

  const remainingMS = new Date(new Date(props.account.PaidUntil) - new Date());

  const remainingDays = Math.floor(remainingMS / 8.64e7);

  useEffect(() => {
    if (props.account.SubscriptionStatus == 'cancelled') {
      setHidden(false);
    }
  }, [props.account]);

  return (
    <>
      <ReactivateSubModal
        showModal={showModal}
        setShowModal={setShowModal}
        app={props.app}
        account={props.account}
      />
      <Alert
        variant="warning"
        onClose={() => setHidden(true)}
        dismissible
        hidden={hidden}
      >
        <div className="horizontalDiv">
          <>
            You have {remainingDays} days to reactivate your {props.app} plan
          </>
          <a
            className="reactivateSubButton text-danger"
            onClick={() => setShowModal(true)}
          >
            Reactivate Plan
          </a>
        </div>
      </Alert>
    </>
  );
};

const ReactivateSubModal = props => {
  const [modalJSX, setModalJSX] = useState();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setModalJSX(text);
  }, []);

  const formatPrice = price => {
    if (price == null) {
      return;
    }
    price = price.toString();
    if (price.slice(-2) == '00') {
      return price.slice(0, -2);
    } else if (price == '0') {
      return price;
    } else {
      return price.slice(0, -2) + '.' + price.slice(-2);
    }
  };

  const reactivateSubscription = () => {
    setModalJSX(loadingAnimation);
    axios
      .post('/subscriptions/reactivate-subscription', {
        app: props.app
      })
      .then(res => {
        setModalJSX(<p>{res.data}</p>);
        setCompleted(true);
      })
      .catch(err =>
        setModalJSX(<p>Something went wrong. Please try again later.</p>)
      );
  };

  var dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  const text = (
    <>
      <h4>Are you sure you want to reactivate your plan?</h4>
      <p>
        Your {textAppNames[props.app]} plan will renew on{' '}
        {new Date(props.account.PaidUntil).toLocaleDateString(
          'en-gb',
          dateOptions
        )}
      </p>
    </>
  );

  const loadingAnimation = (
    <Lottie
      animationData={loading}
      loop
      autoplay
      style={{ width: '200px', height: '200px', margin: 'auto' }}
    />
  );

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={() => props.setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reactivate Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalJSX}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => props.setShowModal(false)}
            hidden={completed}
          >
            Go Back
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              reactivateSubscription();
            }}
            hidden={completed}
          >
            Reactivate Plan
          </Button>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            hidden={!completed}
            variant="danger"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ReactivateSubAlert.propTypes = {
  app: PropTypes.string,
  account: PropTypes.object
};

ReactivateSubModal.propTypes = {
  app: PropTypes.string,
  account: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func
};

export default ReactivateSubAlert;
