import React, { useState, useEffect, useContext } from 'react';
import AppContext from 'context/Context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Alert,
  Modal,
  InputGroup
} from 'react-bootstrap';
import axios from 'axios';
import OTPCheck from 'components/OTP/OTPCheck';
import { Document, Page } from 'react-pdf';
import AnimateCC from 'react-adobe-animate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Signup = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // * Setup States for all input fields and their validity
  const [firstName, setFirstName] = useState('');
  const [firstNameisInvalid, setFirstNameIsInvalid] = useState(null);
  const [lastName, setLastName] = useState('');
  const [lastNameIsInvalid, setLastNameIsInvalid] = useState(null);
  const [email, setEmail] = useState('');
  const [emailIsInvalid, setEmailIsInvalid] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] =
    useState(null);
  const [showTCs, setShowTCs] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsInvalid, setTermsInvalid] = useState(false);

  // * Other States
  const [loading, setLoading] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [signupStep, setSignupStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);

  //  * check for string@string.string
  //  * this is enough as we will verify email later
  const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
  //  * Checks for 1 letter, 1 number, 12 characters and a special char
  const passwordRegex = new RegExp(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/
  );

  // * Store UTM params in local storage
  useEffect(() => {
    storeUTMParams();
  }, []);

  //  * Give immedidate feedback on password and email fields
  useEffect(() => {
    if (email !== '' && !emailRegex.test(email)) {
      setEmailIsInvalid(true);
      emailIsInvalid;
    } else if (email === '') {
      setEmailIsInvalid(null);
    } else {
      setEmailIsInvalid(false);
    }

    if (password !== '' && !passwordRegex.test(password)) {
      setPasswordIsInvalid(true);
    } else if (password == '') {
      setPasswordIsInvalid(null);
    } else {
      setPasswordIsInvalid(false);
    }

    if (confirmPassword !== '' && confirmPassword !== password) {
      setConfirmPasswordIsInvalid(true);
    } else if (confirmPassword == '') {
      setConfirmPasswordIsInvalid(null);
    } else {
      setConfirmPasswordIsInvalid(false);
    }
  }, [email, password, confirmPassword]);

  const checkFields = () => {
    let fieldsAreValid = true;
    if (firstName == '') {
      fieldsAreValid = false;
      setFirstNameIsInvalid(true);
    } else {
      setFirstNameIsInvalid(false);
    }
    if (lastName == '') {
      fieldsAreValid = false;
      setLastNameIsInvalid(true);
    } else {
      setLastNameIsInvalid(false);
    }
    if (email == '' || !emailRegex.test(email)) {
      fieldsAreValid = false;
      setEmailIsInvalid(true);
    } else {
      setEmailIsInvalid(false);
    }
    if (password == '' || !passwordRegex.test(password)) {
      fieldsAreValid = false;
      setPasswordIsInvalid(true);
    } else {
      setPasswordIsInvalid(false);
    }
    if (confirmPassword == '' || confirmPassword !== password) {
      fieldsAreValid = false;
      setConfirmPasswordIsInvalid(true);
    } else {
      setConfirmPasswordIsInvalid(false);
    }
    if (termsChecked == false) {
      fieldsAreValid = false;
      setTermsInvalid(true);
    } else {
      setTermsInvalid(false);
    }
    return fieldsAreValid;
  };

  const onSubmit = async () => {
    setEmailTaken(false);

    if (checkFields() == true) {
      setLoading(true);
      let data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        utms: JSON.parse(localStorage.getItem('utms'))
      };

      let result = await axios
        .post('/create-account', data)
        .then(res => {
          return res.data;
        })
        .catch(err => console.log(err.data));

      setLoading(false);
      if (result === 'taken') {
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setEmailTaken(true);
      } else {
        localStorage.setItem('PMLAdmin', 'null');
        localStorage.setItem('fname', data.firstName);
        localStorage.setItem('lname', data.lastName);
        axios
          .post('/auth/set-user-token', { email: email, password: password })
          .then(() => navigate('/portals'));
      }
    }
  };

  const storeUTMParams = () => {
    let utms;
    const existingUtms = JSON.parse(localStorage.getItem('utms'));

    if (existingUtms) {
      if (
        searchParams.get('utm_source') == null &&
        searchParams.get('utm_medium') == null &&
        searchParams.get('utm_campaign') == null &&
        searchParams.get('utm_term') == null &&
        searchParams.get('utm_content') == null
      ) {
        return;
      } else {
        utms = {
          source: searchParams.get('utm_source'),
          medium: searchParams.get('utm_medium'),
          campaign: searchParams.get('utm_campaign'),
          term: searchParams.get('utm_term'),
          content: searchParams.get('utm_content')
        };
        localStorage.setItem('utms', JSON.stringify(utms));
      }
    } else {
      utms = {
        source: searchParams.get('utm_source'),
        medium: searchParams.get('utm_medium'),
        campaign: searchParams.get('utm_campaign'),
        term: searchParams.get('utm_term'),
        content: searchParams.get('utm_content')
      };
      localStorage.setItem('utms', JSON.stringify(utms));
    }
    setSearchParams({});
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderPages = () => {
    let pages = [];
    for (let i = 1; i < numPages + 1; i++) {
      pages.push(<Page pageNumber={i} scale={1.4} />);
    }
    return pages;
  };

  if (signupStep == 1) {
    return (
      <>
        <Modal
          show={showTCs}
          onHide={() => setShowTCs(false)}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-fitcontent"
        >
          <Modal.Header>
            <Modal.Title>Terms And Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Document
              file="https://www.pacificmarketinglabs.io/Terms-Conditions-Pacific-Marketing-Labs-Jan-23.pdf"
              onLoadSuccess={onDocumentLoadSuccess}
              className="signup-pdf-document"
            >
              {renderPages()}
            </Document>
            Accept the terms and conditions to continue.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowTCs(false)}>
              Reject
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setLoading(true);
                setShowTCs(false);
                onSubmit();
              }}
            >
              Accept
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="centerDiv">
          <div className="centerDivLogo" style={{ width: 150, height: 150 }}>
            {isDark ? (
              <AnimateCC animationName="LabsLogoWhite" paused={false} />
            ) : (
              <AnimateCC animationName="LabsLogoBlue" paused={false} />
            )}
          </div>

          <Card className="p-32" style={{ maxWidth: 600 }}>
            <Card.Body>
              <h3>Create your account</h3>
              {emailTaken ? (
                <Alert
                  variant="danger"
                  onClose={() => setEmailTaken(false)}
                  dismissible
                >
                  <p className="m-0">Email address already taken.</p>
                </Alert>
              ) : (
                ''
              )}
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      isInvalid={firstNameisInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      First Name cannot be empty.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      isInvalid={lastNameIsInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Last Name cannot be empty.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  isInvalid={emailIsInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  Invalid email address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    isInvalid={passwordIsInvalid}
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? faEyeSlash : faEye}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: passwordIsInvalid ? 'block' : 'none' }}
                >
                  Password must contain at least 1 uppercase, 1 lowercase, 1
                  number, 1 special character(@$!%*#?&) and be 12 characters
                  long.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    isInvalid={confirmPasswordIsInvalid}
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: confirmPasswordIsInvalid ? 'block' : 'none'
                  }}
                >
                  Passwords must match.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox" id="defaultCheckbox">
                  <Form.Check.Input
                    type="checkbox"
                    checked={termsChecked}
                    onClick={() => {
                      setTermsChecked(!termsChecked);
                    }}
                    isInvalid={termsInvalid}
                  />
                  <Form.Check.Label>
                    I agree to Pacific Marketing Labs'{' '}
                    <a
                      href="https://www.pacificmarketinglabs.io/Terms-Conditions-Pacific-Marketing-Labs-Jan-23.pdf"
                      style={{ textDecoration: 'underline' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms & Conditions
                    </a>{' '}
                    of use.
                  </Form.Check.Label>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginLeft: '-2em', marginTop: '-6px' }}
                  >
                    Please read and accept terms and conditions
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
              <Button
                onClick={() => onSubmit()}
                disabled={loading}
                variant="danger"
                className="btn-block"
              >
                {loading ? 'Loading' : 'Create Account'}
              </Button>

              <hr />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <p style={{ margin: 0, paddingRight: 8 }}>
                  Already have an account?
                </p>
                <a
                  className="nav-link"
                  style={{ cursor: 'pointer', padding: 0 }}
                  onClick={() => navigate('/login')}
                >
                  Login
                </a>
              </div>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  } else if (signupStep == 2) {
    return <OTPCheck email={email} />;
  }
};

export default Signup;
