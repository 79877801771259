import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import LogsTable from './LogsTable';
import BarChart from './BarChart';
import UsageLineChart from './UsageLineChart';
import UsageData from '../common/UsageData';
import AdminUsageData from '../common/AdminUsageData';
import AdminBetas from '../common/AdminBetas';
import AdminCurrencyR8Settings from './AdminCurrencyR8Settings';

const CurrencyR8Dashboard = props => {
  return (
    <>
      <Row>
        <Col lg={3} style={{ paddingBottom: 32 }}>
          <BarChart data={props.data} />
        </Col>
        <Col lg={6} style={{ paddingBottom: 32 }}>
          <UsageLineChart data={props.data} range={props.range} />
        </Col>
        <Col lg={3} style={{ paddingBottom: 32 }}>
          {props.admin ? (
            <AdminUsageData data={props.account} app={props.app} />
          ) : (
            <UsageData
              data={props.account}
              allocation={props.account.MaxRequests}
              text={'Extra credits used'}
            />
          )}
        </Col>
      </Row>
      {props.admin && (
        <Row>
          <Col lg={3} style={{ paddingBottom: 32 }}>
            <AdminBetas app={props.app} data={props.account} />
          </Col>
          <Col lg={3} style={{ paddingBottom: 32 }}>
            <AdminCurrencyR8Settings data={props.account} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <LogsTable data={props.data} />
        </Col>
      </Row>
    </>
  );
};

export default CurrencyR8Dashboard;
