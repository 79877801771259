import React, { useState, useEffect } from 'react';
import { Button, Modal, Alert, Form, Table } from 'react-bootstrap';
import axios from 'axios';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import loading from 'assets/animations/loading.json';

const UsersSettings = props => {
  const [users, setUsers] = useState([]);
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [userToRemove, setUserToRemove] = useState('');
  const [removeModalText, setRemoveModalText] = useState();

  useEffect(() => {
    if (props.active) {
      fetchUsers();
    }
  }, [props.active]);

  const fetchUsers = () => {
    axios.post('/settings/getusers').then(res => {
      setUsers(res.data);
    });
  };

  const addNewUser = async () => {
    setModalLoading(true);
    setShowAlert(false);
    setEmail('');
    let res = await axios.post('/settings/adduser', { email: email });
    setAlertText(res.data);
    if (res.data == 'User added successfully') {
      setAlertVariant('success');
    } else {
      setAlertVariant('danger');
    }
    setShowAlert(true);
    fetchUsers();
    setModalLoading(false);
  };

  const removeUser = async () => {
    setModalLoading(true);
    let res = await axios.post('/settings/removeuser', {
      id: userToRemove.UserID
    });
    setRemoveModalText(res.data);
    fetchUsers();
    setModalLoading(false);
  };

  const toggleUserAdmin = async user => {
    await axios.post('/settings/toggleuseradmin', {
      email: user.Email
    });
    fetchUsers();
  };

  if (users.length == 0) {
    return (
      <Lottie
        animationData={loading}
        loop
        autoplay
        style={{ width: '200px', height: '200px', margin: 'auto' }}
      />
    );
  }

  return (
    <>
      {/* //* Add new user moda/ */}
      <Modal
        show={addNewUserModal}
        onHide={() => {
          setAddNewUserModal(false);
          setShowAlert(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={alertVariant} hidden={!showAlert}>
            {alertText}
          </Alert>
          <div hidden={modalLoading}>
            <p>If the user exists, they will be added to your portal.</p>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>
          </div>
          <Lottie
            animationData={loading}
            loop
            autoplay
            style={{ width: '100px', height: '100px', margin: 'auto' }}
            hidden={!modalLoading}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setAddNewUserModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => addNewUser()}>
            Add User
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //*Delete user modal */}
      <Modal
        show={removeUserModal}
        onHide={() => {
          setRemoveUserModal(false);
          setRemoveModalText(
            <p>
              Are you sure you want to remove <b>{userToRemove.Email}?</b>
            </p>
          );
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div hidden={modalLoading}>{removeModalText}</div>
          <Lottie
            animationData={loading}
            loop
            autoplay
            style={{ width: '100px', height: '100px', margin: 'auto' }}
            hidden={!modalLoading}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setRemoveUserModal(false)}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={e => {
              removeUser();
              e.target.hidden = true;
            }}
          >
            Remove User
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="horizontalDiv">
        <h3>Manage Users</h3>
        <Button onClick={() => setAddNewUserModal(true)} variant="danger">
          Add New User
        </Button>
      </div>
      <p style={{ marginTop: 16 }}>
        All New users will have access to the Dashboard only. Admin users will
        have access to the Billing, Plans and Account Setting sections in
        addition to the Dashboard.
      </p>
      <hr />
      <Table responsive hover>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th className="text-end" scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, idx) => {
            return (
              <tr key={idx}>
                <td>
                  {user.FirstName} {user.LastName}
                </td>
                <td>{user.Email}</td>
                <td className="text-end">
                  {user.Email !== localStorage.getItem('email') && (
                    <>
                      <Button
                        variant="danger"
                        onClick={() => toggleUserAdmin(user)}
                        style={{ marginRight: 8 }}
                      >
                        {user.isAdmin ? 'Remove' : 'Set'} as Admin
                      </Button>

                      <Button
                        variant="primary"
                        onClick={() => {
                          setRemoveUserModal(true);
                          setUserToRemove(user);
                          setRemoveModalText(
                            <p>
                              Are you sure you want to remove{' '}
                              <b>{user.Email}?</b>
                            </p>
                          );
                        }}
                      >
                        Remove
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

UsersSettings.propTypes = {
  active: PropTypes.bool
};

export default UsersSettings;
