import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PlatformHeader from './PlatformHeader';

const PageTitle = props => {
  const [app, setApp] = useState('');

  useEffect(() => {
    switch (props.app) {
      case 'CurrencyR8':
        setApp('Currency-R8');
        break;
      case 'ActivityM8':
        setApp('Activity-M8');
        break;
      case 'QRGener8':
        setApp('QR-Gener8');
        break;
      case 'BadgelyM8':
        setApp('Badgely-M8');
        break;
      default:
        setApp(props.app);
    }
  }, [props.app]);

  return (
    <div style={{ display: 'block', ...props.style }}>
      <PlatformHeader />
      <div className="horizontalDiv" style={{ ...props.style }}>
        <h1 className={'boldHeader pb-32'} style={{ ...props.style }}>
          <span className="highlightedHeader">{app}</span>{' '}
          {props.page ? props.page : ''}
        </h1>
      </div>
    </div>
  );
};

PageTitle.propTypes = {
  app: PropTypes.string.isRequired,
  page: PropTypes.string,
  style: PropTypes.object
};

export default PageTitle;
