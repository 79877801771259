import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import axios from 'axios';
import Loading from 'components/common/Loading';
import PageTitle from 'components/common/PageTitle';
import { useBreakpoints } from 'hooks/useBreakpoints';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

const CheckoutBody = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentError, setPaymentError] = useState(null);

  const newSubscriptionOnClick = async () => {
    // axios.post('/subscriptions/setcheckoutcomplete', {
    //   app: props.App
    // });
    setPaymentError(null);
    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: `${location.origin}/payment-complete?app=${props.App}&tier=${props.subscription.Name}`
        }
      })
      .then(result => {
        if (result.error) {
          setPaymentError(result.error.message);
        }
      });
  };

  const upgradeSubscriptionOnClick = async () => {
    // axios.post('/subscriptions/setcheckoutcomplete', {
    //   app: props.App
    // });
    stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${location.origin}/upgrade-complete?app=${props.App}&subscriptionID=${props.account.SubscriptionID}&priceID=${props.subscription.StripeID}&unitPriceID=${props.subscription.UnitStripeID}&prorate=${props.prorate}&addons=${props.addons}&tier=${props.subscription.Name}`
      }
    });
  };

  const purchaseAddons = async () => {
    // axios.post('/subscriptions/setcheckoutcomplete', {
    //   app: props.App
    // });

    let addonIDs = [];
    for (let i = 0; i < props.addons.length; i++) {
      addonIDs.push(props.addons[i].ProductID);
    }

    stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:
          `${location.origin}/payment-complete?app=${props.addons[0].App}&addons=` +
          addonIDs.toString()
      }
    });
  };

  return (
    <>
      <Card.Body style={{ paddingTop: 0 }}>
        {paymentError !== null && (
          <Alert variant="danger">{paymentError}</Alert>
        )}
        <PaymentElement />
      </Card.Body>
      <Card.Footer>
        {/* <Button
          variant="secondary"
          onClick={() => props.cancelPurchase}
          style={{ marginRight: 8 }}
        >
          Cancel Payment
        </Button> */}
        {props.subscriptionType === 'new' && (
          <Button
            variant="danger"
            className="btn-block"
            onClick={() => newSubscriptionOnClick()}
          >
            Purchase
          </Button>
        )}
        {props.subscriptionType === 'upgrade' && (
          <Button
            variant="danger"
            className="btn-block"
            onClick={() => upgradeSubscriptionOnClick()}
          >
            Upgrade Subscription
          </Button>
        )}
        {props.addonsOnly && (
          <Button
            variant="danger"
            className="btn-block"
            onClick={() => purchaseAddons()}
          >
            Purchase Add-on
          </Button>
        )}
      </Card.Footer>
    </>
  );
};

const CheckoutView = props => {
  const navigate = useNavigate();
  const breakpoints = useBreakpoints();

  const [clientSecret, setClientSecret] = useState(null);
  const [invoiceID, setInvoiceID] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [addonsOnly, setAddonsOnly] = useState(false);
  const [card, setCard] = useState(null);
  const [prorate, setProtate] = useState(false);
  const [newSubscriptionID, setNewSubscriptionID] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState(false);

  const setHubSpotPipelinePending = async subType => {
    await axios.post('/subscriptions/setcheckoutpending', {
      subType: subType,
      properties: {
        amount: calculateTotal(),
        description: JSON.stringify({
          addons: props.addons,
          subscription: props.subscription
        }),
        deal_currency_code: localStorage.getItem('currencyName')
      }
    });
    if (
      props.subscription.Price === 0 &&
      subType !== 'downgrade' &&
      subType !== 'partner'
    ) {
      setTimeout(() => {
        axios.post('/subscriptions/setcheckoutcomplete', {
          app: props.subscription.App
        });
      }, 10000);
    }
  };

  const getClientSecret = async () => {
    let {
      subscriptionID: subID,
      clientSecret: clientSecret,
      invoiceID: invoiceID,
      endDate: endDate
    } = await axios
      .post('/subscriptions/create-subscription', {
        subscription: props.subscription,
        addons: props.addons
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log(err);
      });
    setClientSecret(clientSecret);
    setInvoiceID(invoiceID);
    setNewSubscriptionID(subID);
    setEndDate(endDate);
  };

  const getAddonsClientSecret = async () => {
    let { data } = await axios.post('/addons/checkout-session', {
      addons: props.addons,
      url: `${location.origin}/payment-complete?addons=${JSON.stringify(
        props.addons.map(a => a.ProductID)
      )}`
    });

    if (data.error) {
      return setError(true);
    }

    setInvoiceID(data.invoiceId);
    setClientSecret(data.clientSecret);
  };

  const setupCard = async () => {
    let { clientSecret: clientSecret } = await axios
      .post('/stripe/setup-intent')
      .then(res => {
        return res.data;
      });
    setClientSecret(clientSecret);
  };

  const getUpgradeInvoice = async prorate => {
    let res = await axios
      .post('/stripe/get-upgrade-invoice', {
        subscriptionID: props.account.SubscriptionID,
        priceID: props.subscription.StripeID,
        unitPriceID: props.subscription.UnitStripeID,
        prorate: prorate,
        addons: props.addons
      })
      .then(res => {
        return res.data;
      });

    if (res.card === null) {
      setupCard();
    }
    setCard(res.card);
    setUpcomingInvoice(res.invoice);
  };

  const getPaymentMethod = async () => {
    let res = await axios.post('/stripe/get-payment-method');
    setCard(res.data.card);
  };

  const activateTrial = async () => {
    let { data } = await axios.post('/subscriptions/activate-trial', {
      trial: props.subscription,
      account: props.account
    });
    navigate(
      `/payment-complete?purchase=success&app=${props.subscription.App}&tier=${props.subscription.Name}`
    );
  };

  const containsAdditionalCredits = () => {
    let credits = false;
    for (const a of props.addons) {
      if (a.Type == 'additional-credit') {
        credits = true;
        break;
      }
    }
    return credits;
  };

  useEffect(() => {
    // prettier-ignore
    const checkSubscription = async () => {
      if (props.account.CurrentPlan == null && props.subscription?.Type == 'trial') {
        activateTrial();
      } else if (Object.keys(props.account).length == 0 || props.account.CurrentPlan == null || props.account.TrialStatus == 'current') {
        if (JSON.stringify(props.subscription) !== '{}' && props.subscription.Name === 'Partner') {
          setSubscriptionType('partner');
          axios.post('/stripe/setup-intent').then(res => {
            setClientSecret(res.data.clientSecret);
          });
          setHubSpotPipelinePending('new');
        } else if (JSON.stringify(props.subscription) !== '{}') {
          setSubscriptionType('new');
          getClientSecret();
          setHubSpotPipelinePending('new');
        }
      } else if (props.subscription?.Price > props.account.Price ||(props.subscription?.Price == props.account.Price && props.subscription?.Price > 0)) {
        setSubscriptionType('upgrade');
        setProtate(true);
        setHubSpotPipelinePending('upgrade');
        await getUpgradeInvoice(true);
      } else if (props.subscription?.Price < props.account.Price) {
        setSubscriptionType('downgrade');
        setHubSpotPipelinePending('downgrade');
      } else if (
        Object.keys(props.subscription).length == 0 &&
        props.addons.length > 0
      ) {
        setHubSpotPipelinePending('addons');
        setAddonsOnly(true);
        getAddonsClientSecret();
      }
    };
    checkSubscription();
  }, []);

  const cancelPurchase = () => {
    axios.post('/subscriptions/cancel-subscription-purchase', {
      invoiceID: invoiceID
    });
    setClientSecret(null);
    setInvoiceID(null);
    history.back();
  };

  const formatPrice = price => {
    price = price.toString();
    if (price.slice(-2) == '00') {
      return price.slice(0, -2);
    } else if (price == '0') {
      return price;
    } else {
      return price.slice(0, -2) + '.' + price.slice(-2);
    }
  };

  const calculateTotal = () => {
    let total =
      props.subscription.Price !== undefined ? props.subscription.Price : 0;
    for (let i = 0; i < props.addons.length; i++) {
      total = total + props.addons[i].Price;
    }
    if (localStorage.getItem('country') === 'GB') {
      total = total + calculateVAT();
    }
    total = formatPrice(total);
    return total;
  };

  const calculateVAT = () => {
    let totalVat = 0;
    if (props.subscription.Price !== undefined) {
      totalVat = totalVat + Math.round(props.subscription.Price * 0.2);
    }
    for (let i = 0; i < props.addons.length; i++) {
      totalVat = totalVat + Math.round(props.addons[i].Price * 0.2);
    }
    return totalVat;
  };

  const upgradeSubscriptionOnClick = async e => {
    e.preventDefault();
    // axios.post('/subscriptions/setcheckoutcomplete', {
    //   app:
    //     props.subscription.App !== undefined
    //       ? props.subscription.App
    //       : props.addons[0].App,
    //   subscriptionType: subscriptionType
    // });
    await axios
      .post('/stripe/upgrade-subscription', {
        subscriptionID: props.account.SubscriptionID,
        priceID: props.subscription.StripeID,
        unitPriceID: props.subscription.UnitStripeID,
        addons: props.addons,
        prorate: subscriptionType === 'upgrade' ? true : false,
        app:
          props.subscription.App !== undefined
            ? props.subscription.App
            : props.addons[0].App
      })
      .then(res => {
        navigate(
          `/upgrade-complete?tier=${props.subscription.Name}&app=` +
            (props.subscription.App !== undefined
              ? props.subscription.App
              : props.addons[0].App)
        );
      });
  };

  var dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  if (clientSecret == 'success') {
    navigate(
      `/payment-complete?purchase=success&app=${props.subscription.App}&tier=${props.subscription.Name}`
    );
  }

  if (clientSecret !== null || subscriptionType !== null || addonsOnly) {
    return (
      <>
        {error && (
          <Modal show>
            <Modal.Header>
              <Modal.Title>Something went wrong</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {typeof error !== 'boolean' ? error : 'Please try again later.'}
              </p>
              <Button
                variant="danger"
                onClick={() => {
                  navigate(0);
                }}
              >
                Back
              </Button>
            </Modal.Body>
          </Modal>
        )}
        <div className="horizontalDiv">
          <PageTitle
            app={
              props.subscription.App !== undefined
                ? props.subscription.App
                : props.addons[0].App
            }
            page={'Checkout'}
          />
        </div>
        <Row>
          <Col lg={6}>
            <Card className="p-32" style={{ height: '100%' }}>
              <Card.Header>
                <h2>Summary</h2>
              </Card.Header>
              <Card.Body style={{ paddingTop: 0 }}>
                <Table borderless responsive>
                  <thead>
                    <tr>
                      <th scope="col" style={{ paddingTop: 0 }}>
                        Item
                      </th>
                      <th scope="col" style={{ paddingTop: 0 }}>
                        Subtotal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* //* Show summary for new subscriptions */}
                    {(subscriptionType == 'new' ||
                      subscriptionType == 'downgrade' ||
                      subscriptionType == 'partner') &&
                      Object.keys(props.subscription).length !== 0 && (
                        <tr>
                          <td>
                            {props.subscription.App} - {props.subscription.Name}
                          </td>
                          <td>
                            {localStorage.getItem('currencySign')}
                            {formatPrice(props.subscription.Price)}
                            {props.subscription.Type === 'subscription'
                              ? '/month'
                              : '/year'}
                          </td>
                        </tr>
                      )}
                    {(subscriptionType == 'new' ||
                      subscriptionType == 'downgrade' ||
                      subscriptionType == 'partner' ||
                      addonsOnly) &&
                      props.addons.map((addon, idx) => {
                        return (
                          <>
                            <tr>
                              <td>{addon.Name}</td>
                              <td>
                                {localStorage.getItem('currencySign')}
                                {formatPrice(addon.Price)}
                              </td>
                            </tr>
                            {addon.Type == 'additional-credit' &&
                              clientSecret && (
                                <tr style={{ marginTop: -24 }}>
                                  <td colSpan={2} className="text-red">
                                    Any additional credits will expire on{' '}
                                    {new Date(
                                      endDate
                                        ? endDate * 1000
                                        : props.account.PaidUntil
                                    ).toLocaleDateString('en-gb', dateOptions)}
                                  </td>
                                </tr>
                              )}
                          </>
                        );
                      })}
                    {(subscriptionType == 'new' ||
                      subscriptionType == 'downgrade' ||
                      subscriptionType == 'partner' ||
                      addonsOnly) &&
                      localStorage.getItem('country') === 'GB' && (
                        <tr>
                          <td>VAT (20%)</td>
                          <td>
                            {localStorage.getItem('currencySign')}
                            {formatPrice(calculateVAT())}
                          </td>
                        </tr>
                      )}
                    {/* //* Show summary for upgrading subscriptions */}
                    {subscriptionType == 'upgrade' &&
                      upcomingInvoice &&
                      upcomingInvoice.lines.data.map((invoice, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{invoice.description.split('(at')[0]}</td>
                            <td>
                              {localStorage.getItem('currencySign')}
                              {formatPrice(invoice.amount)}
                            </td>
                          </tr>
                        );
                      })}
                    {subscriptionType == 'upgrade' &&
                      upcomingInvoice &&
                      localStorage.getItem('country') === 'GB' && (
                        <tr>
                          <td>VAT (20%)</td>
                          <td>
                            {localStorage.getItem('currencySign')}
                            {formatPrice(upcomingInvoice.tax)}
                          </td>
                        </tr>
                      )}

                    <tr>
                      <td style={{ padding: 0 }}>
                        <hr style={{ width: '100%' }} />
                      </td>
                      <td style={{ padding: 0 }}>
                        <hr style={{ width: '100%' }} />
                      </td>
                    </tr>
                    {(subscriptionType == 'new' ||
                      subscriptionType == 'downgrade' ||
                      subscriptionType == 'partner' ||
                      addonsOnly) && (
                      <tr>
                        <td>Total:</td>
                        <td>
                          {localStorage.getItem('currencySign')}
                          {calculateTotal()}
                        </td>
                      </tr>
                    )}
                    {subscriptionType == 'upgrade' && upcomingInvoice && (
                      <tr>
                        <td>Total:</td>
                        <td>
                          {localStorage.getItem('currencySign')}
                          {formatPrice(upcomingInvoice.total)}
                        </td>
                      </tr>
                    )}
                    {/* {containsAdditionalCredits() && clientSecret && (
                      <tr>
                        <td colSpan={2} className="text-red">
                          Any additional credits will expire on{' '}
                          {new Date(
                            endDate ? endDate * 1000 : props.account.PaidUntil
                          ).toLocaleDateString('en-gb', dateOptions)}
                        </td>
                      </tr>
                    )} */}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Button
                  onClick={e => {
                    cancelPurchase();
                  }}
                  variant="primary"
                  className="btn-block"
                >
                  Cancel Purchase
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg={6}>
            <Card
              style={{
                height: '100%',
                marginTop: breakpoints.width >= 992 ? 0 : 16
              }}
              className="p-32"
            >
              <Card.Header>
                <h2>Checkout</h2>
              </Card.Header>
              {clientSecret !== null &&
              (subscriptionType == 'new' ||
                subscriptionType == 'upgrade' ||
                addonsOnly) ? (
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret: clientSecret }}
                >
                  <CheckoutBody
                    setShowCheckout={props.setShowCheckout}
                    cancelPurchase={cancelPurchase}
                    subscriptionType={subscriptionType}
                    App={
                      props.subscription.App !== undefined
                        ? props.subscription.App
                        : props.addons[0].App
                    }
                    account={props.account}
                    addonsOnly={addonsOnly}
                    addons={props.addons}
                    subscription={props.subscription}
                    prorate={prorate}
                  />
                </Elements>
              ) : (
                ''
              )}
              {subscriptionType == 'partner' && clientSecret && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <AgencyCheckoutView subscription={props.subscription} />
                </Elements>
              )}
              {subscriptionType == 'upgrade' && card !== null ? (
                <Card.Body
                  style={{
                    paddingTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <p>
                    This will be charged to your card ending in {card.last4}{' '}
                    immediately. Your billing cycle will be reset and any unused
                    credits will be to your next billing periods allowance.
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Button
                      onClick={e => {
                        upgradeSubscriptionOnClick(e);
                      }}
                      variant="danger"
                      className="btn-block"
                      style={{ width: '48%' }}
                    >
                      Upgrade
                    </Button>
                    <Button
                      onClick={() => {
                        setCard(null);
                        setupCard();
                      }}
                      variant="danger"
                      className="btn-block"
                      style={{ width: '48%' }}
                    >
                      Change Card
                    </Button>
                  </div>
                </Card.Body>
              ) : (
                ''
              )}
              {subscriptionType == 'downgrade' && (
                <Card.Body
                  style={{
                    paddingTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <p>
                    Your plan will downgrade on{' '}
                    {new Date(props.account.PaidUntil).toLocaleDateString(
                      'en-gb',
                      {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      }
                    )}
                    . You will still be charged until the end of your current
                    billing cycle.
                  </p>
                  <Button
                    onClick={e => {
                      upgradeSubscriptionOnClick(e);
                    }}
                    variant="danger"
                    className="btn-block"
                  >
                    Downgrade Plan
                  </Button>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  if (clientSecret === null) {
    return <></>;
  }
};

CheckoutView.propTypes = {
  showCheckout: PropTypes.bool,
  setShowCheckout: PropTypes.func,
  subscription: PropTypes.object,
  addons: PropTypes.array,
  account: PropTypes.object
};

CheckoutBody.propTypes = {
  cancelPurchase: PropTypes.func,
  subscriptionType: PropTypes.string,
  App: PropTypes.string,
  account: PropTypes.object,
  addonsOnly: PropTypes.bool,
  addons: PropTypes.array,
  prorate: PropTypes.bool,
  subscription: PropTypes.object
};

export default CheckoutView;

const AgencyCheckoutView = props => {
  const elements = useElements();
  const stripe = useStripe();

  const purchaseSub = async () => {
    await axios
      .post('/subscriptions/create-subscription', {
        subscription: props.subscription,
        addons: []
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log(err);
      });

    // await axios
    //   .post('/subscriptions/setcheckoutcomplete', {
    //     app: props.subscription.App
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/payment-complete?purchase=success&app=${props.subscription.App}&tier=${props.subscription.Name}`
      }
    });

    if (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <PaymentElement />
      <Button
        onClick={purchaseSub}
        variant="danger"
        className="btn-block"
        style={{
          position: 'absolute',
          bottom: 32,
          marginBottom: 16
        }}
      >
        Purchase
      </Button>
    </>
  );
};
