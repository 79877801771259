import React, { useContext, useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import allRoutes from 'routes/routes';
import { Button } from 'react-bootstrap';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import { useNavigate } from 'react-router-dom';
import AnimateCC from 'react-adobe-animate';
import { useBreakpoints } from 'hooks/useBreakpoints';

const NavbarVertical = () => {
  const breakpoints = useBreakpoints();
  const [routes, setRoutes] = useState([]);
  const navigate = useNavigate();
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
      isDark
    }
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (window.location.pathname.includes('pmladmin')) {
      setRoutes([allRoutes.find(route => route.label === 'Admin')]);
    } else {
      let temp_routes = allRoutes.find(
        route =>
          route.label.toLowerCase() === window.localStorage.getItem('platform')
      );
      setRoutes([temp_routes]);
    }
  }, [window.localStorage.getItem('platform')]);

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
      style={{ backgroundColor: isDark ? '#2E4450' : '#FDFDFD' }}
    >
      <Flex alignItems="center">
        <ToggleButton />
        {isDark && !isNavbarVerticalCollapsed && (
          <div style={{ width: 55, paddingTop: 8 }}>
            <AnimateCC animationName="LabsLogoWhite" paused={false} />
          </div>
        )}
        {!isNavbarVerticalCollapsed && !isDark && (
          <div style={{ width: 55, paddingTop: 8 }}>
            <AnimateCC animationName="LabsLogoBlue" paused={false} />
          </div>
        )}
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ backgroundColor: isDark ? '#2E4450' : '#FDFDFD' }}
      >
        <div className="navbar-vertical-content scrollbar">
          <Nav className="flex-column" as="ul">
            {routes.map(route => {
              if (
                localStorage.getItem('installedApps') === '[]' &&
                !window.location.pathname.includes('pmladmin')
              ) {
                return '';
              }
              return (
                <Fragment key={route.label}>
                  {!route.labelDisable && <NavbarLabel label="Apps" />}
                  <NavbarVerticalMenu routes={route.children} />
                </Fragment>
              );
            })}
          </Nav>

          <>
            {navbarPosition === 'combo' && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
          </>
        </div>
        {localStorage.getItem('installedApps') !== '[]' &&
          (!isNavbarVerticalCollapsed || breakpoints.width < 1200) &&
          localStorage.getItem('admin') == 'true' &&
          !window.location.pathname.includes('pmladmin') && (
            <Button
              id="install-app-button"
              variant="danger"
              className="btn-block"
              style={{
                marginBottom: breakpoints.width < 1200 ? '24px' : '0px'
              }}
              onClick={() => {
                navigate('/install');
              }}
            >
              Install App
            </Button>
          )}
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
