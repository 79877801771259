import loading from 'assets/animations/loading.json';
import axios from 'axios';
import Invoice from 'components/billing/Invoice';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Modal, Table } from 'react-bootstrap';

const InvoiceSettings = props => {
  const [users, setUsers] = useState([]);
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [userToRemove, setUserToRemove] = useState('');
  const [removeModalText, setRemoveModalText] = useState();

  useEffect(() => {
    if (props.active) {
      fetchUsers();
    }
  }, [props.active]);

  const fetchUsers = () => {
    axios.post('/settings/getinvoiceemails').then(res => {
      setUsers(res.data);
    });
  };

  const addNewUser = async () => {
    setModalLoading(true);
    setShowAlert(false);
    setEmail('');
    let res = await axios.post('/settings/invoiceemails', { email: email });
    setAlertText(res.data);
    if (res.data == 'Email added successfully') {
      setAlertVariant('success');
    } else {
      setAlertVariant('danger');
    }
    setShowAlert(true);
    fetchUsers();
    setModalLoading(false);
  };

  const removeUser = async () => {
    setModalLoading(true);
    let res = await axios.delete(
      '/settings/invoiceemails?email=' + encodeURIComponent(userToRemove)
    );
    setRemoveModalText(res.data);
    fetchUsers();
    setModalLoading(false);
  };

  if (users == null) {
    return (
      <Lottie
        animationData={loading}
        loop
        autoplay
        style={{ width: '200px', height: '200px', margin: 'auto' }}
      />
    );
  }

  return (
    <>
      {/* //* Add new user moda/ */}
      <Modal
        show={addNewUserModal}
        onHide={() => {
          setAddNewUserModal(false);
          setShowAlert(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={alertVariant} hidden={!showAlert}>
            {alertText}
          </Alert>
          <div hidden={modalLoading}>
            {/* <p>The email added</p> */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>
          </div>
          <Lottie
            animationData={loading}
            loop
            autoplay
            style={{ width: '100px', height: '100px', margin: 'auto' }}
            hidden={!modalLoading}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setAddNewUserModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => addNewUser()}>
            Add Email
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //*Delete user modal */}
      <Modal
        show={removeUserModal}
        onHide={() => {
          setRemoveUserModal(false);
          setRemoveModalText(
            <p>Are you sure you want to remove {userToRemove}?</p>
          );
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div hidden={modalLoading}>{removeModalText}</div>
          <Lottie
            animationData={loading}
            loop
            autoplay
            style={{ width: '100px', height: '100px', margin: 'auto' }}
            hidden={!modalLoading}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setRemoveUserModal(false)}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={e => {
              removeUser();
              e.target.hidden = true;
            }}
          >
            Remove Email
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="horizontalDiv">
        <h3>Manage Invoices</h3>
        <Button onClick={() => setAddNewUserModal(true)} variant="danger">
          Add New Email
        </Button>
      </div>
      <p style={{ marginTop: 16 }}>
        All emails listed below will be sent a copy of all invoices when they
        are paid.
      </p>
      <hr />
      <Table responsive hover>
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th className="text-end" scope="col">
              {/* Actions */}
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, idx) => {
            return (
              <tr key={idx}>
                <td>{user}</td>
                <td className="text-end">
                  <Button
                    variant="danger"
                    onClick={() => {
                      setRemoveUserModal(true);
                      setUserToRemove(user);
                      setRemoveModalText(
                        <p>Are you sure you want to remove {user}?</p>
                      );
                    }}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

InvoiceSettings.propTypes = {
  active: PropTypes.bool
};

export default InvoiceSettings;
