import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';

import PersonalSettings from 'components/settings/PersonalSettings';
import DisplaySettings from 'components/settings/DisplaySettings';

const ProfileSettings = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    if (location) {
      setActiveTab(location.pathname.split('/profilesettings/')[1]);
    }
  }, [location]);

  return (
    <>
      <h2 className="boldHeader">SETTINGS</h2>

      <Tabs
        id="settingsTab"
        activeKey={activeTab}
        onSelect={t => {
          navigate('/profilesettings/' + t);
        }}
      >
        <Tab
          eventKey="password"
          title="Change Password"
          className="border-bottom border-x p-3"
        >
          <PersonalSettings active={activeTab == 'password'} />
        </Tab>
        <Tab
          eventKey="display"
          title="Display Settings"
          className="border-bottom border-x p-3"
        >
          <DisplaySettings active={activeTab == 'display'} />
        </Tab>
      </Tabs>
    </>
  );
};

export default ProfileSettings;
