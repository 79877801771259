import React from 'react';
import Loading from 'components/common/Loading';
import { Modal, Card, Table, Button } from 'react-bootstrap';

const CURRENCIES = {
  USD: '$',
  GBP: '£',
  EUR: '€'
};

const TransactionsModal = ({
  title,
  transactions,
  setTransactions,
  exchangeRates,
  app
}) => {
  const handleClose = () => setTransactions(null);

  const sortedTransactions =
    transactions &&
    exchangeRates &&
    transactions.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));

  const toGBP = (amount, currency) => {
    let newAmount = 0;
    switch (currency) {
      case 'USD':
        newAmount = amount / exchangeRates.GBPUSD;
        break;
      case 'EUR':
        newAmount = amount / exchangeRates.GBPEUR;
        break;
      default:
        newAmount = amount;
    }

    return newAmount;
  };

  const formatMoney = amount => {
    return Math.round((amount / 100) * 100) / 100;
  };

  return (
    <Modal show={transactions} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
        <Button
          variant="danger"
          size="sm"
          style={{ marginLeft: 20 }}
          onClick={() => console.log(transactions)}
        >
          Print Transactions
        </Button>
      </Modal.Header>
      <Modal.Body
        style={{
          height: '100%',
          maxHeight: '60vh',
          overflow: 'auto',
          padding: 0
        }}
      >
        <Table striped bordered hover>
          <thead style={{ position: 'sticky', top: 0, background: '#F1F2F6' }}>
            <tr>
              <th>Timestamp</th>
              <th>Customer Name</th>
              <th>Net Amount</th>
              <th>Net Amount GBP</th>
              <th>Products</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedTransactions &&
              sortedTransactions.map(t => {
                return (
                  <tr key={t.TransactionID}>
                    <td>{t.Timestamp}</td>
                    <td>{t.CustomerName}</td>
                    <td>
                      {CURRENCIES[t.Currency]}
                      {formatMoney(t.NetAmount)}
                    </td>
                    <td>£{formatMoney(toGBP(t.NetAmount, t.Currency))}</td>
                    <td>{t.Products.map(p => p.Name).join(',')}</td>
                    <td>
                      {t.StripeInvoiceID && (
                        <Button
                          variant="danger"
                          onClick={() =>
                            window
                              .open(
                                `https://dashboard.stripe.com/invoices/${t.StripeInvoiceID}`,
                                '_blank'
                              )
                              .focus()
                          }
                        >
                          Stripe
                        </Button>
                      )}
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => {
                          window
                            .open(
                              `${process.env.REACT_APP_BASE_URL}/pmladmin/dashboard/${app}?account=${t.CustomerName} (${t.PortalID})`,
                              '_blank'
                            )
                            .focus();
                        }}
                      >
                        Dashboard
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default TransactionsModal;
