import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TrialAlert = props => {
  const [hidden, setHidden] = useState(true);

  const remainingMS = new Date(new Date(props.account.PaidUntil) - new Date());

  const remainingDays = Math.floor(remainingMS / 8.64e7);

  useEffect(() => {
    if (props.account.TrialStatus == 'current') {
      setHidden(false);
    } else {
      setHidden(true);
    }
  });

  return (
    <>
      <Alert
        variant="warning"
        onClose={() => setHidden(true)}
        dismissible
        hidden={hidden}
      >
        <div className="horizontalDiv">
          {remainingDays >= 0
            ? `Your ${props.app} trial will run out in ${
                remainingDays + 1
              } days.`
            : `Your ${props.app} trial has run out.`}
        </div>
      </Alert>
    </>
  );
};

TrialAlert.propTypes = {
  app: PropTypes.string,
  account: PropTypes.object
};

export default TrialAlert;
