import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import PageTitle from 'components/common/PageTitle';
import NavbarTop from 'components/navbar/top/NavbarTop';
import loadingJSON from 'assets/animations/loading.json';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Form, Modal } from 'react-bootstrap';

const Agencys = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    onPageLoad();
  }, []);

  const onPageLoad = async () => {
    const isAdmin = await axios
      .post('/pmladmin/isadmin')
      .then(res => res.data)
      .catch(err => console.log(err));

    if (isAdmin != true) {
      return navigate('/page-not-found');
    }

    await axios
      .post('/pmladmin/listaccounts')
      .then(res => setAccounts(res.data))
      .catch(err => console.log(err));

    setLoading(false);
  };

  const createDataList = () => {
    return (
      <>
        <datalist id="accounts">
          {accounts.map(account => {
            if (account.IsAgency) return null;
            return (
              <option key={account.AccountID} value={account.AccountName} />
            );
          })}
        </datalist>
      </>
    );
  };

  const toggleAgencyAccount = async id => {
    console.log(id);
    setShowNewModal(false);
    setLoading(true);

    await axios
      .post('/pmladmin/toggleagencyaccount', { accountId: id })
      .catch(err => {
        console.error(err);
      });

    onPageLoad();
  };

  if (loading) {
    return (
      <div style={{ width: '100vw', height: '100%' }}>
        <Lottie
          animationData={loadingJSON}
          loop
          autoplay
          style={{
            width: '200px',
            height: '200px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </div>
    );
  }

  return (
    <>
      {createDataList()}
      <Modal
        show={showNewModal}
        onHide={() => {
          setSelectedAccount(null);
          setShowNewModal(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Partner Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              This will give the selected account access to the free partner
              tier.
            </p>
            <Form.Group className="mb-3">
              <label>Account:</label>
              <input
                className="form-select"
                // style={{ width: 500 }}
                list="accounts"
                autoComplete="on"
                id="accountName"
                onChange={e => {
                  let name = e.target.value;
                  const account = accounts.find(
                    account => account.AccountName === name
                  );
                  if (account != null) setSelectedAccount(account.AccountID);
                  else setSelectedAccount(null);
                }}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowNewModal(false);
              setSelectedAccount(null);
            }}
          >
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => toggleAgencyAccount(selectedAccount)}
            disabled={selectedAccount == null}
          >
            Set Partner Account
          </Button>
        </Modal.Footer>
      </Modal>
      <NavbarTop simple />
      <PageTitle app="" page="Partners" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: 16,
          paddingRight: 20
        }}
      >
        <p>
          All accounts listed below have access to purchase the free partner
          tier on all apps.
        </p>
        <Button variant="danger" onClick={() => setShowNewModal(true)}>
          Add Partner Account
        </Button>
      </div>
      <Table responsive hover>
        <thead>
          <tr>
            <th scope="col">Account Name</th>
            <th scope="col" className="text-end"></th>
          </tr>
        </thead>
        <tbody>
          {accounts.map(account => {
            if (!account.IsAgency) return null;
            return (
              <tr key={account.AccountID}>
                <td>{account.AccountName}</td>
                <td className="text-end">
                  <Button
                    variant="danger"
                    onClick={() => {
                      toggleAgencyAccount(account.AccountID);
                    }}
                  >
                    Remove Partner Status
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default Agencys;
