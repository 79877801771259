import 'chart.js/auto';
import axios from 'axios';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import Loading from 'components/common/Loading';
import { monthNames, textAppNames } from 'helpers/utils';

const colors = [
  '#2e4450',
  '#64a0c8',
  '#ffd24d',
  '#f80303',
  '#72c96b',
  '#62737c',
  '#96a1a7',
  '#cbd0d3',
  '#eaeced'
];

const formatData = async transactions => {
  if (!transactions) {
    return;
  }

  const groupedByMonth = transactions.reduce((acc, obj) => {
    const month = obj.Timestamp.substring(0, 7); // Extract the YYYY-MM part
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(obj);
    return acc;
  }, {});

  let now = new Date();

  let date12MonthsAgo = new Date();
  date12MonthsAgo.setMonth(now.getMonth() - 12);
  if (now.getMonth() < date12MonthsAgo.getMonth()) {
    date12MonthsAgo.setFullYear(now.getFullYear() - 1);
  }

  const labels = [];
  const datasets = {};

  while (date12MonthsAgo <= now) {
    let month = date12MonthsAgo.toISOString().substring(0, 7);
    let monthLabel =
      monthNames[date12MonthsAgo.getMonth()] +
      '-' +
      date12MonthsAgo.getFullYear().toString().slice(-2);

    if (groupedByMonth[month]) {
      for (const transaction of groupedByMonth[month]) {
        if (transaction.NetAmount <= 0) {
          continue;
        }

        for (const product of transaction.Products) {
          // Group all custom plans together
          if (product.AccountID != null || product.Hidden) {
            if (datasets['Custom'] == null) {
              datasets['Custom'] = {
                label: 'Custom',
                backgroundColor:
                  colors[
                    Array.from(Object.values(datasets)).length % colors.length
                  ] + 'B2', //~70% opacity
                data: {},
                transactions: {}
              };
            }

            datasets['Custom'].data[monthLabel] =
              datasets['Custom'].data[monthLabel] + 1 || 1;
            if (datasets['Custom'].transactions[monthLabel] == null) {
              datasets['Custom'].transactions[monthLabel] = [];
            }
            datasets['Custom'].transactions[monthLabel].push(transaction);

            continue;
          }
          if (
            product.Type === 'subscription' ||
            product.Type === 'annual-subscription'
          ) {
            if (datasets[product.Name] == null) {
              datasets[product.Name] = {
                label: product.Name,
                backgroundColor:
                  colors[
                    Array.from(Object.values(datasets)).length % colors.length
                  ] + 'B2', //~70% opacity
                data: {},
                transactions: {}
              };
            }
            datasets[product.Name].data[monthLabel] =
              datasets[product.Name].data[monthLabel] + 1 || 1;
            if (datasets[product.Name].transactions[monthLabel] == null) {
              datasets[product.Name].transactions[monthLabel] = [];
            }
            datasets[product.Name].transactions[monthLabel].push(transaction);
          }
        }
      }
    } else {
      null;
    }

    labels.push(monthLabel);
    date12MonthsAgo.setMonth(date12MonthsAgo.getMonth() + 1);
  }

  const data = {
    labels: labels,
    datasets: Array.from(Object.values(datasets))
  };

  return data;
};

const MonthlyTransactionsTrend = props => {
  let { app, height } = props;

  if (!height) {
    height = '300px';
  }

  const [transactions, setTransactions] = useState();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    setTransactions(props.transactions);
  }, [props.transactions]);

  useEffect(() => {
    formatData(transactions).then(d => setChartData(d));
  }, [transactions]);

  const chartRef = useRef();
  const chartOnClick = event => {
    const col = getElementAtEvent(chartRef.current, event)[0];

    if (!col) {
      return;
    }

    const month = Array.from(
      Object.keys(chartData.datasets[col.datasetIndex].data)
    )[col.index];

    const transactions =
      chartData.datasets[col.datasetIndex].transactions[month];

    if (props.setModalTitle && props.setModalTransactions) {
      props.setModalTitle(
        `${chartData.datasets[col.datasetIndex].label} - ${
          chartData.labels[col.index].split('-')[0]
        }`
      );
      props.setModalTransactions(transactions);
    }
  };

  const options = {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 2000
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          display: true,
          font: {
            size: 12
          }
        }
      },
      x: {
        stacked: true,
        ticks: {
          display: true,
          callback: (_, index) => {
            return chartData.labels[index].split('-')[0];
          }
        }
      }
    },
    plugins: {
      tooltip: {
        displayColors: true,
        yAlign: 'center',
        callbacks: {
          // label: tooltipItem => {
          //   const lines = [];
          //   for (const dataset of chartData.datasets) {
          //     if (dataset.data[tooltipItem.label] == null) {
          //       continue;
          //     }
          //     lines.push(
          //       `${dataset.label}: ${dataset.data[tooltipItem.label]}`
          //     );
          //   }
          //   return lines;
          // },
          // title: tooltipItem => {
          //   return tooltipItem[0].dataset.label + ' - ' + tooltipItem[0].label;
          // }
        }
      },
      legend: {
        display: true
      },
      title: {
        display: false
      },
      datalabels: {
        color: '#000',
        backgroundColor: '#ffffff80',
        font: {
          size: 13,
          family: 'Poppins'
        }
        // formatter: function (value, _) {
        //   return '£' + value;
        // }
      }
    }
  };

  if (chartData == null || chartData.length == 0) {
    return (
      <Card>
        <Card.Body style={{ paddingTop: 0, height: height }}>
          <div className="chart-header">
            <h2>{textAppNames[app]} Subscriptions</h2>
          </div>
          <Loading />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Body style={{ paddingTop: 0, height: height }}>
        <div className="chart-header">
          <h2>{textAppNames[app]} Subscriptions</h2>
        </div>
        <div style={{ height: 'calc(100% - 64px)' }}>
          <Bar
            ref={chartRef}
            onClick={chartOnClick}
            options={options}
            data={chartData}
            plugins={[ChartDataLabels]}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default MonthlyTransactionsTrend;
