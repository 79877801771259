import loadingJSON from 'assets/animations/loading.json';
import axios from 'axios';
import { defaults } from 'chart.js';
import PageTitle from 'components/common/PageTitle';
import ActivityM8Dashboard from 'components/dashboard/activitym8/ActivityM8Dashboard';
import BadgelyM8Dashboard from 'components/dashboard/badgelym8/BadgelyM8Dashboard';
import CurrencyR8Dashboard from 'components/dashboard/currencyr8/CurrencyR8Dashboard';
import PingDashboard from 'components/dashboard/ping/PingDashboard';
import QRGener8Dashboard from 'components/dashboard/qrgener8/QRGener8Dashboard';
import Valid8Dashboard from 'components/dashboard/valid8/Valid8Dashboard';
import NavbarTop from 'components/navbar/top/NavbarTop';
import AppContext from 'context/Context';
import Lottie from 'lottie-react';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const AdminDashboard = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  defaults.font.family = 'Poppins';
  defaults.color = isDark ? '#cbd0d3' : '#2E4450';
  const date = new Date();

  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date(date.setDate(date.getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());

  const accountNameField = useRef(null);

  useEffect(() => {
    onPageLoad();
  }, [pathname]);

  useEffect(() => {
    const account = searchParams.get('account');
    const range = searchParams.get('range');
    if (
      account != null &&
      range != null &&
      document.getElementById('accountName') != null
    ) {
      document.getElementById('accountName').value = account;
      setStartDate(new Date(range.split('-')[0]));
      setEndDate(new Date(range.split('-')[1]));
      setSearchParams({});
      setTimeout(() => {
        fetchDashboard();
      }, 1000);
      setSearchParams();
    } else if (
      account != null &&
      document.getElementById('accountName') != null
    ) {
      document.getElementById('accountName').value = account;
      setStartDate(new Date(date.setDate(date.getDate() - 30)));
      setEndDate(new Date());
      setTimeout(() => {
        fetchDashboard();
      }, 1000);
      setSearchParams();
    }
  });

  let app;
  switch (window.location.pathname.split('/')[3].toLowerCase()) {
    case 'currencyr8':
      app = 'CurrencyR8';
      break;
    case 'valid8':
      app = 'Valid8';
      break;
    case 'activitym8':
      app = 'ActivityM8';
      break;
    case 'qrgener8':
      app = 'QRGener8';
      break;
    case 'badgelym8':
      app = 'BadgelyM8';
      break;
    case 'ping':
      app = 'Ping';
      break;
  }

  const onRangeChange = dates => {
    setData(null);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onPageLoad = async () => {
    setData(null);
    const isAdmin = await axios
      .post('/pmladmin/isadmin')
      .then(res => res.data)
      .catch(err => console.log(err));

    if (isAdmin != true) {
      return navigate('/page-not-found');
    }

    await axios
      .post('/pmladmin/listaccounts')
      .then(res => setAccounts(res.data))
      .catch(err => console.log(err));

    setLoading(false);
  };

  const createDataList = () => {
    return (
      <>
        <datalist id="accounts">
          {accounts.map(account => (
            <option
              key={account.AccountID}
              value={`${account.AccountName} (${account.PortalID})`}
            />
          ))}
        </datalist>
      </>
    );
  };

  const fetchDashboard = async () => {
    setSelectedAccount(null);
    setData(null);
    let accountName = document.getElementById('accountName').value;
    // accountName = accountName.split(' (')[0];
    // const accountID = accounts.find(
    //   account => account.AccountName === accountName
    // ).AccountID;
    //

    let portalID = accountName.split(' (')[1].split(')')[0];
    const accountID = accounts.find(a => a.PortalID == portalID).AccountID;
    console.log(accountID, portalID);

    await Promise.allSettled(
      [
        axios.post('/pmladmin/getaccount', { accountID, app }).then(res => {
          setSelectedAccount(res.data);
        })
      ],
      [
        axios
          .post('/pmladmin/getdata', { accountID, app, startDate, endDate })
          .then(res => {
            setData(res.data);
          })
      ]
    );
  };

  if (loading) {
    return (
      <div style={{ width: '100vw', height: '100%' }}>
        <Lottie
          animationData={loadingJSON}
          loop
          autoplay
          style={{
            width: '200px',
            height: '200px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </div>
    );
  }

  return (
    <>
      {createDataList()}
      <NavbarTop simple={true} />
      <PageTitle app={app} page="Dashboard" />
      <div
        style={{
          padding: 16,
          display: 'flex'
        }}
      >
        <div style={{ marginRight: 16 }}>
          <label>Account:</label>
          <input
            className="form-select"
            style={{ width: 500 }}
            list="accounts"
            autoComplete="on"
            id="accountName"
            ref={accountNameField}
          />
        </div>
        <div style={{ marginRight: 16 }}>
          <label>Range:</label>
          <ReactDatePicker
            selected={startDate}
            onChange={onRangeChange}
            startDate={startDate}
            formatWeekDay={day => day.slice(0, 3)}
            endDate={endDate}
            selectsRange
            dateFormat="dd MMM yyyy"
            className="form-control"
          />
        </div>
        <Button
          variant="danger"
          style={{
            height: 'fit-content',
            alignSelf: 'flex-end',
            marginLeft: 'auto'
          }}
          onClick={fetchDashboard}
        >
          Search
        </Button>
      </div>
      <div style={{ padding: 16 }}>
        {selectedAccount != null && data != null && app == 'CurrencyR8' && (
          <CurrencyR8Dashboard
            account={selectedAccount}
            data={data}
            range={{ start: startDate, end: endDate }}
            admin={true}
            app={app}
          />
        )}
        {selectedAccount != null && data != null && app == 'Valid8' && (
          <Valid8Dashboard
            account={selectedAccount}
            data={data}
            range={{ start: startDate, end: endDate }}
            admin={true}
            app={app}
          />
        )}
        {selectedAccount != null && data != null && app == 'ActivityM8' && (
          <ActivityM8Dashboard
            account={selectedAccount}
            data={data}
            range={{ start: startDate, end: endDate }}
            admin={true}
            app={app}
          />
        )}
        {selectedAccount != null && data != null && app == 'QRGener8' && (
          <QRGener8Dashboard
            account={selectedAccount}
            data={data}
            range={{ start: startDate, end: endDate }}
            admin={true}
            app={app}
          />
        )}
        {selectedAccount != null && data != null && app == 'BadgelyM8' && (
          <BadgelyM8Dashboard
            account={selectedAccount}
            data={data}
            range={{ start: startDate, end: endDate }}
            admin={true}
            app={app}
          />
        )}
        {selectedAccount != null && data != null && app == 'Ping' && (
          <PingDashboard
            account={selectedAccount}
            data={data}
            range={{ start: startDate, end: endDate }}
            admin={true}
            app={app}
          />
        )}
      </div>
    </>
  );
};

export default AdminDashboard;
