import AdminUsageData from 'components/dashboard/common/AdminUsageData';
import UsageData from 'components/dashboard/common/UsageData';
import LogsTable from 'components/dashboard/valid8/LogsTable';
import ValidationsBarChart from 'components/dashboard/valid8/ValidationsBarChart';
import ValidationLineChart from 'components/dashboard/valid8/ValidationsLineChart';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AdminBetas from '../common/AdminBetas';
// import AdminValid8Settings from './AdminValid8Settings';

const PingDashboard = props => {
  console.log(props.data);

  return (
    <>
      <Row>
        <Col lg={3} style={{ paddingBottom: 32 }}>
          <ValidationsBarChart data={props.data} />
        </Col>
        <Col lg={6} style={{ paddingBottom: 32 }}>
          <ValidationLineChart data={props.data} range={props.range} />
        </Col>
        <Col lg={3} style={{ paddingBottom: 32 }}>
          {props.admin ? (
            <AdminUsageData data={props.account} app={props.app} />
          ) : (
            <UsageData
              data={props.account}
              allocation={props.account.MaxRequests}
              text={'Extra credits used'}
            />
          )}
        </Col>
      </Row>
      {props.admin && (
        <Row>
          <Col lg={3} style={{ paddingBottom: 32 }}>
            <AdminBetas app={props.app} data={props.account} />
          </Col>
          {/* <Col lg={3} style={{ paddingBottom: 32 }}>
            <AdminValid8Settings data={props.account} />
          </Col> */}
        </Row>
      )}
      <Row>
        <Col>
          <LogsTable data={props.data} />
        </Col>
      </Row>
    </>
  );
};

export default PingDashboard;
