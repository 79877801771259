import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';

const UsageData = props => {
  const allocation = props.allocation;
  const currentUsage = props.data.CurrentRequests;
  const [period, setPeriod] = useState();
  const [accountType, setAccountType] = useState();

  const dateOptions = {
    month: 'short',
    day: 'numeric'
  };

  const dateOptionsLong = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  useEffect(() => {
    if (props.data !== undefined) {
      let endDate = new Date(props.data.PaidUntil);
      let startDate = new Date(props.data.PaidUntil);

      startDate.setMonth(startDate.getMonth() - 1);

      setPeriod(
        `${startDate.toLocaleString(
          'en-gb',
          dateOptions
        )} - ${endDate.toLocaleString('en-gb', dateOptions)}`
      );
    }
  }, [props]);

  return (
    <Card style={{ height: '100%' }}>
      <Card.Body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 12
          }}
        >
          <h2 style={{ fontWeight: 'normal', fontSize: 24 }}>Credits</h2>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: 12
          }}
        >
          <ProgressBar
            min={0}
            max={allocation}
            now={currentUsage}
            style={{ flex: 9, marginRight: 4 }}
          />
        </div>
        <div>
          <p style={{ marginBottom: 12 }}>
            {currentUsage > allocation ? allocation : currentUsage} used /{' '}
            {allocation} available
            {props.data.PadiUntil != null && (
              <>
                {' '}
                until{' '}
                {new Date(props.data.PaidUntil).toLocaleDateString(
                  'en-gb',
                  dateOptionsLong
                )}
              </>
            )}
          </p>
        </div>
        {(props.data.CurrentPlan !== 11 || props.data.CurrentPlan !== 12) && (
          <>
            <h4 style={{ fontWeight: 'normal', fontSize: 16 }}>
              <br></br>
              {/* Overage:{' '} */}
              {currentUsage - allocation < 0
                ? 0
                : currentUsage - allocation}{' '}
              {props.text}
            </h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline'
              }}
            >
              {/* <p style={{ fontSize: 16, paddingRight: 8 }}>
              </p> */}
              {/* <p>{props.text}</p> */}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

UsageData.propTypes = {
  data: PropTypes.object,
  allocation: PropTypes.number,
  text: PropTypes.string
};

export default UsageData;
