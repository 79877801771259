import axios from 'axios';
import { defaults } from 'chart.js';
import ReactivateSubAlert from 'components/billing/ReactivateSubAlert';
import PageTitle from 'components/common/PageTitle';
import ActivityM8Dashboard from 'components/dashboard/activitym8/ActivityM8Dashboard';
import BadgelyM8Dashboard from 'components/dashboard/badgelym8/BadgelyM8Dashboard';
import CurrencyR8Dashboard from 'components/dashboard/currencyr8/CurrencyR8Dashboard';
import PingDashboard from 'components/dashboard/ping/PingDashboard';
import QRGener8Dashboard from 'components/dashboard/qrgener8/QRGener8Dashboard';
import Valid8Dashboard from 'components/dashboard/valid8/Valid8Dashboard';
import AppContext from 'context/Context';
import { hasAppAccount } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonGroup,
  Toast,
  ToastContainer,
  ToggleButton
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

const Dashboard = props => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [trialUsed, setTrialUsed] = useState(null);
  const [account, setAccount] = useState(null);
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState('30d');
  const [addonsPurchaseToast, setAddonsPurchaseToast] = useState(false);

  // Colors for charts.js
  defaults.font.family = 'Poppins';
  defaults.color = isDark ? '#cbd0d3' : '#2E4450';

  useEffect(() => {
    //* Check if trial has been activated
    setData(null);
    setAccount(null);
    setTrialUsed(null);
    hasAppAccount(props.app).then(res => {
      setTrialUsed(res);
    });
  }, [props.app]);

  useEffect(() => {
    if (searchParams.get('addonspurchased') == '') {
      setAddonsPurchaseToast(true);
    }
  }, []);

  useEffect(() => {
    if (trialUsed) {
      axios
        .post('/fetch-app-account', { app: props.app })
        .then(res => setAccount(res.data));
      axios
        .post('/dashboard/getdata', { app: props.app, range: dateRange })
        .then(res => setData(res.data))
        .catch(err => console.log(err));
    }
  }, [trialUsed, props.app]);

  useEffect(() => {
    if (!trialUsed) {
      return; // Stops fetching data twice on refresh
    }
    axios
      .post('/dashboard/getdata', { app: props.app, range: dateRange })
      .then(res => setData(res.data));
  }, [dateRange]);

  if (account !== null && data !== null && account !== '') {
    return (
      <>
        <ToastContainer
          position="top-end"
          style={{ zIndex: 999, backgroundColor: '#fff' }}
        >
          <Toast
            onClose={() => setAddonsPurchaseToast(false)}
            show={addonsPurchaseToast}
          >
            <Toast.Header closeButton={true}>
              <img className="rounded me-2" alt="" />
              <strong className="me-auto">Valid8</strong>
            </Toast.Header>
            <Toast.Body>Addon purchase successful</Toast.Body>
          </Toast>
        </ToastContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <PageTitle app={props.app} page={'Dashboard'} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 'fit-content'
            }}
          >
            <ButtonGroup style={{ height: 'fit-content' }}>
              <ToggleButton
                type="radio"
                variant="outline-danger"
                value="alltime"
                id="alltimeToggle"
                checked={dateRange == 'alltime'}
                onChange={e => setDateRange(e.target.value)}
              >
                All Time
              </ToggleButton>
              <ToggleButton
                type="radio"
                variant="outline-danger"
                value="30d"
                id="30dToggle"
                checked={dateRange == '30d'}
                onChange={e => setDateRange(e.target.value)}
              >
                Last 30 Days
              </ToggleButton>
            </ButtonGroup>
          </div>
        </div>
        <ReactivateSubAlert account={account} app={props.app} />
        {props.app == 'Valid8' && (
          <Valid8Dashboard
            data={data}
            account={account}
            range={null}
            admin={false}
          />
        )}
        {props.app == 'CurrencyR8' && (
          <CurrencyR8Dashboard
            data={data}
            account={account}
            range={null}
            admin={false}
          />
        )}
        {props.app == 'ActivityM8' && (
          <ActivityM8Dashboard
            data={data}
            account={account}
            range={null}
            admin={false}
          />
        )}
        {props.app == 'QRGener8' && (
          <QRGener8Dashboard
            data={data}
            account={account}
            range={null}
            admin={false}
          />
        )}
        {props.app == 'BadgelyM8' && (
          <BadgelyM8Dashboard
            data={data}
            account={account}
            range={null}
            admin={false}
          />
        )}
        {props.app == 'Ping' && (
          <PingDashboard
            data={data}
            account={account}
            range={null}
            admin={false}
          />
        )}
      </>
    );
  }

  if (trialUsed === false) {
    return <PageTitle app={props.app} page={'Plans'} />;
  }

  return <PageTitle app={props.app} page={'Dashboard'} />;
};

Dashboard.propTypes = {
  app: PropTypes.string
};

export default Dashboard;
