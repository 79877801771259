import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ErrorModal = props => {
  const { error, onClose, app } = props;

  let title = 'Something went wrong';
  let body = 'Please try again.';

  switch (error) {
    case 'PORTAL_MISMATCH':
      title = 'Wrong Portal';
      body = `Failed to install ${app}. Please make sure you are installing to the correct HubSpot portal.`;
      break;
  }

  return (
    <Modal
      show={error}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
