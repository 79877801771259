import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const PersonalSettings = props => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordIsInvalid, setCurrentPasswordIsInvalid] =
    useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordIsInvalid, setNewPasswordIsInvalid] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] =
    useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //  * Checks for 1 letter, 1 number, 8 characters and a special char
  const passwordRegex = new RegExp(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/
  );
  useEffect(() => {
    if (newPassword !== '' && !passwordRegex.test(newPassword)) {
      setNewPasswordIsInvalid(true);
    } else if (newPassword == '') {
      setNewPasswordIsInvalid(null);
    } else {
      setNewPasswordIsInvalid(false);
    }

    if (confirmPassword !== '' && confirmPassword !== newPassword) {
      setConfirmPasswordIsInvalid(true);
    } else if (confirmPassword == '') {
      setConfirmPasswordIsInvalid(null);
    } else {
      setConfirmPasswordIsInvalid(false);
    }
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    setCurrentPasswordIsInvalid(false);
  }, [currentPassword]);

  const updatePassword = async e => {
    e.preventDefault();
    if (
      newPasswordIsInvalid == true ||
      confirmPasswordIsInvalid == true ||
      currentPassword == '' ||
      newPassword == '' ||
      confirmPassword == ''
    ) {
      return;
    }
    setLoading(true);
    let response = await axios.post('/settings/updatePassword', {
      currentPassword: currentPassword,
      newPassword: newPassword
    });
    if (response.data == 'current password incorrect') {
      setCurrentPasswordIsInvalid(true);
    }

    if (response.data == 'success') {
      setShowAlert(true);
    }
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setLoading(false);
  };

  return (
    <>
      <h3>Change your password</h3>
      <Alert variant="success" hidden={!showAlert}>
        Password successfully updated.
      </Alert>
      <Form.Group className="mb-3">
        <Form.Label>Current Password</Form.Label>
        <InputGroup>
          <Form.Control
            type={showCurrentPassword ? 'text' : 'password'}
            value={currentPassword}
            onChange={e => {
              setCurrentPassword(e.target.value);
            }}
            isInvalid={currentPasswordIsInvalid}
          />
          <InputGroup.Text>
            <FontAwesomeIcon
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              icon={showCurrentPassword ? faEyeSlash : faEye}
            />
          </InputGroup.Text>
        </InputGroup>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: currentPasswordIsInvalid ? 'block' : 'none' }}
        >
          Current password incorrect.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>New Password</Form.Label>
        <InputGroup>
          <Form.Control
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={e => {
              setNewPassword(e.target.value);
            }}
            isInvalid={newPasswordIsInvalid}
          />
          <InputGroup.Text>
            <FontAwesomeIcon
              onClick={() => setShowNewPassword(!showNewPassword)}
              icon={showNewPassword ? faEyeSlash : faEye}
            />
          </InputGroup.Text>
        </InputGroup>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: newPasswordIsInvalid ? 'block' : 'none' }}
        >
          Password must contain at least 1 letter, 1 number, 1 special
          character(@$!%*#?&) and be 12 characters long.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Confirm New Password</Form.Label>
        <InputGroup>
          <Form.Control
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.target.value);
            }}
            isInvalid={confirmPasswordIsInvalid}
          />
          <InputGroup.Text>
            <FontAwesomeIcon
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              icon={showConfirmPassword ? faEyeSlash : faEye}
            />
          </InputGroup.Text>
        </InputGroup>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: confirmPasswordIsInvalid ? 'block' : 'none' }}
        >
          New passwords must match
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        onClick={e => {
          updatePassword(e);
        }}
        disabled={loading}
        variant="danger"
        className="btn-block"
        style={{ marginTop: 32 }}
      >
        {loading ? 'Loading' : 'Update Password'}
      </Button>
    </>
  );
};

export default PersonalSettings;
