import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import logo from 'assets/img/logo.png';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const SendEmailDiv = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const sendResetEmail = e => {
    e.preventDefault();
    setLoading(true);
    axios.post('/reset-password', { email: email }).then(res => {
      setShowAlert(true);
      setLoading(false);
    });
  };

  return (
    <>
      <div className="centerDiv">
        <div className="centerDivLogo">
          <img className="me-2" src={logo} />
        </div>
        <Card className="p-32">
          <Card.Body>
            <h3>Forgotten Password?</h3>
            <Alert
              variant="success"
              onClose={() => setShowAlert(false)}
              hidden={!showAlert}
              className="p-2"
            >
              An email has been sent to{' '}
              {email.split('@')[0].slice(0, 1) +
                '***' +
                email.split('@')[0].slice(-1) +
                '@' +
                email.split('@')[1]}{' '}
              containing a reset link.
            </Alert>
            <Form.Group className={'mb-3'} hidden={showAlert}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button
              onClick={e => sendResetEmail(e)}
              disabled={loading}
              hidden={showAlert}
              variant="danger"
              className="btn-block"
            >
              {loading ? 'Loading' : 'Continue'}
            </Button>
            <Button
              onClick={e => navigate('/login')}
              variant="danger"
              className="btn-block"
              hidden={!showAlert}
            >
              Back to Login
            </Button>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

const ChangePasswordDiv = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
  const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] =
    useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const passwordRegex = new RegExp(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/
  );

  useEffect(() => {
    if (password !== '' && !passwordRegex.test(password)) {
      setPasswordIsInvalid(true);
    } else if (password == '') {
      setPasswordIsInvalid(null);
    } else {
      setPasswordIsInvalid(false);
    }

    if (confirmPassword !== '' && confirmPassword !== password) {
      setConfirmPasswordIsInvalid(true);
    } else if (confirmPassword == '') {
      setConfirmPasswordIsInvalid(null);
    } else {
      setConfirmPasswordIsInvalid(false);
    }
  }, [password, confirmPassword]);

  const checkFields = () => {
    let fieldsAreValid = true;
    if (password == '' || !passwordRegex.test(password)) {
      fieldsAreValid = false;
      setPasswordIsInvalid(true);
    } else {
      setPasswordIsInvalid(false);
    }
    if (confirmPassword == '' || confirmPassword !== password) {
      fieldsAreValid = false;
      setConfirmPasswordIsInvalid(true);
    } else {
      setConfirmPasswordIsInvalid(false);
    }
    return fieldsAreValid;
  };

  const resetPassword = () => {
    setLoading(true);
    if (checkFields() == true) {
      axios
        .post('/reset-password-update', {
          password: password,
          token: searchParams.get('token'),
          id: searchParams.get('id')
        })
        .then(() => {
          setShowSuccessAlert(true);
        })
        .catch(() => {
          showErrorAlert(true);
        });
    }

    setLoading(false);
  };

  return (
    <>
      <div className="centerDiv">
        <div className="centerDivLogo">
          <img className="me-2" src={logo} />
        </div>
        <Card className="p-32">
          <h3>Reset Password</h3>
          <Alert
            variant="success"
            onClose={() => setShowSuccessAlert(false)}
            hidden={!showSuccessAlert}
            className="p-2"
          >
            Password successfully updated.
          </Alert>
          <Alert
            variant="danger"
            onClose={() => setShowErrorAlert(false)}
            hidden={!showErrorAlert}
            className="p-2"
          >
            Something went wrong. Try again or{' '}
            <a
              className="nav-link"
              style={{ padding: 0, cursor: 'pointer' }}
              onClick={() => navigate('/reset-password')}
            >
              request a new link.
            </a>
          </Alert>
          <Form.Group className={'mb-3'} hidden={showSuccessAlert}>
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                isInvalid={passwordIsInvalid}
              />
              <InputGroup.Text>
                <FontAwesomeIcon
                  onClick={() => setShowPassword(!showPassword)}
                  icon={showPassword ? faEyeSlash : faEye}
                />
              </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback
              type="invalid"
              style={{ display: passwordIsInvalid ? 'block' : 'none' }}
            >
              Password must contain at least 1 letter, 1 number, <br /> 1
              special character(@$!%*#?&) and be 12 characters long.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className={'mb-3'} hidden={showSuccessAlert}>
            <Form.Label>Confirm Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                isInvalid={confirmPasswordIsInvalid}
              />
              <InputGroup.Text>
                <FontAwesomeIcon
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                />
              </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback
              type="invalid"
              style={{ display: confirmPasswordIsInvalid ? 'block' : 'none' }}
            >
              Passwords must match.
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            onClick={e => resetPassword(e)}
            disabled={loading}
            hidden={showSuccessAlert}
            variant="danger"
            className="btn-block"
          >
            {loading ? 'Loading' : 'Reset Password'}
          </Button>
          <Button
            onClick={e => navigate('/login')}
            disabled={loading}
            hidden={!showSuccessAlert}
            className="btn-block"
            variant="danger"
          >
            Back to Login
          </Button>
        </Card>
      </div>
    </>
  );
};

const ResetPassword = () => {
  const [tokenType, setTokenType] = useState(); //* valid, invalid, expired
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get('token');
  const id = searchParams.get('id');

  useEffect(() => {
    if (token != null && id != null) {
      axios
        .post('/validate-reset-token', { token: token, id: id })
        .then(res => setTokenType(res.data));
    }
  }, []);

  if (token == null || id == null) {
    return <SendEmailDiv />;
  }
  if (tokenType == 'invalid' || tokenType == 'expired') {
    return <SendEmailDiv />; //* TODO show alert
  }
  if (tokenType == 'valid') {
    return <ChangePasswordDiv />;
  }

  return '';
};

export default ResetPassword;
