import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import axios from 'axios';
import Loading from 'components/common/Loading';
import { textAppNames } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

const PaymentComplete = props => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const purchasedAddons = searchParams.get('addons');

  const checkStatus = async () => {
    const paymentIntentclientSecret = searchParams.get(
      'payment_intent_client_secret'
    );
    const setupIntentclientSecret = searchParams.get(
      'setup_intent_client_secret'
    );
    const setupIntent = searchParams.get('setup_intent');

    const freePurchase = searchParams.get('purchase');

    // const purchaseAddons = async () => {
    //   await axios.post('/stripe/purchase-addons', {
    //     addonIds: searchParams.get('addons'),
    //     country: localStorage.getItem('country'),
    //     currency: localStorage.getItem('currencyName')
    //   });

    //   setTimeout(() => setPaymentStatus('succeeded'), 3000);
    // };

    if (freePurchase == 'success') {
      setTimeout(() => setPaymentStatus('succeeded'), 3000);
    }

    if (paymentIntentclientSecret !== null) {
      stripe
        .retrievePaymentIntent(paymentIntentclientSecret)
        .then(({ paymentIntent }) => {
          setPaymentStatus(paymentIntent.status);
        });
    }

    if (setupIntent !== null) {
      axios.post('/stripe/update-payment-method', {
        clientSecret: setupIntent
      });
    }

    if (setupIntentclientSecret !== null) {
      stripe
        .retrieveSetupIntent(setupIntentclientSecret)
        .then(({ setupIntent }) => {
          let addons;
          switch (setupIntent.status) {
            case 'succeeded':
              // purchaseAddons();
              break;
            default:
              setPaymentStatus(setupIntent.status);
          }
        });
    }
  };

  useEffect(() => {
    if (stripe !== null) {
      checkStatus();
    }
  }, [stripe]);

  useEffect(() => {
    if (paymentStatus === 'succeeded') {
      axios.post('/subscriptions/setcheckoutcomplete', {
        app: searchParams.get('app')
      });
    }
  }, [paymentStatus]);

  if (paymentStatus === 'succeeded' && !purchasedAddons) {
    return (
      <PlanMessage
        app={searchParams.get('app')}
        tier={searchParams.get('tier')}
      />
    );
  } else if (paymentStatus === 'succeeded' && purchasedAddons) {
    return <AddonMessage app={searchParams.get('app')} />;
  }

  if (paymentStatus === 'processing') {
    setTimeout(() => {
      checkStatus();
    }, 10000);
    return (
      <>
        <div>
          <div className="p-32">
            <h3 className="pb-2">Payment Processing</h3>
            <Card.Text className="pb-2">
              Your payment is being processed. Please wait a moment.
            </Card.Text>
          </div>
        </div>
      </>
    );
  }

  if (paymentStatus === 'requires_payment_method') {
    return (
      <>
        <div>
          <div className="p-32">
            <h3 className="pb-2">Payment Failed</h3>
            <Card.Text className="pb-2">
              Your payment failed. Please try again.
            </Card.Text>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="danger"
            >
              Back to Checkout
            </Button>
          </div>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

const PaymentCompleteWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentComplete />
    </Elements>
  );
};

const PlanMessage = ({ ...props }) => {
  const app = textAppNames[props.app];

  return (
    <div>
      <div className="p-32">
        <h3 className="pb-2">Plan Activated</h3>
        <Card.Text className="pb-2">
          Thank you, your {app} {props.tier} plan is now active.
          <br />
          <br />
          {app} is now installed and setup on your HubSpot portal ready for you
          to use.
          <br />
          <br />
          Visit our{' '}
          <a
            className="text-red"
            href={`${process.env.REACT_APP_WWW_URL}/help`}
            rel="noopener noreferrer"
            target="_blank"
          >
            help centre
          </a>{' '}
          to learn how to use {app} or go straight to your Pacific Marketing
          Labs {app}{' '}
          <a className="text-red" href={`/${props.app}/dashboard`}>
            dashboard
          </a>
          .
        </Card.Text>
      </div>
    </div>
  );
};

const AddonMessage = ({ ...props }) => {
  const app = textAppNames[props.app];

  return (
    <div>
      <div className="p-32">
        <h3 className="pb-2">Addon Purchased</h3>
        <Card.Text className="pb-2">
          Thank you, your addon has been added to your {app} account.
          <br />
          <br />
          Visit our{' '}
          <a
            className="text-red"
            href={`${process.env.REACT_APP_WWW_URL}/help`}
            rel="noopener noreferrer"
            target="_blank"
          >
            help centre
          </a>{' '}
          to learn how to use {app} or go straight to your Pacific Marketing
          Labs {app}{' '}
          <a className="text-red" href={`/${props.app}/dashboard`}>
            dashboard
          </a>
          .
        </Card.Text>
      </div>
    </div>
  );
};

export default PaymentCompleteWrapper;
