import React, { useEffect, useState } from 'react';
import { Route, Routes, useSearchParams, Navigate } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import Signup from 'views/Signup';
import Login from 'views/Login';
import Portals from 'views/Portals';
import NewPortal from 'views/NewPortal';
import InstallAppOAuthCallback from 'views/InstallAppOAuthCallback';
import ResetPassword from 'views/ResetPassword';
import IFrame from 'components/currencyr8/IFrame';
import ActivityM8IFrame from 'components/activitym8/ActivityM8IFrame';
import PageNotFound from 'views/errors/PageNotFound';
import AdminDashboard from 'views/admin/Dashboard';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      {/* prettier-ignore */}
      <Routes>
        {/* Routes Without Sidebar */}
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="admin-dashboard" element={<AdminDashboard />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/currencyr8/crmcard/iframe" element={<IFrame />} />
        <Route path="/activitym8/crmcard/iframe" element={<ActivityM8IFrame />} />
        <Route path="/oauth-callback/:app" element={<InstallAppOAuthCallback />} />
        <Route path="/portals" element={<Portals />} />
        <Route path="/newportal" element={<NewPortal />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="*" element={<MainLayout />} />
      </Routes>
    </>
  );
};

export default Layout;
