import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import Lottie from 'lottie-react';
import loading from 'assets/animations/loading.json';

let originalSettings = {};

const CurrencyR8Settings = () => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    let res = await axios.get('/settings/currencyr8');
    originalSettings = res.data;
    setSettings(res.data);
  };

  const saveSettings = async (instantSetting = null) => {
    if (instantSetting) {
      await axios.post('/settings/currencyr8', instantSetting);
      fetchSettings();
    } else {
      await axios.post('/settings/currencyr8', settings);
      fetchSettings();
    }
  };

  if (JSON.stringify(settings) === '{}')
    return (
      <Lottie
        animationData={loading}
        loop
        autoplay
        style={{ width: '200px', height: '200px', margin: 'auto' }}
      />
    );

  return (
    <>
      <div className="horizontalDiv">
        <h3>Currency-R8 Settings</h3>
        <Button
          onClick={() => saveSettings()}
          variant="danger"
          disabled={
            JSON.stringify(settings) === JSON.stringify(originalSettings)
          }
        >
          Save Changes
        </Button>
      </div>
      <Form.Check
        id="crmcard"
        label="Enable on-demand currency conversion from Deal record card."
        onChange={e => setSettings({ ...settings, crmcard: e.target.checked })}
        defaultChecked={settings.crmcard}
        checked={settings.crmcard}
      />
    </>
  );
};

export default CurrencyR8Settings;
