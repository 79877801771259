import logo from 'assets/img/logo.png';
import axios from 'axios';
import Loading from 'components/common/Loading';
import { BadgelyM8Modal, ErrorModal } from 'components/oauth-modals';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { textAppNames } from 'helpers/utils';

const freeApps = ['activitym8'];

const InstallAppOAuthCallback = () => {
  const [handshakeComplete, setHandshakeComplete] = useState(false);
  const [errorModal, setErrorModal] = useState(null);
  // const [valid8Modal, setValid8Modal] = useState(false);
  // const [pingModal, setPingModal] = useState(false);
  const [badgelym8Modal, setBadgelym8Modal] = useState(false);

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const code = params.get('code');
  const app = useParams().app;

  useEffect(() => {
    if (handshakeComplete === false && code !== null) {
      fetchTokens(code);
      axios.post('/auth/set-app-installed', {
        app: app,
        currency: localStorage.getItem('currencyName')
      });
    }
    switch (app) {
      case 'badgelym8':
        setBadgelym8Modal(true);
        break;
    }
  }, []);

  const returnToDashboard = async () => {
    await axios.post('/auth/get-installed-apps').then(res => {
      localStorage.setItem('installedApps', JSON.stringify(res.data));
    });
    let url = '../../' + app + '/dashboard';
    navigate(url);
  };

  const returnToPlans = async () => {
    await axios.post('/auth/get-installed-apps').then(res => {
      localStorage.setItem('installedApps', JSON.stringify(res.data));
    });
    let url = '../../' + app + '/plans';
    navigate(url);
  };

  const fetchTokens = code => {
    let installedApps = JSON.parse(localStorage.getItem('installedApps'));
    axios
      .get('/hubspot/oauth/store-tokens', {
        params: {
          code: code,
          app: app,
          installed: installedApps
            .map(i => i.toLowerCase())
            .includes(app)
            .toString()
        }
      })
      .then(setHandshakeComplete(true))
      .catch(err => setErrorModal(err.response.data));
  };

  if (handshakeComplete === false) {
    return <Loading />;
  }

  if (handshakeComplete === true) {
    return (
      <>
        <ErrorModal
          error={errorModal}
          onClose={() => {
            setErrorModal(null);
            returnToDashboard();
          }}
          app={textAppNames[app]}
        />
        <BadgelyM8Modal show={badgelym8Modal} setShow={setBadgelym8Modal} />
        <div className="centerDiv">
          <div className="centerDivLogo">
            <img className="me-2" src={logo} />
          </div>
          <Card>
            <Card.Body className="p-32">
              <h2>Thanks For Installing {textAppNames[app]}</h2>
              <Button
                className="btn-block mt-3"
                variant="danger"
                onClick={() => {
                  freeApps.includes(app)
                    ? returnToDashboard()
                    : returnToPlans();
                }}
              >
                {freeApps.includes(app)
                  ? 'Continue to Dashboard'
                  : 'Select Plan'}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
};

export default InstallAppOAuthCallback;
