import axios from 'axios';
import CheckoutView from 'components/billing/CheckoutView';
import Subscription from 'components/billing/Subscription';
import PageTitle from 'components/common/PageTitle';
import AppContext from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

const formatPrice = price => {
  price = price.toString();
  if (price.slice(-2) == '00') {
    return price.slice(0, -2);
  } else if (price == '0') {
    return price;
  } else {
    return price.slice(0, -2) + '.' + price.slice(-2);
  }
};

const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};

const Addons = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const [addons, setAddons] = useState();
  const [account, setAccount] = useState();
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [showCheckout, setShowCheckout] = useState(false);

  const {
    config: { showNavbar },
    setConfig
  } = useContext(AppContext);

  // stupid needs pathname dep otherwise it doesnt update between apps
  useEffect(() => {
    if (localStorage.getItem('admin') == 'false') {
      navigate('/page-not-found', { replace: true });
    }

    axios
      .get(
        `/additional-credits?currency=${localStorage.getItem('currencyName')}`
      )
      .then(res => {
        const filtered = res.data.filter(
          addon => addon.App.toLowerCase() == props.app.toLowerCase()
        );
        setAddons(filtered);
      });

    axios
      .post('/fetch-app-account', {
        app: props.app
      })
      .then(res => {
        setAccount(res.data);
      });
  }, [location.pathname]);

  useEffect(() => {
    if (showCheckout) {
      setConfig('showNavbar', false);
    } else {
      setConfig('showNavbar', true);
    }
  }, [showCheckout]);

  if (!addons || !account) {
    return <PageTitle app={props.app} page={'Add-ons'} />;
  }

  if (account?.CurrentPlan == null || account?.TrialStatus == 'current') {
    return (
      <>
        <PageTitle app={props.app} page={'Add-ons'} />
        <p>
          Please purchase a{' '}
          <a className="text-red" href={`/${props.app}/plans`}>
            plan{' '}
          </a>
          before purchasing any add-ons
        </p>
      </>
    );
  }

  if (showCheckout) {
    return (
      <CheckoutView
        showCheckout={showCheckout}
        setShowCheckout={setShowCheckout}
        subscription={{}}
        addons={[addons[selectedPlan]]}
        account={account ? account : {}} //Otherwise sends string, breaks prop validation
      />
    );
  }

  return (
    <>
      <PageTitle app={props.app} page={'Add-ons'} />
      <Card className="mt-5">
        <Row className="p-5">
          <Col lg={8}>
            <h3 className="pb-3">Booster Pack</h3>
            <p className="pb-3">
              Purchase extra credits to use until{' '}
              {new Date(account.PaidUntil).toLocaleDateString(
                'en-gb',
                dateOptions
              )}
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Form.Range
                min={0}
                max={addons.length - 1}
                step={1}
                onChange={e => {
                  setSelectedPlan(e.target.value);
                }}
                value={selectedPlan}
                className="plans-range"
                style={{ width: 'calc(100% - 37px)' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {addons.map((tier, idx) => {
                let requests = tier.Requests.toLocaleString();

                return (
                  <span key={idx} style={{ width: 74, textAlign: 'center' }}>
                    {requests}
                  </span>
                );
              })}
            </div>
          </Col>
          <Col lg={4}>
            {addons[0] && Object.keys(addons[0]).length > 0 && (
              <Subscription
                info={addons[selectedPlan]}
                features={[]}
                popular={false}
                chosen={false}
                setChosen={false}
                disabled={false}
                current={false}
                button={false}
                border={true}
                height={'unset'}
              />
            )}
          </Col>
        </Row>
      </Card>
      <Row className="pt-3">
        <Col>
          <Button
            id="checkout-button"
            onClick={() => setShowCheckout(true)}
            variant="danger"
            className="btn-block"
            style={{ marginTop: 16, marginBottom: 16 }}
          >
            Continue to Checkout
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Addons;
