export const hubspotRoutes = {
  label: 'HubSpot',
  children: [
    {
      name: 'Valid8',
      icon: 'envelope-open',
      active: true,
      children: [
        {
          name: 'Dashboard',
          to: '/valid8/dashboard',
          active: true,
          admin: false
        },
        {
          name: 'Billing',
          to: '/valid8/billing',
          active: true,
          admin: true
        },
        {
          name: 'Plans',
          to: '/valid8/plans',
          active: true,
          admin: true
        },
        {
          name: 'Add-ons',
          to: '/valid8/add-ons',
          active: true,
          admin: true
        }
      ]
    },
    {
      name: 'Currency-R8',
      icon: 'dollar-sign',
      active: true,
      children: [
        {
          name: 'Dashboard',
          to: '/currencyr8/dashboard',
          active: true,
          admin: false
        },
        {
          name: 'Scheduler',
          to: '/currencyr8/scheduler',
          active: true
        },
        {
          name: 'Billing',
          to: '/currencyr8/billing',
          active: true,
          admin: true
        },
        {
          name: 'Plans',
          to: '/currencyr8/plans',
          active: true,
          admin: true
        }
      ]
    },
    {
      name: 'Activity-M8',
      icon: 'pie-chart',
      active: true,
      children: [
        {
          name: 'Dashboard',
          to: '/activitym8/dashboard',
          active: true,
          admin: false
        }
        // {
        //   name: 'Billing',
        //   to: '/activitym8/billing',
        //   active: true,
        //   admin: true
        // },
        // {
        //   name: 'Plans',
        //   to: '/activitym8/plans',
        //   active: true,
        //   admin: true
        // }
      ]
    },
    {
      name: 'QR-Gener8',
      icon: 'qrcode',
      active: true,
      children: [
        {
          name: 'Dashboard',
          to: '/qrgener8/dashboard',
          active: true,
          admin: false
        },
        {
          name: 'Billing',
          to: '/qrgener8/billing',
          active: true,
          admin: true
        },
        {
          name: 'Plans',
          to: '/qrgener8/plans',
          active: true,
          admin: true
        }
      ]
    },
    {
      name: 'Badgely-M8',
      icon: 'qrcode',
      active: true,
      children: [
        {
          name: 'Dashboard',
          to: '/badgelym8/dashboard',
          active: true,
          admin: false
        },
        {
          name: 'Billing',
          to: '/badgelym8/billing',
          active: true,
          admin: true
        },
        {
          name: 'Plans',
          to: '/badgelym8/plans',
          active: true,
          admin: true
        }
      ]
    },
    {
      name: 'Ping',
      icon: 'envelope',
      active: true,
      children: [
        {
          name: 'Dashboard',
          to: '/ping/dashboard',
          active: true,
          admin: false
        },
        {
          name: 'Billing',
          to: '/ping/billing',
          active: true,
          admin: true
        },
        {
          name: 'Plans',
          to: '/ping/plans',
          active: true,
          admin: true
        }
      ]
    }
  ]
};

export const sharpspringRoutes = {
  label: 'SharpSpring',
  children: [
    {
      name: 'Valid8',
      icon: 'envelope-open',
      active: true,
      children: [
        {
          name: 'Dashboard',
          to: '/valid8/dashboard',
          active: true,
          admin: false
        },
        {
          name: 'Billing',
          to: '/valid8/billing',
          active: true,
          admin: true
        },
        {
          name: 'Plans',
          to: '/valid8/plans',
          active: true,
          admin: true
        }
      ]
    }
  ]
};

export const adminRoutes = {
  label: 'Admin',
  children: [
    {
      name: 'Dashboard',
      icon: 'house',
      active: true,
      children: [
        {
          name: 'Currency-R8',
          to: '/pmladmin/dashboard/currencyr8',
          active: true
        },
        {
          name: 'Valid8',
          to: '/pmladmin/dashboard/valid8',
          active: true
        },
        {
          name: 'Activity-M8',
          to: '/pmladmin/dashboard/activitym8',
          active: true
        },
        {
          name: 'QR-Gener8',
          to: '/pmladmin/dashboard/qrgener8',
          active: true
        },
        {
          name: 'Badgely-M8',
          to: '/pmladmin/dashboard/badgelym8',
          active: true
        },
        {
          name: 'Ping',
          to: '/pmladmin/dashboard/ping',
          active: true
        }
      ]
    },
    // {
    //   name: 'Logs',
    //   icon: 'file-alt',
    //   active: true,
    //   children: [
    //     {
    //       name: 'App Logs',
    //       to: '/pmladmin/logs/app',
    //       active: true
    //     },
    //     {
    //       name: 'Webhooks Logs',
    //       to: '/pmladmin/logs/webhooks',
    //       active: true
    //     }
    //   ]
    // },
    {
      name: 'Reports',
      icon: 'chart-line',
      active: true,
      children: [
        {
          name: 'Valid8',
          to: '/pmladmin/reports/valid8',
          active: true
        }
      ]
    },
    {
      name: 'Settings',
      icon: 'gear',
      active: true,
      children: [
        {
          name: 'Admins',
          to: '/pmladmin/admins',
          active: true
        },
        {
          name: 'Partners',
          to: '/pmladmin/partners',
          active: true
        },
        {
          name: 'Freeagent',
          to: '/pmladmin/freeagent',
          active: true
        },
        {
          name: 'Products',
          to: '/pmladmin/products',
          active: true
        }
      ]
    }
  ]
};

export default [hubspotRoutes, sharpspringRoutes, adminRoutes];
