import React from 'react';
import SharpSpringIcon from 'assets/img/icons/sharpspring.png';
import HubSpotIcon from 'assets/img/icons/hubspot-sprocket.png';

const PlatformHeader = () => {
  return (
    <div style={{ display: 'flex' }}>
      {/* <img
        className="platform-icon"
        src={
          localStorage.getItem('platform') == 'hubspot'
            ? HubSpotIcon
            : SharpSpringIcon
        }
      /> */}
      <p style={{ marginBottom: 4 }}>
        {JSON.parse(localStorage.getItem('portal'))
          ? JSON.parse(localStorage.getItem('portal')).name
          : ''}
      </p>
    </div>
  );
};

export default PlatformHeader;
