import React, { useState, useContext } from 'react';
import AppContext from 'context/Context';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

const DisplaySettings = () => {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  return (
    <>
      <h3>Display Settings</h3>
      <Form.Check
        type="switch"
        id="themeToggleSwitch"
        label="Dark mode"
        onChange={e => setConfig('isDark', e.target.checked)}
        checked={isDark}
      />
    </>
  );
};

export default DisplaySettings;
