import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import {
  faArrowRightFromBracket,
  faGear,
  faDesktop,
  faUser,
  faCircleQuestion,
  faLock
} from '@fortawesome/free-solid-svg-icons';

const ProfileDropdown = () => {
  const navigate = useNavigate();

  return (
    <>
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#"
          className="pe-0 ps-2 nav-link"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faUser} style={{ paddingRight: 8 }} />
          <p style={{ marginBottom: 0 }}>
            {localStorage.getItem('fname') +
              ' ' +
              localStorage.getItem('lname') +
              ' '}
            <FontAwesomeIcon icon={faAngleDown} />
          </p>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            {localStorage.getItem('PMLAdmin') === 'true' &&
              window.location.pathname === '/portals' && (
                <Dropdown.Item
                  onClick={() => {
                    navigate('/pmladmin/dashboard/currencyr8');
                  }}
                >
                  Admin
                  <FontAwesomeIcon
                    icon={faLock}
                    className="me-1"
                    style={{ float: 'right' }}
                  />
                </Dropdown.Item>
              )}
            <Dropdown.Item
              onClick={() => {
                localStorage.removeItem('portal');
                navigate('/portals');
              }}
            >
              Portals
              <FontAwesomeIcon
                icon={faDesktop}
                className="me-1"
                style={{ float: 'right' }}
              />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                window.open(`${process.env.REACT_APP_WWW_URL}/help`, '_blank');
              }}
            >
              Help
              <FontAwesomeIcon
                icon={faCircleQuestion}
                className="me-1"
                style={{ float: 'right' }}
              />
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/profilesettings">
              Profile
              <FontAwesomeIcon
                icon={faUser}
                className="me-1"
                style={{ float: 'right' }}
              />
            </Dropdown.Item>
            {localStorage.getItem('portal') !== null &&
              localStorage.getItem('admin') == 'true' && (
                <Dropdown.Item as={Link} to="/portalsettings">
                  Settings
                  <FontAwesomeIcon
                    icon={faGear}
                    className="me-1"
                    style={{ float: 'right' }}
                  />
                </Dropdown.Item>
              )}
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => {
                localStorage.removeItem('portal');
                axios.post('/logout');
                navigate('/login');
              }}
            >
              Logout
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className="me-1"
                style={{ float: 'right' }}
              />
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;
