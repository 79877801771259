import loading from 'assets/animations/loading.json';
import axios from 'axios';
import PageTitle from 'components/common/PageTitle';
import Scheduler from 'components/currencyr8/Scheduler';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { Button, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { set } from 'react-hook-form';

const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZone: 'UTC'
};

const FXScheduler = props => {
  const [currencies, setCurrencies] = useState([]);
  const [primaryCurrency, setPrimaryCurrency] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [showUpdateButton, setShowUpdateButton] = useState(true);
  const [httpStatus, setHttpStatus] = useState(0);
  const [accountType, setAccountType] = useState(null);
  const [schedulerTime, setSchedulerTime] = useState(null);
  const [schedulerEnabled, setSchedulerEnabled] = useState(null);
  const [disabledCurrencies, setDisabledCurrencies] = useState([]);
  const [originalEnabledStatus, setOriginalEnabledStatus] = useState(null);
  const [showDisabledCurrenciesWarning, setShowDisabledCurrenciesWarning] =
    useState(false);
  const [companyHubspotID, setCompanyHubspotID] = useState(null);
  const [schedulerData, setSchedulerData] = useState(null);

  // const activeBetas = JSON.parse(localStorage.getItem('betas') || '[]');
  // if (
  //   activeBetas.filter(b => b.name == 'Scheduler' && b.app == 'CurrencyR8')
  //     .length == 0
  // ) {
  //   return '';
  // }

  useEffect(() => {
    onPageLoad();
    listCurrencies();
  }, []);

  // Toggle all disabled on scheduler enable/disable
  useEffect(() => {
    if (originalEnabledStatus === null) {
      setOriginalEnabledStatus(schedulerEnabled);
      return;
    }
    if (
      schedulerEnabled &&
      currencies.length - 1 - disabledCurrencies.length == 1
    ) {
      return;
    } else if (schedulerEnabled) {
      setDisabledCurrencies([]);
    } else {
      setDisabledCurrencies(
        currencies
          .map(c => c.fromCurrencyCode)
          .filter(c => c !== primaryCurrency)
      );
    }
  }, [schedulerEnabled]);

  const onPageLoad = () => {
    axios.post(`/currencyr8/getaccounttype`).then(res => {
      setAccountType(res.data);
    });
  };

  const showModal = () => {
    setModalText(
      <p>
        Are you sure you want to update your currencies? This will update all
        selected currencies in your HubSpot portal immediately with the latest
        rates and cost {currencies.length - 1 - disabledCurrencies.length}{' '}
        credits.
      </p>
    );
    setShowUpdateButton(true);
    setModalShow(true);
  };

  const listCurrencies = () => {
    axios
      .post('/currencyr8/scheduler/listcurrencies/current')
      .then(res => {
        setHttpStatus(200);
        setCurrencies(res.data.currencies);
        setPrimaryCurrency(res.data.primaryCurrency);
        setCompanyHubspotID(res.data.companyHubspotID);
      })
      .catch(err => {
        setHttpStatus(err.response.data.error);
      });
  };

  const updateCurrencies = () => {
    setModalText(
      <Lottie
        animationData={loading}
        loop
        autoplay
        style={{ width: '200px', height: '200px', margin: 'auto' }}
      />
    );
    setShowUpdateButton(false);

    axios
      .post('/currencyr8/scheduler/updatecurrencies')
      .then(res => {
        setModalText(
          <>
            <h4>Currencies updated successfully.</h4>
            <a
              href="https://app.hubspot.com/settings/1986582/account-defaults/multicurrency"
              target="_blank"
              rel="noreferrer"
              className="text-red"
            >
              View in Hubspot.
            </a>
          </>
        );
        listCurrencies();
        setModalText(
          <p>Currency rates have been updated in your HubSpot portal.</p>
        );
      })
      .catch(err => {
        console.log(err.response.status);
        if (err.response.status === 401) {
          setModalText(
            <p>
              You do not have enough credits to complete this action. Please
              upgrade your Currency-R8 account to continue.
            </p>
          );
        } else {
          setModalText(<p>Something went wrong.</p>);
        }
      });
  };

  const formatTime = () => {
    if (schedulerTime) {
      let now = new Date();
      let schedule = new Date(schedulerTime);

      let nextSchedule;
      if (schedule < now) {
        nextSchedule = new Date(schedulerTime + 86400000);
      } else {
        nextSchedule = schedule;
      }

      let diff = nextSchedule - now;

      const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

      let hours = Math.floor(diff / 3.6e6);
      let minutes = Math.floor(diff / 60000);

      if (minutes > 60) {
        return formatter.format(hours, 'hours');
      } else {
        return formatter.format(minutes, 'minutes');
      }
    }
  };

  const calculateNextUpdate = schedulerData => {
    let now = new Date();
    let nextUpdate;

    let updateFrequency = schedulerData.UpdateFrequency;
    let time = { hour: schedulerData.Hour, minute: schedulerData.Minute };
    let updateDay = schedulerData.UpdateDay;
    switch (updateFrequency) {
      case 1: //daily
        nextUpdate = null;
        break;
      case 2: //weekly
        let daysUntilNextChosenDay = (updateDay - now.getUTCDay() + 7) % 7; //eslint-disable-line
        if (
          new Date(0, 0, 0, now.getUTCHours(), now.getUTCMinutes()) >=
            new Date(0, 0, 0, time.hour, time.minute) &&
          updateDay == now.getUTCDay()
        ) {
          daysUntilNextChosenDay += 7;
        }
        nextUpdate = new Date(now);
        nextUpdate.setDate(now.getUTCDate() + daysUntilNextChosenDay);
        nextUpdate.setUTCHours(time.hour, time.minute, 0, 0);
        break;
      case 3:
        const currentDay = now.getUTCDate(); //eslint-disable-line
        const currentMonth = now.getUTCMonth(); //eslint-disable-line
        const currentYear = now.getUTCFullYear(); //eslint-disable-line

        let targetMonth = currentMonth; //eslint-disable-line
        if (updateDay > currentDay) {
          targetMonth = currentMonth;
        } else if (updateDay < currentDay) {
          targetMonth = currentMonth + 1;
        } else if (
          updateDay == currentDay &&
          new Date(0, 0, 0, now.getUTCHours(), now.getUTCMinutes()) <
            new Date(0, 0, 0, time.hour, time.minute)
        ) {
          targetMonth = currentMonth;
        } else {
          targetMonth = currentMonth + 1;
        }

        const lastDayOfTargetMonth = new Date( //eslint-disable-line
          currentYear,
          targetMonth + 1,
          0
        ).getDate();

        let targetDay = Math.min(updateDay, lastDayOfTargetMonth); //eslint-disable-line

        nextUpdate = new Date(
          Date.UTC(currentYear, targetMonth, targetDay, time.hour, time.minute)
        );

        break;
    }
    return new Date(nextUpdate).toLocaleString('en-gb', dateOptions);
  };

  const updateDisabledCurrencies = (currency, checked) => {
    if (currencies.length - 1 - disabledCurrencies.length === 1 && !checked) {
      setShowDisabledCurrenciesWarning(true);
      return;
    }
    if (checked) {
      setDisabledCurrencies(disabledCurrencies.filter(i => i !== currency));
    } else {
      setDisabledCurrencies([...disabledCurrencies, currency]);
    }
  };

  switch (httpStatus) {
    case 0:
      return <PageTitle page="Scheduler" app={props.app} />;
    case 'Gated for portal':
      return <RequestAccess app={props.app} />;
    case 'HubSpot API Error':
      return (
        <>
          <PageTitle page="Scheduler" app={props.app} />
          <p>Something went wrong, please try again later.</p>
        </>
      );
    case 'Invalid scopes':
    case 'Invalid Access Token':
      return (
        <>
          <PageTitle page="Scheduler" app={props.app} />
          <p>
            Please update your Currency-R8 app to the latest version to
            continue.
          </p>
          <Button
            variant="danger"
            href={
              'https://app-eu1.hubspot.com/oauth/authorize?client_id=9199c45e-8028-4e61-832e-48f52b80ee7f&redirect_uri=https://app.pacificmarketinglabs.io/oauth-callback/currencyr8&scope=settings.currencies.read%20settings.currencies.write%20oauth%20crm.objects.deals.read%20crm.objects.deals.write%20crm.objects.line_items.read%20crm.objects.line_items.write'
            }
          >
            {' '}
            Update To Latest Version{' '}
          </Button>
        </>
      );
    case 'ONE-CURRENCY':
      return (
        <>
          <PageTitle page="Scheduler" app={props.app} />
          <p>
            Currency-R8 Scheduler requires at least two currencies enabled in
            your HubSpot portal. Once added, this screen will update with the
            new Scheduler settings.
          </p>
          <p>
            If you need help adding new currencies, please either review this{' '}
            <a
              className="text-red"
              target="_blank"
              rel="noreferrer"
              href="https://knowledge.hubspot.com/object-settings/add-and-edit-your-account-currencies"
            >
              HubSpot knowledge base article
            </a>{' '}
            or{' '}
            <a
              className="text-red"
              target="_blank"
              rel="noreferrer"
              href="https://www.pacificmarketinglabs.io/contact"
            >
              contact us
            </a>{' '}
            via our support desk.
          </p>
        </>
      );
  }

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Update Currency Rates Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>
            {showUpdateButton ? 'Cancel' : 'Close'}
          </Button>
          <Button
            variant="danger"
            onClick={() => updateCurrencies()}
            hidden={!showUpdateButton}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDisabledCurrenciesWarning}
        onHide={() => setShowDisabledCurrenciesWarning(false)}
        size="lg"
        centered
      >
        {/* <Modal.Header>
          <Modal.Title>Update Currency Rates Now</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <p style={{ fontSize: 18 }}>
            You must have at least one currency enabled while the Scheduler is
            active.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => setShowDisabledCurrenciesWarning(false)}
          >
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <PageTitle page="Scheduler" app={props.app} />
      <Scheduler
        currencies={currencies}
        primaryCurrency={primaryCurrency}
        showModal={() => showModal()}
        accountType={accountType}
        setSchedulerTime={setSchedulerTime}
        setSchedulerEnabled={setSchedulerEnabled}
        setDisabledCurrencies={setDisabledCurrencies}
        disabledCurrencies={disabledCurrencies}
        companyHubspotID={companyHubspotID}
        schedulerData={schedulerData}
        setSchedulerData={setSchedulerData}
        style={{ display: schedulerData ? 'block' : 'none' }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: 'fit-content',
          marginBottom: '24px'
        }}
      ></div>
      {currencies.length > 0 && schedulerData && (
        <div>
          <h3 style={{ marginBottom: '1rem' }}>
            Current Global Exchange Rates In Your HubSpot Portal
          </h3>
          <p>
            {!originalEnabledStatus &&
              'Scheduler is not currently enabled, please enable it using the settings above. '}
            {originalEnabledStatus &&
              schedulerData.UpdateFrequency === 1 &&
              `The following selected currencies will next be updated in ${formatTime()}. `}
            {originalEnabledStatus &&
              schedulerData.UpdateFrequency !== 1 &&
              `The following selected currencies will next be updated on the ${calculateNextUpdate(
                schedulerData
              )}. `}
            If you would like to update these currencies right now with the
            latest rates,{' '}
            {accountType === 'free' ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="overlay-trigger-example">
                    Upgrade to a paid Currency-R8 plan to use this feature.
                  </Tooltip>
                }
              >
                <a className="text-red" style={{ cursor: 'pointer' }}>
                  click here
                </a>
              </OverlayTrigger>
            ) : (
              <a
                onClick={showModal}
                className="text-red"
                style={{ cursor: 'pointer' }}
              >
                click here
              </a>
            )}
            .
          </p>
          <Table responsive hover>
            <thead>
              <tr>
                <td>Currency</td>
                <td style={{ textAlign: 'center' }}>
                  Include in Scheduled Update
                </td>
                <td>Current Rate in your HubSpot Portal</td>
                <td>Last Updated in your HubSpot Portal</td>
              </tr>
            </thead>
            <tbody>
              {currencies
                .filter(i => i.fromCurrencyCode !== primaryCurrency)
                .map((currency, idx) => (
                  <tr key={idx}>
                    <td>{currency.fromCurrencyCode}</td>
                    <td style={{ textAlign: 'center' }}>
                      <input
                        type="checkbox"
                        checked={
                          !disabledCurrencies.includes(
                            currency.fromCurrencyCode
                          )
                        }
                        onChange={e => {
                          updateDisabledCurrencies(
                            currency.fromCurrencyCode,
                            e.target.checked
                          );
                        }}
                        className="form-check-input"
                      />
                    </td>
                    <td>
                      {currency.conversionRate} {primaryCurrency}
                    </td>
                    <td>
                      {new Date(currency.updatedAt).toLocaleString(
                        'en-gb',
                        dateOptions
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

const RequestAccess = props => {
  const [requested, setRequested] = useState(false);

  useEffect(() => {
    let betas = JSON.parse(localStorage.getItem('betas'));
    if (betas.filter(i => i.name === 'Scheduler')[0]?.enabled) {
      setRequested(true);
    }
  }, []);

  const requestAccess = async () => {
    await axios.post('/currencyr8/scheduler/request-access', {
      betas: [{ name: 'Scheduler', app: 'CurrencyR8', enabled: true }]
    });
    let betas = JSON.parse(localStorage.getItem('betas'));
    localStorage.setItem(
      'betas',
      JSON.stringify([
        ...betas,
        { name: 'Scheduler', app: 'CurrencyR8', enabled: true }
      ])
    );
    setRequested(true);
  };

  return (
    <>
      <PageTitle page="Scheduler" app={props.app} />
      {requested ? (
        <>
          <p>
            You have requested access to scheduler. Please allow up to 2-3
            working days.
          </p>
          <Button
            variant="danger"
            disabled
            style={{ marginBottom: 32 }}
            onClick={requestAccess}
          >
            Request Access
          </Button>
        </>
      ) : (
        <>
          <p>
            Scheduler is currently in beta. Watch the video below to learn more
            about it, or press the button below to request access.
          </p>
          <Button
            variant="danger"
            style={{ marginBottom: 32 }}
            onClick={requestAccess}
          >
            Request Access
          </Button>
        </>
      )}

      <div
        style={{
          position: 'relative',
          paddingBottom: 'min(56.25%, 450px)',
          width: 'min(100%, 800px)'
        }}
      >
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '4px'
          }}
          src="https://www.youtube.com/embed/yVFh0qLTA2s"
          title="Pacific Marketing Labs: Currency-R8 Scheduler"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
};

export default FXScheduler;
