import React, { useContext } from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const TopNavRightSideNavItem = props => {
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const navigate = useNavigate();
  1;

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <ProfileDropdown />
    </Nav>
  );
};

TopNavRightSideNavItem.propTypes = {
  simple: PropTypes.bool
};

export default TopNavRightSideNavItem;
