import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          &copy; <br className="d-sm-none" /> {new Date().getFullYear()} Pacific
          Marketing Labs Ltd. All rights reserved{' | '}
          {/* <a
            href="https://www.pacificmarketing.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            Pacific Marketing LTD
          </a>
          {' | '} */}
          <a
            href={`https://www.pacificmarketinglabs.io/Terms-Conditions-Pacific-Marketing-Labs-Jan-23.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            Terms &amp; Conditions
          </a>
          {' | '}
          <a
            href="https://www.pacificmarketinglabs.io/Privacy-Policy-Pacific-Marketing-Labs-Jan-23.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy{' '}
          </a>
        </p>
      </Col>
      <Col sm="auto">{/* <p className="mb-0 text-600">v{version}</p> */}</Col>
    </Row>
  </footer>
);

export default Footer;
