import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import axios from 'axios';
import Loading from 'components/common/Loading';
import { textAppNames } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

const UpgradeComplete = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const [searchParams, setSearchParams] = useSearchParams();

  const [paymentStatus, setPaymentStatus] = useState('');

  useEffect(() => {
    if (stripe !== null) {
      checkStatus();
    }
  }, [stripe]);

  useEffect(() => {
    if (paymentStatus === 'succeeded') {
      axios.post('/subscriptions/setcheckoutcomplete', {
        app: searchParams.get('app')
      });
    }
  }, [paymentStatus]);

  const checkStatus = async () => {
    const setupIntentSecret = searchParams.get('setup_intent_client_secret');

    if (setupIntentSecret === null) {
      setPaymentStatus('succeeded');
    } else {
      stripe.retrieveSetupIntent(setupIntentSecret).then(({ setupIntent }) => {
        switch (setupIntent.status) {
          case 'succeeded':
            upgradeSubscription(setupIntent.payment_method);
            break;
          default:
            setPaymentStatus(setupIntent.status);
        }
      });
    }
  };

  const upgradeSubscription = async paymentMethod => {
    await axios.post('/stripe/upgrade-subscription', {
      subscriptionID: searchParams.get('subscriptionID'),
      priceID: searchParams.get('priceID'),
      unitPriceID: searchParams.get('unitPriceID'),
      addons: searchParams.get('addons'),
      prorate: searchParams.get('prorate'),
      paymentMethod: paymentMethod
    });

    setTimeout(() => setPaymentStatus('succeeded'), 3000)``;
  };

  if (paymentStatus === 'succeeded') {
    const app = searchParams.get('app');
    const tier = searchParams.get('tier');
    return (
      <>
        <div>
          <div className="p-32">
            <h3 className="pb-2">Plan Updated</h3>
            <Card.Text className="pb-2">
              Thank you, your {textAppNames[app]} plan has been updated to{' '}
              {tier} tier.
            </Card.Text>
            <Button
              onClick={() => {
                navigate(`/${searchParams.get('app')}/dashboard`);
              }}
              variant="danger"
            >
              Continue to Dashboard
            </Button>
          </div>
        </div>
      </>
    );
  }

  if (paymentStatus === 'processing') {
    return (
      <>
        <div>
          <div className="p-32">
            <h3 className="pb-2">Payment Processing</h3>
            <Card.Text className="pb-2">
              Your payment is being processed. Please wait a moment.
            </Card.Text>
          </div>
        </div>
      </>
    );
  }

  if (paymentStatus === 'requires_payment_method') {
    return (
      <>
        <div>
          <div className="p-32">
            <h3 className="pb-2">Payment Failed</h3>
            <Card.Text className="pb-2">
              Your payment failed. Please try again.
            </Card.Text>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="danger"
            >
              Back to Checkout
            </Button>
          </div>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

const UpgradeCompleteWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <UpgradeComplete />
    </Elements>
  );
};

export default UpgradeCompleteWrapper;
