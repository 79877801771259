import logo from 'assets/img/logo.png';
import axios from 'axios';
import NavbarTop from 'components/navbar/top/NavbarTop';
import { countryData } from 'data/countryData';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NewPortal = () => {
  const navigate = useNavigate();
  const [setupStep, setSetupStep] = useState(1);

  const [platformType, setPlatformType] = useState('hubspot');
  const [portalName, setPortalName] = useState('');
  const [portalNameIsEmpty, setPortalNameIsEmpty] = useState(false);
  const [portalID, setPortalID] = useState('');
  const [portalIDIsEmpty, setPortalIDIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [portalUsed, setPortalUsed] = useState(false);
  const [sharpspringAPI, setSharpspringAPI] = useState('');
  const [sharpspringAPIIsEmpty, setSharpspringAPIIsEmpty] = useState(false);

  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressCity, setAddressCity] = useState('');
  const [addressCounty, setAddressCounty] = useState('');
  const [addressCountry, setAddressCountry] = useState('GB');
  const [addressPostcode, setAddressPostcode] = useState('');
  const [vatCode, setVatCode] = useState('');
  const [currency, setCurrency] = useState('GBP');

  const [addressLine1IsEmpty, setAddressLine1IsEmpty] = useState(false);
  const [vatCodeIsInvalid, setVatCodeIsEmpty] = useState(false);

  const vatCodeRegex = new RegExp(
    /^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/
  );

  useEffect(() => {
    axios.post('/auth/verify-token').catch(err => navigate('/login'));
  }, []);

  const step1Submit = async e => {
    e.preventDefault();
    setLoading(true);
    setPortalNameIsEmpty(false);
    setPortalIDIsEmpty(false);
    setPortalUsed(false);
    setSharpspringAPIIsEmpty(false);
    if (portalName === '') {
      setPortalNameIsEmpty(true);
    }
    if (portalID === '') {
      setPortalIDIsEmpty(true);
    }

    if (platformType == 'sharpspring' && sharpspringAPI === '') {
      setSharpspringAPIIsEmpty(true);
    }

    if (
      portalID === '' ||
      portalName === '' ||
      (platformType == 'sharpspring' && sharpspringAPI === '')
    ) {
      setLoading(false);
      return;
    }

    const isPortalUsed = await axios
      .post(process.env.REACT_APP_BASE_URL + '/is-portalid-used', {
        portalID: portalID
      })
      .then(res => {
        return res.data;
      });

    if (isPortalUsed) {
      setPortalUsed(true);
      setLoading(false);
    } else {
      setLoading(false);
      setSetupStep(2);
    }
  };

  const step2Submit = async e => {
    e.preventDefault();
    setLoading(true);
    if (addressLine1 === '') {
      setAddressLine1IsEmpty(true);
      setLoading(false);
      return;
    }
    setVatCode(vatCode.toUpperCase());
    if (vatCode != '' && !vatCodeRegex.test(vatCode.toUpperCase())) {
      setVatCodeIsEmpty(true);
      setLoading(false);
      return;
    }

    await axios
      .post('/create-portal', {
        portalName: portalName,
        portalID: portalID,
        platformType: platformType,
        sharpspringAPI: sharpspringAPI,
        address: {
          line1: addressLine1,
          line2: addressLine2,
          city: addressCity,
          county: addressCounty,
          country: addressCountry,
          postcode: addressPostcode
        },
        vatCode: vatCode,
        currency: currency,
        email: localStorage.getItem('email')
      })
      .then(res => {
        localStorage.setItem('currencyName', res.data.name);
        localStorage.setItem('currencySign', res.data.sign);
        localStorage.setItem('country', res.data.country);
        localStorage.setItem('platform', res.data.platform);
        localStorage.setItem('admin', res.data.admin);
      });

    let data = JSON.stringify({
      name: portalName
    });

    localStorage.setItem('installedApps', '[]');
    localStorage.setItem('portal', data);

    navigate('/install');
  };

  if (setupStep === 1) {
    return (
      <>
        <NavbarTop simple={true} />
        <div
          style={{
            display: 'flex',
            height: '100vh',
            backgroundColor: 'transparent'
          }}
        >
          <div className="horizontalCenter" style={{ margin: 'auto auto' }}>
            <div className="new-portal-form-container">
              <h2 style={{ paddingBottom: 16 }} className="boldHeader">
                CONNECT NEW <span className="highlightedHeader">PORTAL</span>
              </h2>
              {portalUsed ? (
                <Alert
                  variant="danger"
                  onClose={() => setPortalUsed(false)}
                  dismissible
                >
                  <p className="m-0" style={{ maxWidth: 500 }}>
                    Sorry this portal ID is already in use. Please contact your
                    HubSpot portal administrator or{' '}
                    <a
                      className="text-red"
                      href={process.env.REACT_APP_SUPPORT_FORM}
                      target="_blank"
                      rel="noreferrer"
                    >
                      log a support ticket with us
                    </a>
                    .
                  </p>
                </Alert>
              ) : (
                ''
              )}
              <Form.Group className="mb-3">
                <Form.Label>Platform</Form.Label>
                <Form.Select
                  value={platformType}
                  onChange={e => setPlatformType(e.target.value)}
                >
                  <option value="hubspot">HubSpot</option>
                  {/* <option value="sharpspring">SharpSpring</option> */}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  value={portalName}
                  onChange={e => {
                    setPortalName(e.target.value);
                  }}
                  isInvalid={portalNameIsEmpty}
                />
                <Form.Control.Feedback type="invalid">
                  Company Name cannot be empty.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  {platformType == 'hubspot'
                    ? 'HubSpot Portal ID'
                    : 'SharpSpring Account ID'}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={portalID}
                  onChange={e => {
                    setPortalID(e.target.value);
                  }}
                  isInvalid={portalIDIsEmpty}
                />
                <Form.Control.Feedback type="invalid">
                  {platformType == 'hubspot'
                    ? 'HubSpot Portal ID'
                    : 'SharpSpring Account ID'}{' '}
                  cannot be empty.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                hidden={platformType == 'sharpspring' ? false : true}
              >
                <Form.Label>SharpSpring API Key</Form.Label>
                <Form.Control
                  type="text"
                  value={sharpspringAPI}
                  onChange={e => {
                    setSharpspringAPI(e.target.value);
                  }}
                  isInvalid={sharpspringAPIIsEmpty}
                />
                <Form.Control.Feedback type="invalid">
                  SharpSpring API cannot be empty.
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                onClick={e => {
                  step1Submit(e);
                }}
                disabled={loading}
                variant="danger"
                style={{
                  marginTop: 16
                }}
                className="btn-block"
              >
                {loading ? 'LOADING' : 'NEXT'}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else if (setupStep === 2) {
    return (
      <>
        <NavbarTop simple={true} />
        <div
          style={{
            display: 'flex',
            height: '100vh',
            backgroundColor: 'transparent'
          }}
        >
          <div className="horizontalCenter" style={{ margin: 'auto auto' }}>
            <div className="new-portal-form-container">
              <h2 className="boldHeader">
                <span className="highlightedHeader">Portal</span> Billing
                Information
              </h2>
              <Form.Group className="mb-2">
                <Form.Label>Address Line 1</Form.Label>
                <Form.Control
                  type="text"
                  value={addressLine1}
                  onChange={e => {
                    setAddressLine1(e.target.value);
                  }}
                  isInvalid={addressLine1IsEmpty}
                  size="sm"
                />
                <Form.Control.Feedback type="invalid">
                  Address Line 1 cannot be empty.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  value={addressLine2}
                  onChange={e => {
                    setAddressLine2(e.target.value);
                  }}
                  size="sm"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  value={addressCity}
                  onChange={e => {
                    setAddressCity(e.target.value);
                  }}
                  size="sm"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>County/State</Form.Label>
                <Form.Control
                  type="text"
                  value={addressCounty}
                  onChange={e => {
                    setAddressCounty(e.target.value);
                  }}
                  size="sm"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Post/Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  value={addressPostcode}
                  onChange={e => {
                    setAddressPostcode(e.target.value);
                  }}
                  size="sm"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  value={addressCountry}
                  onChange={e => {
                    setAddressCountry(e.target.value);
                    e.target.value == 'GB' ? setCurrency('GBP') : '';
                  }}
                  size="sm"
                >
                  {countryData.map(country => {
                    return (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Currency</Form.Label>
                <Form.Select
                  value={currency}
                  onChange={e => setCurrency(e.target.value)}
                  disabled={addressCountry == 'GB' ? true : false}
                >
                  <option value="GBP">£ GBP</option>
                  <option value="USD">$ USD</option>
                  <option value="EUR">€ EUR</option>
                </Form.Select>
              </Form.Group>
              {addressCountry === 'GB' && (
                <Form.Group className="mb-2">
                  <Form.Label>VAT Number (Optional)</Form.Label>
                  <Form.Control
                    type="text"
                    value={vatCode}
                    onChange={e => {
                      setVatCode(e.target.value);
                    }}
                    isInvalid={vatCodeIsInvalid}
                    size="sm"
                  />
                  <Form.Control.Feedback type="invalid">
                    Invalid VAT Number.
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Button
                onClick={e => {
                  step2Submit(e);
                }}
                disabled={loading}
                variant="danger"
                className="btn-block"
                style={{ marginTop: 16 }}
              >
                {loading ? 'LOADING' : 'CREATE PORTAL'}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default NewPortal;
