import React from 'react';
import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap';
import axios from 'axios';

const PaymentSettings = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [searchParams, setSearchParams] = useSearchParams();

  const intentClientSecret = searchParams.get('setup_intent');

  const updatePaymentMethod = async () => {
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/portalsettings/payment`
      }
    });

    if (error) {
      console.log(error.message);
    }
  };

  if (intentClientSecret == null) {
    return (
      <>
        <h3>Manage your payment method</h3>
        <PaymentElement />
        <Button
          onClick={() => {
            updatePaymentMethod();
          }}
          variant="danger"
          className="btn-block"
          style={{ marginTop: 32 }}
        >
          Update Payment Method
        </Button>
      </>
    );
  } else if (intentClientSecret !== null) {
    axios.post('/stripe/update-payment-method', {
      clientSecret: intentClientSecret
    });

    return (
      <Alert variant="success" style={{ marginBottom: 0 }}>
        Payment Method Successfully Updated
      </Alert>
    );
  }
};

export default PaymentSettings;
