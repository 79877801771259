import React, { useContext } from 'react';
import AppContext from 'context/Context';
import AnimateCC from 'react-adobe-animate';
import logo from 'assets/img/logo.png';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);

  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: isDark ? '#2E4450' : '#FDFDFD'
        //   backgroundImage:
        //     'url(/static/media/Background-2-White.786c95585ee8ef6c1a85.png)',
        //   backgroundSize: 'cover'
      }}
    >
      <div
        style={{
          position: 'absolute',
          justifyContent: 'center',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{ width: 300, alignSelf: 'center' }}>
          {isDark ? (
            <AnimateCC animationName="LabsLogoWhite" paused={false} />
          ) : (
            <AnimateCC animationName="LabsLogoBlue" paused={false} />
          )}
        </div>

        <p>
          For more information please visit{' '}
          <a className="text-red" href={process.env.REACT_APP_BASE_URL}>
            {process.env.REACT_APP_BASE_URL.split('https://')[1]}
            {process.env.REACT_APP_BASE_URL.split('http://')[1]}
          </a>
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
