import React from 'react';
import { Card, Table } from 'react-bootstrap';
import Loading from 'components/common/Loading';
import { textAppNames } from 'helpers/utils';

const UsageTable = props => {
  let { accounts, app } = props;

  if (accounts == null) {
    return (
      <Card>
        <Card.Body style={{ paddingTop: 0 }}>
          <div className="chart-header">
            <h2>{textAppNames[app]} Current Usage</h2>
          </div>
          <Loading />
        </Card.Body>
      </Card>
    );
  }

  const sortedAccounts = accounts.sort(
    (a, b) =>
      a.MaxRequests - a.CurrentRequests - (b.MaxRequests - b.CurrentRequests)
  );

  return (
    <Card>
      <Card.Body style={{ paddingTop: 0 }}>
        <div className="chart-header">
          <h2>{textAppNames[app]} Current Usage</h2>
        </div>
        <div style={{ height: 'calc(100% - 64px)' }}>
          <Table>
            <thead
              style={{ position: 'sticky', top: 0, background: '#F1F2F6' }}
            >
              <tr>
                <th>Customer Name</th>
                <th>Product Name</th>
                <th>Credits Purchased</th>
                <th>Credits Used</th>
                <th>Credits Available</th>
              </tr>
            </thead>
            <tbody>
              {sortedAccounts &&
                sortedAccounts.map(a => {
                  return (
                    <tr key={a.AccountID}>
                      <td>{a.AccountName}</td>
                      <td>{a.ProductName}</td>
                      <td>{a.MaxRequests}</td>
                      <td>{a.CurrentRequests}</td>
                      <td>{a.MaxRequests - a.CurrentRequests}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default UsageTable;
