import React from 'react';
import Loading from 'components/common/Loading';
import { Card } from 'react-bootstrap';

const BigNumber = ({ title, number, onClick }) => {
  if (number === undefined) {
    return (
      <Card style={{ height: '100%' }}>
        <Card.Body style={{ paddingTop: 0 }}>
          <div className="chart-header">
            <h2>{title}</h2>
          </div>
          <Loading />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card
      style={{ height: '100%', cursor: onClick ? 'pointer' : 'unset' }}
      className="reporting-big-number"
      onClick={onClick ? onClick : null}
    >
      <Card.Body>
        <div className="chart-header">
          <h2>{title}</h2>
        </div>
        <p className="reporting-big-number--number">{number}</p>
      </Card.Body>
    </Card>
  );
};

export default BigNumber;
