import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Alert, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import AppContext from 'context/Context';
import AnimateCC from 'react-adobe-animate';
import PropTypes from 'prop-types';

const OTPCheck = props => {
  const navigate = useNavigate();

  const [otpIsWrong, setOTPisWrong] = useState(false);
  const [otp, setOTP] = useState('');
  const [otpIsInvalid, setOTPIsInvalid] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);

  useEffect(() => {
    if (props.resend == true) {
      resendOTP();
    }
  }, [props]);

  const resendOTP = async () => {
    setLoading(true);
    setOTP('');
    axios.post('/resend-otp', { email: props.email }).then(() => {
      setOTPisWrong(false);
      setLoading(false);
    });
  };

  const onOTPSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setOTPIsInvalid(false);

    if (otp === '') {
      setOTPIsInvalid(true);
      setLoading(false);
      return;
    }

    let data = {
      email: props.email,
      otp: otp
    };

    let response = await axios
      .post('/check-otp', data)
      .then(res => {
        return res.data;
      })
      .catch(err => console.log(err.data));

    if (response.result == true) {
      axios
        .post('/auth/set-user-token', { email: props.email })
        .then(() => navigate('/portals'));
    } else {
      setLoading(false);
      setOTPisWrong(true);
    }
  };

  return (
    <>
      <div className="centerDiv">
        <div className="centerDivLogo" style={{ width: 150, height: 150 }}>
          {isDark ? (
            <AnimateCC animationName="LabsLogoWhite" paused={false} />
          ) : (
            <AnimateCC animationName="LabsLogoBlue" paused={false} />
          )}
        </div>
        <Card className="p-32" style={{ maxWidth: 750 }}>
          <Card.Body>
            <h3>One-Time Password</h3>
            {otpIsWrong ? (
              <Alert
                variant="danger"
                onClose={() => otpIsWrong(false)}
                dismissible
              >
                <p className="m-0">
                  OTP is incorrect or has expired.{' '}
                  <a
                    onClick={() => {
                      resendOTP();
                    }}
                    style={{
                      cursor: PointerEvent,
                      textDecoration: 'underline'
                    }}
                  >
                    Resend Code
                  </a>
                </p>
              </Alert>
            ) : (
              <Alert variant="success" className="p-2">
                An email has been sent to{' '}
                {props.email.split('@')[0].slice(0, 1) +
                  '***' +
                  props.email.split('@')[0].slice(-1) +
                  '@' +
                  props.email.split('@')[1]}{' '}
                containing a one-time password. If you have not received the
                email, please check your junk folder.
              </Alert>
            )}

            <Form.Group className={'mb-3'}>
              <Form.Label>One-Time Password</Form.Label>
              <Form.Control
                type="text"
                value={otp}
                onChange={e => setOTP(e.target.value)}
                isInvalid={otpIsInvalid}
              />
              <Form.Control.Feedback type="invalid">
                OTP cannot be empty.
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              onClick={e => onOTPSubmit(e)}
              disabled={loading}
              variant="danger"
              className="btn-block"
            >
              {loading ? 'Loading' : 'Submit'}
            </Button>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

OTPCheck.propTypes = {
  email: PropTypes.string,
  resend: PropTypes.bool
};

OTPCheck.defaultTypes = {
  resend: false
};

export default OTPCheck;
