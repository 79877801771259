export const currencies = [
  { sign: '؋', symbol: 'AFN', name: 'Afghan Afghani' },
  { sign: 'Lek', symbol: 'ALL', name: 'Albanian Lek' },
  { sign: '', symbol: 'DZD', name: 'Algerian Dinar' },
  { sign: '', symbol: 'AOA', name: 'Angolan Kwanza' },
  { sign: '$', symbol: 'ARS', name: 'Argentine Peso' },
  { sign: '', symbol: 'AMD', name: 'Armenian Dram' },
  { sign: 'ƒ', symbol: 'AWG', name: 'Aruban Florin' },
  { sign: '$', symbol: 'AUD', name: 'Australian Dollar' },
  { sign: '₼', symbol: 'AZN', name: 'Azerbaijani Manat' },
  { sign: '$', symbol: 'BSD', name: 'Bahamian Dollar' },
  { sign: '', symbol: 'BHD', name: 'Bahraini Dinar' },
  { sign: '', symbol: 'BDT', name: 'Bangladeshi Taka' },
  { sign: '$', symbol: 'BBD', name: 'Barbadian Dollar' },
  // { sign: "", symbol: "BYR", name: "Belarusian Ruble" },
  { sign: 'BZ$', symbol: 'BZD', name: 'Belize Dollar' },
  { sign: '$', symbol: 'BMD', name: 'Bermudan Dollar' },
  { sign: '', symbol: 'BTN', name: 'Bhutanese Ngultrum' },
  { sign: '$b', symbol: 'BOB', name: 'Bolivian Boliviano' },
  {
    sign: 'KM',
    symbol: 'BAM',
    name: 'Bosnia-Herzegovina Convertible Mark'
  },
  { sign: 'P', symbol: 'BWP', name: 'Botswanan Pula' },
  { sign: 'R$', symbol: 'BRL', name: 'Brazilian Real' },
  { sign: '£', symbol: 'GBP', name: 'British Pound Sterling' },
  { sign: '$', symbol: 'BND', name: 'Brunei Dollar' },
  { sign: 'лв', symbol: 'BGN', name: 'Bulgarian Lev' },
  { sign: '', symbol: 'BIF', name: 'Burundian Franc' },
  { sign: '៛', symbol: 'KHR', name: 'Cambodian Riel' },
  { sign: '$', symbol: 'CAD', name: 'Canadian Dollar' },
  { sign: '', symbol: 'CVE', name: 'Cape Verdean Escudo' },
  { sign: '$', symbol: 'KYD', name: 'Cayman Islands Dollar' },
  { sign: '', symbol: 'XOF', name: 'CFA Franc BCEAO' },
  { sign: '', symbol: 'XAF', name: 'CFA Franc BEAC' },
  { sign: '', symbol: 'XPF', name: 'CFP Franc' },
  { sign: '$', symbol: 'CLP', name: 'Chilean Peso' },
  { sign: '', symbol: 'CLF', name: 'Chilean Unit of Account (UF)' },
  { sign: '¥', symbol: 'CNY', name: 'Chinese Yuan' },
  { sign: '$', symbol: 'COP', name: 'Colombian Peso' },
  { sign: '', symbol: 'KMF', name: 'Comorian Franc' },
  { sign: '', symbol: 'CDF', name: 'Congolese Franc' },
  { sign: '₡', symbol: 'CRC', name: 'Costa Rican Colón' },
  { sign: 'kn', symbol: 'HRK', name: 'Croatian Kuna' },
  { sign: '', symbol: 'CUC', name: 'Cuban Convertible Peso' },
  { sign: '₱', symbol: 'CUP', name: 'Cuban Peso' },
  { sign: 'Kč', symbol: 'CZK', name: 'Czech Republic Koruna' },
  { sign: 'kr', symbol: 'DKK', name: 'Danish Krone' },
  { sign: '', symbol: 'DJF', name: 'Djiboutian Franc' },
  { sign: 'RD$', symbol: 'DOP', name: 'Dominican Peso' },
  { sign: '$', symbol: 'XCD', name: 'East Caribbean Dollar' },
  { sign: '£', symbol: 'EGP', name: 'Egyptian Pound' },
  { sign: '', symbol: 'ERN', name: 'Eritrean Nakfa' },
  { sign: '', symbol: 'ETB', name: 'Ethiopian Birr' },
  { sign: '€', symbol: 'EUR', name: 'Euro' },
  { sign: '£', symbol: 'FKP', name: 'Falkland Islands Pound' },
  { sign: '$', symbol: 'FJD', name: 'Fijian Dollar' },
  { sign: '', symbol: 'GMD', name: 'Gambian Dalasi' },
  { sign: '', symbol: 'GEL', name: 'Georgian Lari' },
  { sign: '¢', symbol: 'GHS', name: 'Ghanaian Cedi' },
  { sign: '£', symbol: 'GIP', name: 'Gibraltar Pound' },
  { sign: '', symbol: 'XAU', name: 'Gold (troy ounce)' },
  { sign: 'Q', symbol: 'GTQ', name: 'Guatemalan Quetzal' },
  // { sign: "£", symbol: "GGP", name: "Guernsey Pound" },
  { sign: '', symbol: 'GNF', name: 'Guinean Franc' },
  { sign: '$', symbol: 'GYD', name: 'Guyanaese Dollar' },
  { sign: '', symbol: 'HTG', name: 'Haitian Gourde' },
  { sign: 'L', symbol: 'HNL', name: 'Honduran Lempira' },
  { sign: '$', symbol: 'HKD', name: 'Hong Kong Dollar' },
  { sign: 'Ft', symbol: 'HUF', name: 'Hungarian Forint' },
  { sign: 'kr', symbol: 'ISK', name: 'Icelandic Króna' },
  { sign: '₹', symbol: 'INR', name: 'Indian Rupee' },
  { sign: 'Rp', symbol: 'IDR', name: 'Indonesian Rupiah' },
  { sign: '﷼', symbol: 'IRR', name: 'Iranian Rial' },
  { sign: '', symbol: 'IQD', name: 'Iraqi Dinar' },
  { sign: '₪', symbol: 'ILS', name: 'Israeli New Sheqel' },
  { sign: 'J$', symbol: 'JMD', name: 'Jamaican Dollar' },
  { sign: '¥', symbol: 'JPY', name: 'Japanese Yen' },
  // { sign: "£", symbol: "JEP", name: "Jersey Pound" },
  { sign: '', symbol: 'JOD', name: 'Jordanian Dinar' },
  { sign: 'лв', symbol: 'KZT', name: 'Kazakhstani Tenge' },
  { sign: '', symbol: 'KES', name: 'Kenyan Shilling' },
  { sign: '', symbol: 'KWD', name: 'Kuwaiti Dinar' },
  { sign: 'лв', symbol: 'KGS', name: 'Kyrgystani Som' },
  { sign: '₭', symbol: 'LAK', name: 'Laotian Kip' },
  // { sign: "", symbol: "LVL", name: "Latvian Lats" },
  { sign: '£', symbol: 'LBP', name: 'Lebanese Pound' },
  { sign: '', symbol: 'LSL', name: 'Lesotho Loti' },
  { sign: '$', symbol: 'LRD', name: 'Liberian Dollar' },
  { sign: '', symbol: 'LYD', name: 'Libyan Dinar' },
  // { sign: "", symbol: "LTL", name: "Lithuanian Litas" },
  { sign: '', symbol: 'MOP', name: 'Macanese Pataca' },
  { sign: 'ден', symbol: 'MKD', name: 'Macedonian Denar' },
  { sign: '', symbol: 'MGA', name: 'Malagasy Ariary' },
  { sign: '', symbol: 'MWK', name: 'Malawian Kwacha' },
  { sign: 'RM', symbol: 'MYR', name: 'Malaysian Ringgit' },
  { sign: '', symbol: 'MVR', name: 'Maldivian Rufiyaa' },
  // { sign: "£", symbol: "IMP", name: "Manx pound" },
  // { sign: "", symbol: "MRO", name: "Mauritanian Ouguiya" },
  { sign: '₨', symbol: 'MUR', name: 'Mauritian Rupee' },
  { sign: '$', symbol: 'MXN', name: 'Mexican Peso' },
  { sign: '', symbol: 'MDL', name: 'Moldovan Leu' },
  { sign: '₮', symbol: 'MNT', name: 'Mongolian Tugrik' },
  { sign: '', symbol: 'MAD', name: 'Moroccan Dirham' },
  { sign: 'MT', symbol: 'MZN', name: 'Mozambican Metical' },
  { sign: '', symbol: 'MMK', name: 'Myanma Kyat' },
  { sign: '$', symbol: 'NAD', name: 'Namibian Dollar' },
  { sign: '₨', symbol: 'NPR', name: 'Nepalese Rupee' },
  { sign: 'ƒ', symbol: 'ANG', name: 'Netherlands Antillean Guilder' },
  { sign: 'Br', symbol: 'BYN', name: 'New Belarusian Ruble' },
  { sign: 'NT$', symbol: 'TWD', name: 'New Taiwan Dollar' },
  { sign: '$', symbol: 'NZD', name: 'New Zealand Dollar' },
  { sign: 'C$', symbol: 'NIO', name: 'Nicaraguan Córdoba' },
  { sign: '₦', symbol: 'NGN', name: 'Nigerian Naira' },
  { sign: '₩', symbol: 'KPW', name: 'North Korean Won' },
  { sign: 'kr', symbol: 'NOK', name: 'Norwegian Krone' },
  { sign: '﷼', symbol: 'OMR', name: 'Omani Rial' },
  { sign: '₨', symbol: 'PKR', name: 'Pakistani Rupee' },
  { sign: 'B/.', symbol: 'PAB', name: 'Panamanian Balboa' },
  { sign: '', symbol: 'PGK', name: 'Papua New Guinean Kina' },
  { sign: 'Gs', symbol: 'PYG', name: 'Paraguayan Guarani' },
  { sign: 'S/.', symbol: 'PEN', name: 'Peruvian Nuevo Sol' },
  { sign: '₱', symbol: 'PHP', name: 'Philippine Peso' },
  { sign: 'zł', symbol: 'PLN', name: 'Polish Zloty' },
  { sign: '﷼', symbol: 'QAR', name: 'Qatari Rial' },
  { sign: 'lei', symbol: 'RON', name: 'Romanian Leu' },
  { sign: '₽', symbol: 'RUB', name: 'Russian Ruble' },
  { sign: '', symbol: 'RWF', name: 'Rwandan Franc' },
  { sign: '£', symbol: 'SHP', name: 'Saint Helena Pound' },
  { sign: '$', symbol: 'SVC', name: 'Salvadoran Colón' },
  { sign: '', symbol: 'WST', name: 'Samoan Tala' },
  { sign: '﷼', symbol: 'SAR', name: 'Saudi Riyal' },
  { sign: 'Дин.', symbol: 'RSD', name: 'Serbian Dinar' },
  { sign: '₨', symbol: 'SCR', name: 'Seychellois Rupee' },
  { sign: '', symbol: 'SLL', name: 'Sierra Leonean Leone' },
  { sign: '', symbol: 'XAG', name: 'Silver (troy ounce)' },
  { sign: '$', symbol: 'SGD', name: 'Singapore Dollar' },
  { sign: '$', symbol: 'SBD', name: 'Solomon Islands Dollar' },
  { sign: 'S', symbol: 'SOS', name: 'Somali Shilling' },
  { sign: 'R', symbol: 'ZAR', name: 'South African Rand' },
  { sign: '₩', symbol: 'KRW', name: 'South Korean Won' },
  { sign: '', symbol: 'XDR', name: 'Special Drawing Rights' },
  { sign: '₨', symbol: 'LKR', name: 'Sri Lankan Rupee' },
  { sign: '', symbol: 'SDG', name: 'Sudanese Pound' },
  { sign: '$', symbol: 'SRD', name: 'Surinamese Dollar' },
  { sign: '', symbol: 'SZL', name: 'Swazi Lilangeni' },
  { sign: 'kr', symbol: 'SEK', name: 'Swedish Krona' },
  { sign: 'CHF', symbol: 'CHF', name: 'Swiss Franc' },
  { sign: '£', symbol: 'SYP', name: 'Syrian Pound' },
  // { sign: "", symbol: "STD", name: "São Tomé and Príncipe Dobra" },
  { sign: '', symbol: 'TJS', name: 'Tajikistani Somoni' },
  { sign: '', symbol: 'TZS', name: 'Tanzanian Shilling' },
  { sign: '฿', symbol: 'THB', name: 'Thai Baht' },
  { sign: '', symbol: 'TOP', name: 'Tongan Paʻanga' },
  { sign: 'TT$', symbol: 'TTD', name: 'Trinidad and Tobago Dollar' },
  { sign: '', symbol: 'TND', name: 'Tunisian Dinar' },
  { sign: '₺', symbol: 'TRY', name: 'Turkish Lira' },
  { sign: '', symbol: 'TMT', name: 'Turkmenistani Manat' },
  { sign: '', symbol: 'UGX', name: 'Ugandan Shilling' },
  { sign: '₴', symbol: 'UAH', name: 'Ukrainian Hryvnia' },
  { sign: 'د.إ', symbol: 'AED', name: 'United Arab Emirates Dirham' },
  { sign: '$', symbol: 'USD', name: 'United States Dollar' },
  { sign: '$U', symbol: 'UYU', name: 'Uruguayan Peso' },
  { sign: 'лв', symbol: 'UZS', name: 'Uzbekistan Som' },
  { sign: '', symbol: 'VUV', name: 'Vanuatu Vatu' },
  { sign: 'Bs', symbol: 'VEF', name: 'Venezuelan Bolívar Fuerte' },
  { sign: '₫', symbol: 'VND', name: 'Vietnamese Dong' },
  { sign: '﷼', symbol: 'YER', name: 'Yemeni Rial' },
  { sign: '', symbol: 'ZMW', name: 'Zambian Kwacha' },
  // { sign: "", symbol: "ZMK", name: "Zambian Kwacha (pre-2013)" },
  { sign: '$', symbol: 'ZWL', name: 'Zimbabwean Dollar' }
];
