import axios from 'axios';
import CancellationModal from 'components/billing/CancellationModal';
import CheckoutView from 'components/billing/CheckoutView';
import DowngradeAlert from 'components/billing/DowngradeAlert';
import OverdueSubscription from 'components/billing/OverdueSubscription';
import Plans from 'components/billing/Plans';
import ReactivateSubAlert from 'components/billing/ReactivateSubAlert';
import Subscription from 'components/billing/Subscription';
import TrialAlert from 'components/billing/TrialAlert';
import PageTitle from 'components/common/PageTitle';
import AppContext from 'context/Context';
import { hasAppAccount, textAppNames } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

const Subscriptions = props => {
  const slider = props.slider ?? false;
  const annualPlans = props.annualPlans ?? false;

  // const [trialUsed, setTrialUsed] = useState(null);
  const [tiers, setTiers] = useState([]);
  const [additionalCredits, setAddtionalCredits] = useState([]);
  const [addons, setAddons] = useState([]);
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showPlans, setShowPlans] = useState('monthly');

  const navigate = useNavigate();
  const location = useLocation();

  const {
    config: { showNavbar },
    setConfig
  } = useContext(AppContext);

  const loadData = async () => {
    await axios
      .post('/subscriptions/fetch-products', {
        app: props.app,
        currency: localStorage.getItem('currencyName')
      })
      .then(res => {
        let products = res.data.filter(
          e =>
            (e.Type == 'subscription' ||
              e.Type == 'trial' ||
              e.Type == 'annual-subscription') &&
            e.Hidden == false
        );
        products.sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price));

        // if enterprise deal with no price, move to end of array
        products.forEach(p => {
          if (p.Name == 'Enterprise' && p.Price == 0) {
            let enterprise = p;
            products = products.filter(p => p.Name != 'Enterprise');
            products.push(enterprise);
          }
        });

        setTiers(products);
        setAddons(
          res.data.filter(
            e =>
              e.Type == 'addon' &&
              e.Platform == localStorage.getItem('platform')
          )
        );
      });

    await axios
      .post('/fetch-app-account', {
        app: props.app
      })
      .then(res => {
        setAccount(res.data);
        setLoading(false);
      });

    await axios
      .get(
        `/additional-credits?currency=${localStorage.getItem('currencyName')}`
      )
      .then(res => {
        const filtered = res.data.filter(
          addon => addon.App.toLowerCase() == props.app.toLowerCase()
        );
        setAddtionalCredits(filtered);
      });
  };

  useEffect(() => {
    if (localStorage.getItem('admin') == 'false') {
      navigate('/page-not-found', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (!account || !tiers) return;
    if (account.CurrentPlan != null) {
      const currentPlan = tiers.find(
        tier => tier.ProductID == account.CurrentPlan
      );
      if (!currentPlan) {
        return setShowPlans('monthly');
      }
      if (currentPlan.Type == 'annual-subscription') {
        setShowPlans('annual');
      } else {
        setShowPlans('monthly');
      }
    }
  }, [tiers, account]);

  useEffect(() => {
    if (showCheckout) {
      setConfig('showNavbar', false);
    } else {
      setConfig('showNavbar', true);
    }
  }, [showCheckout]);

  useEffect(() => {
    setTiers([]);
    setAddtionalCredits([]);
    setAddons([]);
    setAccount({});
    setLoading(true);
    setSelectedSubscription({});
    setSelectedAddons([]);
    setShowCheckout(false);
    setShowModal(false);
    setShowPlans('monthly');
    loadData();
  }, [location.pathname]);

  useEffect(() => {
    loadData();
  }, [props]);

  // if (account.SubscriptionStatus === 'overdue') {
  //   return <OverdueSubscription app={props.app} />;
  // } else

  if (!showCheckout && !loading) {
    return (
      <>
        <CancellationModal
          app={props.app}
          account={account}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <PageTitle app={props.app} page={'Plans'} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 'fit-content'
            }}
          >
            <ButtonGroup
              style={{
                height: 'fit-content',
                visibility: annualPlans ? 'visible' : 'hidden'
              }}
            >
              <ToggleButton
                type="radio"
                variant="outline-danger"
                value="annual"
                id="annualToggle"
                checked={showPlans == 'annual'}
                onChange={e => setShowPlans(e.target.value)}
              >
                Annual
              </ToggleButton>
              <ToggleButton
                type="radio"
                variant="outline-danger"
                value="monthly"
                id="monthlyToggle"
                checked={showPlans == 'monthly'}
                onChange={e => setShowPlans(e.target.value)}
              >
                Monthly
              </ToggleButton>
            </ButtonGroup>
          </div>
        </div>

        <ReactivateSubAlert app={props.app} account={account} />
        <DowngradeAlert app={props.app} account={account} />
        <TrialAlert app={props.app} account={account} />
        {account.CurrentPlan == null && (
          <div>
            <h5>
              {textAppNames[props.app]} is now installed on your HubSpot portal.
              To start using {textAppNames[props.app]} please select a plan from
              the choices below.
            </h5>
          </div>
        )}
        <Plans
          addons={addons}
          account={account}
          selectedSubscription={selectedSubscription}
          setSelectedSubscription={setSelectedSubscription}
          selectedAddons={selectedAddons}
          setSelectedAddons={setSelectedAddons}
          setShowCheckout={setShowCheckout}
          setShowModal={setShowModal}
          tiers={
            showPlans == 'annual'
              ? tiers.filter(t => t.Type == 'annual-subscription')
              : tiers.filter(t => t.Type == 'subscription' || t.Type == 'trial')
          }
          additionalCredits={additionalCredits}
          slider={slider}
          subscriptionType={showPlans}
        />
      </>
    );
  } else if (showCheckout) {
    return (
      <CheckoutView
        showCheckout={showCheckout}
        setShowCheckout={setShowCheckout}
        subscription={selectedSubscription}
        addons={selectedAddons}
        account={account ? account : {}} //Otherwise sends string, breaks prop validation
      />
    );
  } else {
    return <PageTitle app={props.app} page={'Plans'} />;
  }
};

Subscriptions.propTypes = {
  app: PropTypes.string
};

export default Subscriptions;
