import axios from 'axios';
import PageTitle from 'components/common/PageTitle';
import React, { useEffect, useState } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

const DONT_COLOR_NAME = ['Ping'];

const InstallApp = () => {
  let urls = [];
  const [installDates, setInstallDates] = useState([]);
  const [extraApps, setExtraApps] = useState([]);

  if (process.env.REACT_APP_NODE_ENV === 'development') {
    urls = [
      {
        name: 'Currency-R8',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=2b2d23c7-56f0-44d5-9024-ca234f4db669&redirect_uri=https://app.dev.pacificmarketinglabs.io/oauth-callback/currencyr8&scope=settings.currencies.read%20settings.currencies.write%20automation%20oauth%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.line_items.read%20crm.objects.line_items.write'
      },
      {
        name: 'Valid8',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=e865b0d8-34e5-4f6b-840c-79715c1fe844&redirect_uri=https://app.dev.pacificmarketinglabs.io/oauth-callback/valid8&scope=automation%20oauth%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.contacts.read%20crm.schemas.contacts.write'
      },
      {
        name: 'Activity-M8',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=0a89e6d6-fca8-425a-94c3-a4fb8646b893&redirect_uri=https://app.dev.pacificmarketinglabs.io/oauth-callback/activitym8&scope=content%20business-intelligence%20sales-email-read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.contacts.read'
      },
      {
        name: 'QR-Gener8',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=8eb1836c-5676-4c9f-bcc2-5912e66976a7&redirect_uri=https://app.dev.pacificmarketinglabs.io/oauth-callback/qrgener8&scope=crm.objects.contacts.read'
      },
      {
        name: 'Badgely-M8',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=30ce9f75-eb15-47a1-a82c-85905920b420&redirect_uri=https://app.dev.pacificmarketinglabs.io/oauth-callback/badgelym8&scope=oauth%20crm.objects.contacts.write'
      },
      {
        name: 'Ping',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=d29fe6f9-0caf-499e-b931-3ea3672827bc&redirect_uri=https://app.dev.pacificmarketinglabs.io/oauth-callback/ping&scope=automation%20oauth%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.contacts.write',
        hidden: !extraApps.includes('ping')
      }
    ];
  } else if (process.env.REACT_APP_NODE_ENV === 'production') {
    urls = [
      {
        name: 'Currency-R8',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=9199c45e-8028-4e61-832e-48f52b80ee7f&redirect_uri=https://app.pacificmarketinglabs.io/oauth-callback/currencyr8&scope=settings.currencies.read%20settings.currencies.write%20oauth%20crm.objects.deals.read%20crm.objects.deals.write%20crm.objects.line_items.read%20crm.objects.line_items.write'
      },
      {
        name: 'Valid8',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=15a46ce8-2426-4035-bdf8-76e6b47955f1&redirect_uri=https://app.pacificmarketinglabs.io/oauth-callback/valid8&scope=automation%20oauth%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.contacts.write'
      },
      {
        name: 'Activity-M8',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=21c1ec5b-cb2c-4bd1-b8a1-a2b13adb8557&redirect_uri=https://app.pacificmarketinglabs.io/oauth-callback/activitym8&scope=content%20business-intelligence%20sales-email-read%20crm.objects.contacts.read'
      },
      // {
      //   name: 'QR-Gener8',
      //   url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=eb3efe18-d321-42b0-a98b-adcd6b682a58&redirect_uri=https://app.pacificmarketinglabs.io/oauth-callback/qrgener8&scope=oauth'
      // }
      // {
      //   name: 'Badgely-M8',
      //   url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=fc3c8f1c-c2e3-4f20-bfd8-588ee6c846e7&redirect_uri=https://app.pacificmarketinglabs.io/oauth-callback/badgelym8&scope=crm.objects.contacts.write'
      // }
      {
        name: 'Ping',
        url: 'https://app-eu1.hubspot.com/oauth/authorize?client_id=60379cf6-812c-4bbc-9c1e-5b3a35f62927&redirect_uri=https://app.pacificmarketinglabs.io/oauth-callback/ping&scope=automation%20oauth%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.contacts.write',
        hidden: !extraApps.includes('ping')
      }
    ];
  }

  useEffect(() => {
    axios.post('/auth/get-installed-apps-dates').then(res => {
      setInstallDates(res.data);
    });
    axios.get('/auth/extra-apps').then(res => setExtraApps(res.data));
  }, []);

  if (installDates.length == 0 && installDates !== '') {
    return '';
  }

  return (
    <>
      <div style={{ margin: 8, paddingBottom: 32 }}>
        <PageTitle app={'Install'} page={'App'} style={{ padding: 0 }} />
        <h5>
          Select the app that you want to install. Please make sure your browser
          is signed into HubSpot before continuing.
        </h5>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {urls.map((app, idx) => {
          const installedApps = JSON.parse(
            localStorage.getItem('installedApps')
          );

          const hidden = app.hidden ?? false;

          if (hidden) {
            return null;
          }

          let envName = `REACT_APP_${app.name.replace('-', '')}_INSTALLDATE`;
          let latestVersion = new Date(process.env[envName]);
          return (
            <div style={{ flexBasis: '33.3%', marginBottom: 16 }} key={idx}>
              <Card style={{ margin: 8, height: '100%' }}>
                <Card.Body>
                  {DONT_COLOR_NAME.includes(app.name) ? (
                    <h1 style={{ paddingBottom: 16 }} className="boldHeader">
                      {app.name}
                    </h1>
                  ) : (
                    <h1 style={{ paddingBottom: 16 }} className="boldHeader">
                      {app.name.slice(0, -1)}
                      <span className="text-red">{app.name.slice(-1)}</span>
                    </h1>
                  )}
                  {/* If install date == null */}
                  {installDates[app.name.replace('-', '')] == null && (
                    <Button
                      className="btn-block"
                      variant="danger"
                      href={app.url}
                    >
                      Install
                    </Button>
                  )}
                  {/* if install is not up to date */}
                  {new Date(installDates[app.name.replace('-', '')]) <
                    latestVersion &&
                    installDates[app.name.replace('-', '')] != null && (
                      <Button
                        className="btn-block"
                        variant="danger"
                        href={app.url}
                      >
                        Update to Latest Version
                      </Button>
                    )}
                  {/* if install is up to date */}
                  {new Date(installDates[app.name.replace('-', '')]) >=
                    latestVersion &&
                    installDates[app.name.replace('-', '')] != null && (
                      <Button
                        className="btn-block"
                        variant="danger"
                        href={app.url}
                        disabled={true}
                      >
                        Latest Version Installed
                      </Button>
                    )}
                  {/* reinstall button */}
                  {installDates[app.name.replace('-', '')] != null && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Use this option if you have deleted the app from your
                          HubSpot portal and need to add it again.
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-block"
                        variant="primary"
                        href={app.url}
                        style={{ marginTop: 16 }}
                      >
                        Re-install App
                      </Button>
                    </OverlayTrigger>
                  )}
                </Card.Body>
              </Card>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default InstallApp;
