import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import loading from 'assets/animations/loading.json';
import axios from 'axios';
import Lottie from 'lottie-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import CurrencyR8Settings from 'components/settings/CurrencyR8Settings';
import InvoiceSettings from 'components/settings/InvoiceSettings';
import PaymentSettings from 'components/settings/PaymentSettings';
import PingSettings from 'components/settings/PingSettings';
import UsersSettings from 'components/settings/UserSettings';
import Valid8Settings from 'components/settings/Valid8Settings';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('payment');
  const [clientSecret, setClientSecret] = useState('');

  const installedApps = useMemo(() => {
    return JSON.parse(localStorage.getItem('installedApps'));
  }, []);

  useEffect(() => {
    if (localStorage.getItem('admin') == 'false') {
      navigate('/page-not-found', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'payment' && clientSecret === '') {
      axios.post('/stripe/setup-intent').then(res => {
        setClientSecret(res.data.clientSecret);
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (location) {
      setActiveTab(location.pathname.split('/portalsettings/')[1]);
    }
  }, [location]);

  return (
    <>
      <h2 className="boldHeader">SETTINGS</h2>

      <Tabs
        id="settingsTab"
        activeKey={activeTab}
        onSelect={t => {
          navigate('/portalsettings/' + t);
        }}
      >
        {/* Payment Tab */}
        <Tab
          eventKey="payment"
          title="Payment"
          className="border-bottom border-x p-3"
        >
          {clientSecret !== '' ? (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret: clientSecret }}
            >
              <PaymentSettings />
            </Elements>
          ) : (
            <Lottie
              animationData={loading}
              loop
              autoplay
              style={{ width: '200px', height: '200px', margin: 'auto' }}
            />
          )}
        </Tab>
        {/* Users Tab */}
        <Tab
          eventKey="users"
          title="Users"
          className="border-bottom border-x p-3"
        >
          <UsersSettings active={activeTab == 'users' ? true : false} />
        </Tab>
        {/* Invoices Tab */}
        <Tab
          eventKey="invoices"
          title="Invoices"
          className="border-bottom border-x p-3"
        >
          <InvoiceSettings active={activeTab == 'invoices' ? true : false} />
        </Tab>
        {/* Valid8 Tab */}
        {installedApps.includes('Valid8') && (
          <Tab
            eventKey="valid8"
            title="Valid8"
            className="border-bottom border-x p-3"
          >
            <Valid8Settings />
          </Tab>
        )}
        {/* Currency-R8 Tab */}
        {installedApps.includes('CurrencyR8') && (
          <Tab
            eventKey="currencyr8"
            title="Currency-R8"
            className="border-bottom border-x p-3"
          >
            <CurrencyR8Settings />
          </Tab>
        )}
        {/* Ping Tab */}
        {installedApps.includes('Ping') && (
          <Tab
            eventKey="ping"
            title="Ping"
            className="border-bottom border-x p-3"
          >
            <PingSettings />
          </Tab>
        )}
      </Tabs>
    </>
  );
};

export default Settings;
