import axios from 'axios';
import PageTitle from 'components/common/PageTitle';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Table } from 'react-bootstrap';
import Select from 'react-select';

const typeOptions = [
  {
    label: 'Addon',
    value: 'addon'
  },
  {
    label: 'Monthly Subscription',
    value: 'subscription'
  },
  {
    label: 'Annual Subscription',
    value: 'annual-subscription'
  },
  {
    label: 'Trial',
    value: 'trial'
  },
  {
    label: 'Additional Credits',
    value: 'additional-credit'
  }
];

const appOptions = [
  {
    label: 'Valid8',
    value: 'Valid8'
  },
  {
    label: 'Currency-R8',
    value: 'CurrencyR8'
  },
  {
    label: 'Activity-M8',
    value: 'ActivityM8'
  },
  {
    label: 'QR-Gener8',
    value: 'QRGener8'
  },
  {
    label: 'Badgely-M8',
    value: 'BadgelyM8'
  },
  {
    label: 'Ping',
    value: 'Ping'
  }
];

const Products = () => {
  const [products, setProducts] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const [editProduct, setEditProduct] = useState(null);
  const [createProduct, setCreateProduct] = useState(null);

  const [apps, setApps] = useState([]);
  const [showEnterprise, setShowEnterprise] = useState(false);
  const [showAddons, setShowAddons] = useState(false);
  const [showHidden, setShowHidden] = useState(false);

  useEffect(() => {
    onPageLoad();
  }, []);

  const onPageLoad = async () => {
    axios.get(`/pmladmin/products/all`).then(res => {
      setProducts(res.data.sort((a, b) => a.ProductID - b.ProductID));
    });
    if (accounts.length === 0) {
      axios.post('/pmladmin/listaccounts').then(res => setAccounts(res.data));
    }
  };

  return (
    <>
      <datalist id="accounts">
        {accounts.map(account => (
          <option key={account.AccountID} value={account.AccountName} />
        ))}
      </datalist>
      {editProduct && (
        <EditModal
          show={editProduct}
          onHide={() => setEditProduct(null)}
          product={editProduct}
          reload={onPageLoad}
          accounts={accounts}
        />
      )}

      {createProduct && (
        <CreateModal
          show={createProduct}
          onHide={() => setCreateProduct(null)}
          reload={onPageLoad}
          accounts={accounts}
        />
      )}
      <PageTitle app="" page="Products" />
      <div
        style={{
          position: 'absolute',
          top: 91.7,
          bottom: 64.8,
          width: 'calc(100% - 32px)'
        }}
      >
        <Card style={{ height: '100%' }}>
          <Card.Header style={{ display: 'flex', gap: 10 }}>
            <Form.Group>
              <Form.Label>App(s)</Form.Label>
              <Select
                closeMenuOnSelect={false}
                placeholder="Select App(s)"
                isMulti
                options={[
                  ...new Set(
                    products.map(p =>
                      JSON.stringify({ label: p.App, value: p.App })
                    )
                  )
                ].map(JSON.parse)}
                classNamePrefix="react-select"
                value={apps}
                onChange={setApps}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                value={showAddons}
                onChange={e => setShowAddons(e.target.checked)}
                label="Show Addons"
              ></Form.Check>
              <Form.Check
                style={{ marginTop: -13 }}
                type="checkbox"
                value={showEnterprise}
                onChange={e => setShowEnterprise(e.target.checked)}
                label="Show Custom Products"
              ></Form.Check>
              <Form.Check
                style={{ marginTop: -13 }}
                type="checkbox"
                value={showHidden}
                onChange={e => setShowHidden(e.target.checked)}
                label="Show Hidden Products"
              ></Form.Check>
            </Form.Group>
            <Button
              variant="danger"
              style={{
                whiteSpace: 'nowrap',
                height: 'fit-content',
                alignSelf: 'center',
                marginLeft: 'auto'
              }}
              onClick={() =>
                setCreateProduct(
                  products
                    ? products.reduce(
                        (prev, current) =>
                          prev.ProductID > current.ProductID ? prev : current,
                        0
                      ).ProductID + 1
                    : null
                )
              }
            >
              Create New Product
            </Button>
          </Card.Header>
          <Card.Body
            style={{ height: 'calc(100% - 120px)', overflow: 'auto' }}
            id="log-body"
          >
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">App</th>
                  <th scope="col" className="text-end"></th>
                </tr>
              </thead>
              <tbody>
                {products.map(p => {
                  if (
                    apps.length > 0 &&
                    !apps.map(a => a.value).includes(p.App)
                  )
                    return null;

                  if (!showEnterprise && p.AccountID) return null;

                  if (!showAddons && p.Type == 'addon') return null;

                  if (!showHidden && p.Hidden) return null;

                  return (
                    <tr key={p.ProductID}>
                      <td>{p.Name}</td>
                      <td>{p.App}</td>
                      <td className="text-end">
                        <Button
                          variant="danger"
                          onClick={() => setEditProduct(p)}
                        >
                          Edit Plan
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

const EditModal = ({ show, onHide, product, reload, accounts }) => {
  if (!product) return null;

  const [id, setId] = useState(product.ProductID);
  const [name, setName] = useState(product.Name);
  const [app, setApp] = useState(appOptions.find(a => a.value == product.App));
  const [requests, setRequests] = useState(product.Requests);
  const [type, setType] = useState(product.Type);
  const [trialDays, setTrialDays] = useState(product.TrialDays || 0);
  const [descripton, setDescription] = useState(product.Description);
  const [accountName, setAccountName] = useState(
    accounts.find(a => a.AccountID == product.AccountID)?.AccountName || ''
  );
  const [hidden, setHidden] = useState(product.Hidden);

  const [gbpPrice, setGbpPrice] = useState(product.GBP_Price || 0);
  const [usdPrice, setUsdPrice] = useState(product.USD_Price || 0);
  const [eurPrice, setEurPrice] = useState(product.EUR_Price || 0);
  const [gbpStripeID, setGbpStripeID] = useState(product.GBP_StripeID || '');
  const [usdStripeID, setUsdStripeID] = useState(product.USD_StripeID || '');
  const [eurStripeID, setEurStripeID] = useState(product.EUR_StripeID || '');
  const [gbpUnitStripeID, setGbpUnitStripeID] = useState(
    product.GBP_UnitStripeID || ''
  );
  const [usdUnitStripeID, setUsdUnitStripeID] = useState(
    product.USD_UnitStripeID || ''
  );
  const [eurUnitStripeID, setEurUnitStripeID] = useState(
    product.EUR_UnitStripeID || ''
  );

  useEffect(() => {
    setType(typeOptions.find(t => t.value == product.Type));
  }, []);

  const onSubmit = async e => {
    e.target.disabled = true;

    if (!name || !app || !type || !descripton) {
      e.target.disabled = false;
      return alert('Please fill in all fields');
    }

    try {
      const array = JSON.parse(descripton);
      if (!Array.isArray(array)) {
        e.target.disabled = false;
        return alert('Description must be valid Array');
      }
    } catch {
      e.target.disabled = false;
      return alert('Description must be valid Array');
    }

    const data = {
      Name: name,
      App: app.value,
      Requests: requests,
      Type: type.value,
      TrialDays: type.value == 'trial' ? trialDays : null,
      Description: descripton,
      AccountID:
        accounts.find(a => accountName == a.AccountName)?.AccountID || null,
      Hidden: hidden,
      GBP_Price: gbpPrice || 0,
      USD_Price: usdPrice || 0,
      EUR_Price: eurPrice || 0,
      GBP_StripeID: gbpStripeID || null,
      USD_StripeID: usdStripeID || null,
      EUR_StripeID: eurStripeID || null,
      GBP_UnitStripeID: gbpUnitStripeID || null,
      USD_UnitStripeID: usdUnitStripeID || null,
      EUR_UnitStripeID: eurUnitStripeID || null
    };

    await axios.patch(`/pmladmin/products/${id}`, data);
    await reload();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {product.Name} - {product.App}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <div style={{ flexBasis: '49%' }}>
          <Form.Group>
            <Form.Check
              type="checkbox"
              checked={hidden}
              onChange={e => setHidden(e.target.checked)}
              label="Hidden"
            ></Form.Check>
          </Form.Group>
          <Form.Group>
            <Form.Label>Product ID</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={id}
              onChange={e => setId(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Product Name*</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>App*</Form.Label>
            <Select
              closeMenuOnSelect={true}
              placeholder="Select App"
              options={appOptions}
              classNamePrefix="react-select"
              value={app}
              onChange={setApp}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Requests*</Form.Label>
            <Form.Control
              type="number"
              value={requests}
              onChange={e => setRequests(parseInt(e.target.value))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type*</Form.Label>
            <Select
              closeMenuOnSelect={true}
              placeholder="Select Type"
              options={typeOptions}
              classNamePrefix="react-select"
              value={type}
              onChange={setType}
            />
          </Form.Group>
          {type && type.value == 'trial' && (
            <Form.Group>
              <Form.Label>Trial Length (Days)</Form.Label>
              <Form.Control
                type="number"
                value={trialDays}
                onChange={e => setTrialDays(parseInt(e.target.value))}
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Description*</Form.Label>
            <Form.Control
              as="textarea"
              value={descripton}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Custom Account</Form.Label>
            <Form.Control
              className="form-select"
              list="accounts"
              autoComplete="on"
              id="accountName"
              value={accountName}
              onChange={e => setAccountName(e.target.value)}
            />
          </Form.Group>
          {/* <input
            className="form-select"
            style={{ width: 500 }}
            list="accounts"
            autoComplete="on"
            id="accountNamesss"
            value={test}
            onChange={e => setTest(e.target.value)}
          /> */}
        </div>
        <div style={{ flexBasis: '49%' }}>
          <Form.Group>
            <Form.Label>£ GBP Price</Form.Label>
            <Form.Control
              type="number"
              value={gbpPrice}
              onChange={e => setGbpPrice(parseInt(e.target.value) || 0)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>£ GBP Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={gbpStripeID}
              onChange={e => setGbpStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>£ GBP Unit Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={gbpUnitStripeID}
              onChange={e => setGbpUnitStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>$ USD Price</Form.Label>
            <Form.Control
              type="number"
              value={usdPrice}
              onChange={e => setUsdPrice(parseInt(e.target.value) || 0)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>$ USD Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={usdStripeID}
              onChange={e => setUsdStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>$ USD Unit Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={usdUnitStripeID}
              onChange={e => setUsdUnitStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>€ EUR Price</Form.Label>
            <Form.Control
              type="number"
              value={eurPrice}
              onChange={e => setEurPrice(parseInt(e.target.value) || 0)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>€ EUR Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={eurStripeID}
              onChange={e => setEurStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>€ EUR Unit Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={eurUnitStripeID}
              onChange={e => setEurUnitStripeID(e.target.value)}
            />
          </Form.Group>
        </div>
        <Button
          variant="danger"
          style={{ flexBasis: '100%' }}
          onClick={onSubmit}
        >
          Update Product
        </Button>
      </Modal.Body>
    </Modal>
  );
};

const CreateModal = ({ show, onHide, reload, accounts }) => {
  const [id, setId] = useState(show);
  const [name, setName] = useState('');
  const [app, setApp] = useState('');
  const [requests, setRequests] = useState(0);
  const [type, setType] = useState({
    label: 'Subscription',
    value: 'subscription'
  });
  const [trialDays, setTrialDays] = useState(0);
  const [descripton, setDescription] = useState(
    '["X Requests per month",">X Requests {currency} 0.05 each","Free email support"]'
  );
  const [accountName, setAccountName] = useState('');
  const [hidden, setHidden] = useState(false);

  const [gbpPrice, setGbpPrice] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const [eurPrice, setEurPrice] = useState(0);
  const [gbpStripeID, setGbpStripeID] = useState('');
  const [usdStripeID, setUsdStripeID] = useState('');
  const [eurStripeID, setEurStripeID] = useState('');
  const [gbpUnitStripeID, setGbpUnitStripeID] = useState('');
  const [usdUnitStripeID, setUsdUnitStripeID] = useState('');
  const [eurUnitStripeID, setEurUnitStripeID] = useState('');

  const onSubmit = async e => {
    e.target.disabled = true;

    if (!name || !app || !type || !descripton) {
      e.target.disabled = false;
      return alert('Please fill in all fields');
    }

    try {
      const array = JSON.parse(descripton);
      if (!Array.isArray(array)) {
        e.target.disabled = false;
        return alert('Description must be valid Array');
      }
    } catch {
      e.target.disabled = false;
      return alert('Description must be valid Array');
    }

    const data = {
      Name: name,
      App: app.value,
      Requests: requests,
      Type: type.value,
      TrialDays: type.value == 'trial' ? trialDays : null,
      Description: descripton,
      AccountID: accounts.find(a => accountName == a.AccountName)?.AccountID,
      Hidden: hidden,
      GBP_Price: gbpPrice || 0,
      USD_Price: usdPrice || 0,
      EUR_Price: eurPrice || 0,
      GBP_StripeID: gbpStripeID || null,
      USD_StripeID: usdStripeID || null,
      EUR_StripeID: eurStripeID || null,
      GBP_UnitStripeID: gbpUnitStripeID || null,
      USD_UnitStripeID: usdUnitStripeID || null,
      EUR_UnitStripeID: eurUnitStripeID || null
    };

    await axios.post(`/pmladmin/products/${id}`, data);
    await reload();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create New Product</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <div style={{ flexBasis: '49%' }}>
          <Form.Group>
            <Form.Check
              type="checkbox"
              checked={hidden}
              onChange={e => setHidden(e.target.checked)}
              label="Hidden"
            ></Form.Check>
          </Form.Group>
          <Form.Group>
            <Form.Label>Product ID</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={id}
              onChange={e => setId(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Product Name*</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>App*</Form.Label>
            <Select
              closeMenuOnSelect={true}
              placeholder="Select App"
              options={appOptions}
              classNamePrefix="react-select"
              value={app}
              onChange={setApp}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Requests*</Form.Label>
            <Form.Control
              type="number"
              value={requests}
              onChange={e => setRequests(parseInt(e.target.value))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type*</Form.Label>
            <Select
              closeMenuOnSelect={true}
              placeholder="Select Type"
              options={typeOptions}
              classNamePrefix="react-select"
              value={type}
              onChange={setType}
            />
          </Form.Group>
          {type && type.value == 'trial' && (
            <Form.Group>
              <Form.Label>Trial Length (Days)*</Form.Label>
              <Form.Control
                type="number"
                value={trialDays}
                onChange={e => setTrialDays(parseInt(e.target.value))}
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Description*</Form.Label>
            <Form.Control
              as="textarea"
              value={descripton}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Custom Account</Form.Label>
            <Form.Control
              className="form-select"
              list="accounts"
              autoComplete="on"
              id="accountName"
              value={accountName}
              onChange={e => setAccountName(e.target.value)}
            />
          </Form.Group>
        </div>
        <div style={{ flexBasis: '49%' }}>
          <Form.Group>
            <Form.Label>£ GBP Price</Form.Label>
            <Form.Control
              type="number"
              value={gbpPrice}
              onChange={e => setGbpPrice(parseInt(e.target.value) || 0)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>£ GBP Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={gbpStripeID}
              onChange={e => setGbpStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>£ GBP Unit Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={gbpUnitStripeID}
              onChange={e => setGbpUnitStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>$ USD Price</Form.Label>
            <Form.Control
              type="number"
              value={usdPrice}
              onChange={e => setUsdPrice(parseInt(e.target.value) || 0)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>$ USD Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={usdStripeID}
              onChange={e => setUsdStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>$ USD Unit Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={usdUnitStripeID}
              onChange={e => setUsdUnitStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>€ EUR Price</Form.Label>
            <Form.Control
              type="number"
              value={eurPrice}
              onChange={e => setEurPrice(parseInt(e.target.value) || 0)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>€ EUR Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={eurStripeID}
              onChange={e => setEurStripeID(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>€ EUR Unit Stripe ID</Form.Label>
            <Form.Control
              type="text"
              value={eurUnitStripeID}
              onChange={e => setEurUnitStripeID(e.target.value)}
            />
          </Form.Group>
        </div>
        <Button
          variant="danger"
          style={{ flexBasis: '100%' }}
          onClick={onSubmit}
        >
          Create Product
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default Products;
