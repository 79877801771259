import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';

import 'assets/scss/user.scss';
const App = () => {
  return (
    <Router>
      <Layout />
    </Router>
  );
};

export default App;
