import React, { useState, useEffect } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import axios from 'axios';

const AdminBetas = props => {
  const [betas, setBetas] = useState([]);
  const [saveLocked, setSaveLocked] = useState(true);

  useEffect(() => {
    getBetas();
  }, []);

  const getBetas = async () => {
    const { data: betas } = await axios.post('/pmladmin/getaccountbetas', {
      accountID: props.data.AccountID,
      app: props.app
    });
    setBetas(betas);
  };

  const saveBetas = async () => {
    let updatedBetas = betas.map(beta => {
      beta.enabled = document.getElementById('beta_' + beta.name).checked;
      beta.column = beta.app + '_' + beta.name;
      return beta;
    });
    await axios.post('/pmladmin/updateaccountbetas', {
      accountID: props.data.AccountID,
      betas: updatedBetas
    });
    await getBetas();
    setSaveLocked(true);
  };

  return (
    <>
      <Card style={{ height: 300 }}>
        <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: 12
            }}
          >
            <h2 style={{ fontWeight: 'normal', fontSize: 24 }}>Betas</h2>
          </div>
          <div
            style={{
              flexGrow: 1,
              flexBasis: 1,
              flex: 'flex-grow'
            }}
          >
            {betas.map((beta, idx) => {
              return (
                <div
                  style={{
                    paddingBottom: 8,
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                  key={idx}
                >
                  <p style={{ margin: 0 }}>{beta.name}</p>
                  <Form.Check
                    type="switch"
                    id={'beta_' + beta.name}
                    defaultChecked={beta.enabled}
                    onChange={() => {
                      setSaveLocked(false);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div style={{ display: 'flex' }}>
            <Button
              variant="danger"
              style={{ width: '100%' }}
              onClick={() => {
                saveBetas();
              }}
              disabled={saveLocked}
            >
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AdminBetas;
