import React from 'react';
import { Modal, Table, Button } from 'react-bootstrap';

const AccountsModal = ({ title, accounts, setAccounts, app }) => {
  const handleClose = () => setAccounts(null);

  return (
    <Modal show={accounts} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
        <Button
          variant="danger"
          size="sm"
          style={{ marginLeft: 20 }}
          onClick={() => console.log(accounts)}
        >
          Print Accounts
        </Button>
      </Modal.Header>
      <Modal.Body
        style={{
          height: '100%',
          maxHeight: '60vh',
          overflow: 'auto',
          padding: 0
        }}
      >
        <Table striped bordered hover>
          <thead style={{ position: 'sticky', top: 0, background: '#F1F2F6' }}>
            <tr>
              <th>Customer Name</th>
              <th>Product Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {accounts &&
              accounts.map(a => {
                return (
                  <tr key={a.AccountID}>
                    <td>{a.AccountName}</td>
                    <td>{a.ProductName}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() =>
                          window
                            .open(
                              `https://dashboard.stripe.com/customers/${a.StripeID}`,
                              '_blank'
                            )
                            .focus()
                        }
                      >
                        Stripe
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => {
                          window
                            .open(
                              `${process.env.REACT_APP_BASE_URL}/pmladmin/dashboard/${app}?account=${a.AccountName} (${a.PortalID})`,
                              '_blank'
                            )
                            .focus();
                        }}
                      >
                        Dashboard
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default AccountsModal;
