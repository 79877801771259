import 'chart.js/auto';
import { getColor, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';

const ValidationsBarChart = props => {
  const [values, setValues] = useState();

  useEffect(() => {
    if (props.data !== undefined) {
      formatValues();
    }
  }, [props]);

  const formatValues = () => {
    let statusCount = [0, 0, 0, 0];
    for (let i = 0; i < props.data.length; i++) {
      switch (props.data[i].Status) {
        case 'Valid':
          statusCount[0] += 1;
          break;
        case 'Invalid':
          statusCount[1] += 1;
          break;
        case 'Catch-All':
          statusCount[2] += 1;
          break;
        case 'Unknown':
          statusCount[3] += 1;
          break;
      }
    }
    setValues(statusCount);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 2000
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Total Validations',
        font: {
          size: 24,
          family: "'Poppins'",
          weight: 'normal'
        }
      }
    }
  };
  const data = {
    labels: ['Valid', 'Invalid', 'Catch-All', 'Unknown'],
    datasets: [
      {
        label: '# of Validations',
        data: values,
        backgroundColor: [
          rgbaColor(getColor('success'), 0.7),
          rgbaColor(getColor('danger'), 0.7),
          rgbaColor(getColor('warning'), 0.7),
          rgbaColor(getColor('light'), 0.7)
        ]
      }
    ]
  };

  return (
    <Card style={{ height: '100%' }}>
      <Card.Body>
        <Bar options={options} data={data} />
      </Card.Body>
    </Card>
  );
};

ValidationsBarChart.propTypes = {
  data: PropTypes.array
};

export default ValidationsBarChart;
