import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getColor, rgbaColor } from 'helpers/utils';
import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import Loading from 'components/common/Loading';
import { monthNames, textAppNames } from 'helpers/utils';

const formatData = transactions => {
  if (!transactions) {
    return;
  }

  const groupedByMonth = transactions.reduce((acc, obj) => {
    const month = obj.Timestamp.substring(0, 7); // Extract the YYYY-MM part
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(obj);
    return acc;
  }, {});

  let now = new Date();

  let date12MonthsAgo = new Date();
  date12MonthsAgo.setMonth(now.getMonth() - 12);
  if (now.getMonth() < date12MonthsAgo.getMonth()) {
    date12MonthsAgo.setFullYear(now.getFullYear() - 1);
  }

  const labels = [];
  const values = [];
  const monthlyTransactions = [];

  while (date12MonthsAgo <= now) {
    let month = date12MonthsAgo.toISOString().substring(0, 7);

    monthlyTransactions.push(groupedByMonth[month] || []);
    labels.push(monthNames[date12MonthsAgo.getMonth()]);
    values.push(groupedByMonth[month] ? groupedByMonth[month].length : 0);

    date12MonthsAgo.setMonth(date12MonthsAgo.getMonth() + 1);
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of New Trials',
        data: values,
        transactions: monthlyTransactions,
        backgroundColor: [rgbaColor(getColor('danger'), 0.7)]
      }
    ]
  };

  return data;
};

const TrialsBarChart = props => {
  let { app, height } = props;

  if (!height) {
    height = '300px';
  }

  const [transactions, setTransactions] = useState();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    setTransactions(props.transactions);
  }, [props.transactions]);

  useEffect(() => {
    setChartData(formatData(transactions));
  }, [transactions]);

  const chartRef = useRef();
  const chartOnClick = event => {
    const col = getElementAtEvent(chartRef.current, event)[0];

    if (!col) {
      return;
    }

    const transactions = chartData.datasets[0].transactions[col.index];

    if (props.setModalTitle && props.setModalTransactions) {
      props.setModalTitle(`New Trials ${chartData.labels[col.index]}`);
      props.setModalTransactions(transactions);
    }
  };

  const options = {
    indexAxis: 'x',
    responsive: true,
    // barThickness: 20,
    maintainAspectRatio: false,
    animation: {
      duration: 2000
    },
    scales: {
      y: {
        ticks: {
          display: true,
          font: {
            size: 12
          }
        }
      },
      x: {
        ticks: {
          display: true
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: 'bottom',
        color: '#000',
        clamp: true,
        font: {
          size: 16
        },
        formatter: function (value, _) {
          if (value > 0) {
            return value;
          } else {
            return '';
          }
        }
      }
    }
  };

  if (chartData == null || chartData.length == 0) {
    return (
      <Card>
        <Card.Body style={{ paddingTop: 0, height: height }}>
          <div className="chart-header">
            <h2>{textAppNames[app]} New Trials</h2>
          </div>
          <Loading />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Body style={{ paddingTop: 0, height: height }}>
        <div className="chart-header">
          <h2>{textAppNames[app]} New Trials</h2>
        </div>
        <div style={{ height: 'calc(100% - 64px)' }}>
          <Bar
            ref={chartRef}
            onClick={chartOnClick}
            options={options}
            data={chartData}
            plugins={[ChartDataLabels]}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default TrialsBarChart;
